import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
@Component({
  selector: 'app-pricing',
  templateUrl: './pricing.component.html',
  styleUrls: ['./pricing.component.scss']
})

// interface Alert {
//   type: string;
//   message: string;
// }



export class PricingComponent implements OnInit {
  visability = false;
  visability2 = false;
  ttt = true;
  ttt2 = false;
  ttt3 = false;



  // public closeAlert(alert: Alert) {
  //     const index: number = this.alerts.indexOf(alert);
  //     this.alerts.splice(index, 1);
  // }
  public checkboxGroupForm: FormGroup;
  public radioGroupForm: FormGroup;
  constructor(private formBuilder: FormBuilder) { }
  modelRadio = 1;
  ngOnInit() {
    this.radioGroupForm = this.formBuilder.group({
      'modelRadioForm': 'Left'
    });
  }

  goTop() {
    let scrollToTop = window.setInterval(() => {
      let pos = window.pageYOffset;
      if (pos > 0) {
        window.scrollTo(0, pos - 20); // how far to scroll on each step
      } else {
        window.clearInterval(scrollToTop);
      }
    }, 6);


  }
  showDivs() {
    this.visability = true;
    this.visability2 = false;
    this.ttt = false;
    this.ttt2 = true;
    this.ttt3 = false;
    // span.visability = false; // remove this
    // wrap.visability = true;  // remove this
  }


  hideDivs() {
    this.visability = false;
    this.visability2 = false;
    this.ttt = true;
    this.ttt2 = false;
    this.ttt3 = false;

    // span.visability = false; // remove this
    // wrap.visability = true;  // remove this
  }
  showDivs2() {
    this.visability2 = true;
    this.visability = true;
    this.ttt = false;
    this.ttt2 = false;
    this.ttt3 = true;
  }
  doTheBack() {
    window.history.back();
  }
  clicked(event) {
    this.visability = false;

    // this.target = event.target.innerText;
  }

}
