import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-user-resume',
  templateUrl: './user-resume.component.html',
  styleUrls: ['./user-resume.component.scss']
})
export class UserResumeComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
