import { Component, OnInit, Renderer2 } from '@angular/core';
import { UserService } from '../../shared/user.service';
import { GlobalService } from '../../shared/global.service';
import { TranslateService } from '@ngx-translate/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router, NavigationExtras, ActivatedRoute } from "@angular/router";
@Component({
  selector: 'app-notification-sidebar',
  templateUrl: './notification-sidebar.component.html',
  styleUrls: ['./notification-sidebar.component.scss']
})
export class NotificationSidebarComponent implements OnInit {
  framePath;
  userSlug;
  resumeSlug;
  resumeId;
  userName;
  resumeLanguage;
  id;
  resumeMeta;
  isPro;
  userDetails = { role: '', firstName: '', lastName: '', email: '' };
  options = {
    direction: 'ltr'
  };
  constructor(


    public userService: UserService,
    public globalService: GlobalService,
    public router: Router,
    private renderer: Renderer2,
    public translate: TranslateService,
    public route: ActivatedRoute) {


    this.globalService.userDetailWatch.subscribe(res => {
      this.userDetails.role = res['meta']['role'];
      this.userDetails.firstName = res['firstName'];
      this.userDetails.lastName = res['lastName'];
      this.userDetails.email = res['email'];
    })

  }
  ngOnInit() {
    this.resumeId = localStorage.getItem('resumeId');
    this.userName = localStorage.getItem('userName');
    this.userSlug = this.userName;

    this.id = localStorage.getItem('resumeId');

    this.resumeMeta = { ResumeLanguage: "" };
    this.resumeMeta = { isPro: "" };
    // this.userService.getResume(this.id).subscribe((res) => {

    //   if (res['data'].length > 0) {
    //     this.resumeMeta.ResumeLanguage = res['data'][0].meta.ResumeLanguage;
    //     this.resumeMeta.isPro = res['data'][0].meta.defaultTemplate.isPro;
    //   }

    // });

    // notification-sidebar JS File
    $.getScript('./assets/js/notification-sidebar.js');
  }
  onLogout() {
    this.userService.deleteToken();
    this.router.navigate(['/login']);
  }

}
