import { Component } from '@angular/core';

@Component({
    selector: 'app-media-objects',
    templateUrl: './media-objects.component.html',
    styleUrls: ['./media-objects.component.scss']
})

export class MediaObjectsComponent {

}