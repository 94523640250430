import { Component, ElementRef, ViewChild, ViewEncapsulation, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgForm } from '@angular/forms';
import { QuillEditorComponent } from 'ngx-quill';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { UserService } from '../../shared/user.service';
import { Observable, from } from 'rxjs';
import { ActivatedRoute, Router, Params, NavigationExtras } from "@angular/router";
import { SidebarComponent } from 'app/shared/sidebar/sidebar.component'
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import Quill from 'quill';
import { workers } from 'cluster';
import * as alertFunctions from '../../shared/data/sweet-alerts';
import swal from 'sweetalert2';
import { TranslateService } from '@ngx-translate/core';

import { RenameSectionComponent } from '../../common_modals/rename-section/rename-section.component';
import { GlobalService } from 'app/shared/global.service';

@Component({
  selector: 'app-areas-of-expertise',
  templateUrl: './areas-of-expertise.component.html',
  styleUrls: ['./areas-of-expertise.component.scss'],
  encapsulation: ViewEncapsulation.None,
  exportAs: 'child'
})
export class AreasOfExpertiseComponent implements OnInit {
  //declarations
  areaofexpertiseArray: any;
  resumeIdArray;
  sectionArray;
  areaofexpertiseform;
  temporaryArray;
  title = 'Quill works!';
  hide = false;
  isReadOnly = false;
  form: FormGroup;
  public sub: any;
  public id: any;
  resumeId: {};
  sectionname;
  sectionUniqueId;

  areaofexpertise: Boolean = true;
  renamesection: Boolean = false;
  public icons;
  sectionicon;
  public iconsArray = ["fa-book", "fa-bed", "fa-bicycle", "fa-car ", "fa-camera", "fa-futbol-o"];
  constructor(fb: FormBuilder, public userService: UserService,
    private route: ActivatedRoute,
    public router: Router,
    public modalService: NgbModal, public activeModal: NgbActiveModal, public translate: TranslateService, public global: GlobalService) {
    this.form = fb.group({
      editor: ['test']
    });
  }
  @ViewChild('myModal') myModal;
  ngOnInit() {

    this.route.params.subscribe((params) => {
      this.sectionUniqueId = params['ids'];
      localStorage.setItem("sectionid", this.sectionUniqueId);

      this.id = localStorage.getItem('resumeId');

      this.form
        .controls
        .editor
        .valueChanges.pipe(
          debounceTime(400),
          distinctUntilChanged()
        )
        .subscribe(data => {
        });
      this.userService.getResume(this.id).subscribe((res) => {
        this.global.emitResume(res);
        this.userService.setCurrentTab(res, "section-interests");
        this.onareaofexpertiseUpdate(res)

      });
      // this.userService.getSingleSection(this.sectionUniqueId)
      //   .subscribe((res) => {
      //     // 27.05 updated by Jupiter
      //     // this.onSectionareaofexpertiseUpdate(res['section'])

      //   });
    });
  }
  onareaofexpertiseUpdate(res) {
    this.resumeIdArray = res.data ? res.data[0] : res.doc;
    let selectedSection = this.resumeIdArray.sections.find((section) => {
      return section.ids == this.sectionUniqueId;
    })
    this.global.emitUpdateSideMenu(selectedSection);
    this.onSectionareaofexpertiseUpdate(selectedSection || {})
  }

  onSectionareaofexpertiseUpdate(res) {
    this.sectionUniqueId = res['ids'] || "";
    this.sectionArray = res || [];
    this.areaofexpertiseArray = res.subsections || [];
    this.sectionname = res.sectionname;
    this.sectionicon = res.icon;

  }
  //events starts
  setFocus($event) {
    $event.focus();
  }

  patchValue() {
    this.form.controls['editor'].patchValue(`${this.form.controls['editor'].value} patched!`)
  }

  toggleReadOnly() {
    this.isReadOnly = !this.isReadOnly;
  }

  logChange($event: any) {
    //your code here
  }

  logSelection($event: any) {
    //your code here
  }
  //events ends
  add = false;
  list = true;
  edit = false;

  onAdd(id: string) {
    this.areaofexpertiseform = "";
    if (this.areaofexpertiseform == "") {
      this.areaofexpertiseform = {
        about: ""
      }
    }
    this.add = true;
    this.list = false;
  }
  onEdit(areaofexpertiseArray: any) {
    window.scrollTo(0, 0)
    this.areaofexpertiseform = {
      ids: areaofexpertiseArray.ids,
      company_url: areaofexpertiseArray.company_url,
      about: areaofexpertiseArray.about,
      name: areaofexpertiseArray.name
    }
    this.add = false;
    this.list = false;
    this.edit = true;
    window.scrollTo(0, 0)

  }
  typeWarning() {
    var titleY;
    var textY;
    var confirmButtonText;
    var cancelButtonText;
    var DeletedY;
    var successY;
    this.translate.get("Delect Section Text").subscribe((text: string) => { titleY = text; });
    this.translate.get("You won't be able to revert this!").subscribe((text: string) => { textY = text; });
    this.translate.get("Yes, delete it!").subscribe((text: string) => { confirmButtonText = text; });
    this.translate.get("No, cancel!").subscribe((text: string) => { cancelButtonText = text; });
    this.translate.get("Deleted").subscribe((text: string) => { DeletedY = text; });
    this.translate.get("Success").subscribe((text: string) => { successY = text; });
    swal({
      title: titleY,
      text: textY,
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: confirmButtonText,
      cancelButtonText: cancelButtonText,
      reverseButtons: true
    }).then((result) => {
      if (result.value) {

        swal(
          DeletedY,
          successY,
          'success'
        )
        var data = this.resumeIdArray.sections;
        var index = data.findIndex(x => x.ids === this.sectionUniqueId);
        let selectedSection = data[index];

        data.splice(index, 1);

        this.userService.updateresumegenericsubject(this.id, this.resumeIdArray).subscribe((res: any) => {
          this.router.navigate(['/cv/', this.id, 'personal-info'], { relativeTo: this.route });

          this.global.emitSidemenuDelete(selectedSection);
        });


      } else if (

        result.dismiss === swal.DismissReason.cancel
      ) {
        //   swal(
        //     'Cancelled',
        //     'Your imaginary file is safe :)',
        //     'error'
        //   )
      }
    })
    //alertFunctions.customWarningDeleteResume();
  }
  onEditSubmit(form: NgForm) {
    var subsections = {
      ids: form.value.ids,
      about: form.value.about,
      name: form.value.name,
      opened: false,

    }

    this.areaofexpertiseArray.forEach(element => {

      var index = this.areaofexpertiseArray.findIndex(x => x.ids === form.value.ids);

      this.areaofexpertiseArray.splice(index, 1, subsections);
    });

    let index = this.resumeIdArray.sections.findIndex((section) => {
      return section.ids == this.sectionUniqueId;
    })

    this.resumeIdArray.sections[index].subsections = this.areaofexpertiseArray;

    this.userService.updateresumegeneric(this.id, this.resumeIdArray).subscribe((res: any) => {

      form.reset()
      this.edit = false;
      this.list = true;
      // location.reload();
    });
  }
  onCancel() {
    this.list = true;
    this.edit = false;
    this.add = false;
    this.renamesection = false;
  }
  confirmDelete(id: any) {
    var titleY;
    var textY;
    var confirmButtonText;
    var cancelButtonText;
    var DeletedY;
    var successY;
    this.translate.get("Delect Item Text").subscribe((text: string) => { titleY = text; });
    this.translate.get("You won't be able to revert this!").subscribe((text: string) => { textY = text; });
    this.translate.get("Yes, delete it!").subscribe((text: string) => { confirmButtonText = text; });
    this.translate.get("No, cancel!").subscribe((text: string) => { cancelButtonText = text; });
    this.translate.get("Deleted").subscribe((text: string) => { DeletedY = text; });
    this.translate.get("Success").subscribe((text: string) => { successY = text; });
    swal({
      title: titleY,
      text: textY,
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: confirmButtonText,
      cancelButtonText: cancelButtonText,
      reverseButtons: true
    }).then((result) => {
      if (result.value) {

        swal(
          DeletedY,
          successY,
          'success'
        )
        let sectionIndex = this.resumeIdArray.sections.findIndex(x => x.ids === this.sectionUniqueId);
        this.areaofexpertiseArray.splice(index, 1);
        var index = this.areaofexpertiseArray.findIndex(x => x.ids === id);
        this.resumeIdArray.sections[sectionIndex].subsections = this.areaofexpertiseArray;

        this.userService.updateresumegeneric(this.id, this.resumeIdArray).subscribe((res: any) => {

          this.add = false;
          this.list = true;
        });
      } else if (

        result.dismiss === swal.DismissReason.cancel
      ) {
      }
    })
    //alertFunctions.customWarningDeleteResume();
  }
  onDelete(id: String) {
    this.confirmDelete(id);
  }
  confirmCancelButton() {
    this.typeWarning();
  }
  onClone(areaofexpertiseArray: any) {
    var temporaryArray = {
      ids: Math.floor(10000000000 + Math.random() * 90000000000),
      about: areaofexpertiseArray.about,
      name: areaofexpertiseArray.name,
      opened: areaofexpertiseArray.opened

    }
    this.areaofexpertiseArray.push(temporaryArray)
    // this.lanuageArray.push(this.selectedlanuage);
    let sectionIndex = this.resumeIdArray.sections.findIndex(x => x.ids === this.sectionUniqueId);
    this.resumeIdArray.sections[sectionIndex].subsections = this.areaofexpertiseArray;
    this.userService.updateresumegeneric(this.id, this.resumeIdArray).subscribe((res: any) => {

      this.edit = false;
      this.list = true;
    });
  }
  editWorkexpErience() {
    // this.modalService.open(content, { size: 'lg' });
    const modalRef = this.modalService.open(RenameSectionComponent);
    modalRef.componentInstance.sectionname = this.sectionname;
    modalRef.componentInstance.sectionicon = this.sectionicon;
    modalRef.componentInstance.sectionArray = this.sectionArray;
    modalRef.componentInstance.resumeIdArray = this.resumeIdArray;
    modalRef.componentInstance.areaofexpertiseArray = this.areaofexpertiseArray;
    modalRef.componentInstance.id = this.id;
    modalRef.componentInstance.sub = this.sub;
    modalRef.componentInstance.areaofexpertiseform = this.areaofexpertiseform;
    modalRef.componentInstance.temporaryArray = this.temporaryArray;
    modalRef.componentInstance.FormGroup = this.form;
    modalRef.componentInstance.sectionUniqueId = this.sectionUniqueId;
    modalRef.componentInstance.icons = this.icons;


    modalRef.componentInstance.passEntry.subscribe((receivedEntry) => {

      this.onareaofexpertiseUpdate(receivedEntry)
    })
  }
  savesection(icon, sectionname, id, sectionArray, resumeIdArray) {

    sectionArray.sectionname = this.sectionname

    this.userService.updateresumegenericsubject(this.id, this.resumeIdArray).subscribe((res: any) => {

    });

    this.modalService.dismissAll();
    this.activeModal.dismiss()

  }

  iconschangeFunction(id, data, sectionArray, resumeIdArray) {
    sectionArray.icon = data
    this.icons = sectionArray.icon;

    this.sectionicon = sectionArray.icon

  }

  openModal() {

    this.myModal.nativeElement.className = 'modal fade show';


  }



  ngOnDestroy() {

  }

  sectionrename(newValue) {
    this.sectionname = newValue


  }
}
