import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { ROUTES } from './sidebar-routes.config';
import { RouteInfo } from "./sidebar.metadata";
import { Router, ActivatedRoute, Params, NavigationExtras } from "@angular/router";
import { TranslateService } from '@ngx-translate/core';
import { UserService } from '../user.service';
import { findIndex, throttleTime } from 'rxjs/operators'
import { NgForm } from '@angular/forms';
import { Subscription } from 'rxjs';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { modelGroupProvider } from '@angular/forms/src/directives/ng_model_group';
// import { SectionsComponent } from 'app/sections/sections.component';
import { GlobalService } from '../global.service';
import { NgbdModalTemplateLists } from '../customizer/customizer.component';


declare var $: any;

@Component({
    selector: 'app-sidebar',
    templateUrl: './sidebar.component.html',
    styleUrls: ['./sidebar.component.scss'],
    providers: [UserService]
})

export class SidebarComponent implements OnInit, OnDestroy {
    public menuItems = [];
    public sectiondynamicArray: any[];
    public sections = [];
    public sub: any;
    public id: any;
    public userSlug: any;
    public resumeSlug: any;
    public resumeIdArray: any;
    public resumeLanguage: any;

    private menuSectionOrder = [
        "section-text",
        "section-work",
        "section-education",
        "section-skillset",
        "section-languages",
        "section-skillset",
        "skills-software",
        "section-awards",
        "section-certifications",
        "section-courses",
        "section-strengths",
        "section-interests",
        "section-portfolio",
        "section-logos",
        "section-text-inside",
        "section-references",
        "section-world-map",
        "section-publications",
        "section-empty",
        "section-donut-chart",
        "section-Hobbies",
        "section-empty-space"
    ];
    message: any;
    subscription: Subscription;
    subscriptionPI: Subscription;
    userdetails = { firstName: '', lastName: '', role: '' };
    textDir: any;
    userdetailsRole = { role: '' };
    resumeMeta;
    resumeData;
    resumeId;
    userId;
    logoUrl: any;
    apiUrl: any;
    personalInfoArray;
    personalImg = '';
    personalInfo = { firstName: '', lastName: '' };

    constructor(
        public router: Router,
        public global: GlobalService,
        public route: ActivatedRoute,
        public translate: TranslateService,
        public userService: UserService,
        private globalService: GlobalService,

        public modalService: NgbModal) {
        // this.globalService.personalInfoWatch.subscribe((personalInfo) => {
        //     console.log('personalInfo', personalInfo);
        // });


        this.subscription = this.userService.getMessage().subscribe(message => {
            this.message = message;
            this.resumeIdArray = this.message;
            this.id = localStorage.getItem('resumeId');

            this.menuItems = ROUTES.filter(menuItem => menuItem.name == "Personal Info");
            this.sortMenuItem();
            this.menuItems[0].path = "/cv/" + this.id + "/personal-info";
            // console.log("menu items", this.menuItems);
            // console.log("message", this.message.sections)
            this.message.sections.forEach(element => {
                if (element.type != undefined) {
                    var index = ROUTES.findIndex(x => x.type.toString().toLowerCase() === element.type.toString().toLowerCase());

                    element.path = this.getPath(element, index);

                    if (element.sectionname != undefined) {
                        ROUTES[index].sectionname = element.sectionname;
                    }
                    element.sectionname = ROUTES[index].sectionname;
                    this.menuItems.push(element);
                }

            });
            this.sortMenuItem();

            //this.menuItems = this.message.sections
        });
        this.global.userDetailWatch.subscribe(value => {
            this.personalInfo.firstName = value['firstName'];
            this.personalInfo.lastName = value['lastName'];

            this.userdetails.firstName = value['firstName'];
            this.userdetails.lastName = value['lastName'];
            this.userdetails.role = value['role'];

        })

        this.global.personalInfoWatch.subscribe(value => {
            this.personalInfo.firstName = value['aboutSection'].firstName;
            this.personalInfo.lastName = value['aboutSection'].lastName;

        });
        this.global.languageChangeWatch.subscribe(value => {
            if (value == 'ar') {
                this.textDir = 'rtl';
            } else {
                this.textDir = 'ltr';
            }
        });

        this.global.changeProfileLogoWatch.subscribe(value => {
            this.personalImg = '/upload/sections/personal-logos/' + value;
            //console.log(value);
        });

        this.id = localStorage.getItem('resumeId');

        this.globalService.resumeDetailWatch.subscribe(res => {
            this.resumeData = res['data'][0];
            this.resumeMeta = res['data'][0].meta;

            this.resumeIdArray = res['data'][0];
            this.personalInfoArray = res['data'][0].meta;
            localStorage.setItem("resumeLanguage", res['data'][0]['meta']['ResumeLanguage']);
            if (this.personalInfoArray.aboutSection.hasOwnProperty('image') && this.personalInfoArray.aboutSection.image._Xlarge) {
                this.personalImg = '/upload/sections/personal-logos/' + this.personalInfoArray.aboutSection.image._Xlarge;
            }
            // if (this.personalInfoArray.aboutSection.image._Xlarge != null && this.personalInfoArray.aboutSection.image._Xlarge != '') {
            //     this.personalImg = this.apiUrl + '/upload/sections/personal-logos/' + this.personalInfoArray.aboutSection.image._Xlarge;
            // }

            this.sections = res['data'][0].sections;

            this.resumeLanguage = res['data'][0].meta.ResumeLanguage;

            this.menuItems = this.menuItems.splice(0, 1);
            // this.sections = this.sections.sort((section1, section2) => {
            //     if (section1['name'] > section2['name']) return 1;
            //     else return -1;
            // })
            this.sections.forEach(element => {
                if (element.type != undefined) {
                    var index = ROUTES.findIndex(x => x.type.toString().toLowerCase() === element.type.toString().toLowerCase());

                    element.path = this.getPath(element, index);
                    if (element.sectionname) {
                        ROUTES[index].sectionname = element.sectionname;
                    } else {
                        ROUTES[index].sectionname = element.name;
                    }

                    element.sectionname = ROUTES[index].sectionname;
                    this.menuItems.push(element);
                }

            });
            this.sortMenuItem();
            this.setPersonalMenu();
            // this.userService.updateresumegeneric(this.id, this.resumeIdArray).subscribe((res: any) => {

            // });
        })
        // this.global.sideMenuUpdateWatch.subscribe(value => {
        //     this.menuItems.forEach(menu => {
        //         if (menu.ids === value['ids']) {
        //             menu.icon = value['icon'];
        //             menu.sectionname = value['sectionname'];// remove log
        //         }
        //     })
        // })
    }
    func: any;

    sortMenuItem() {
        //console.log('before sortMenuItem ===> ', this.menuItems);
        this.menuItems = this.menuItems.sort((menu1, menu2) => {
            return this.menuSectionOrder.indexOf(menu1.type) > this.menuSectionOrder.indexOf(menu2.type) ? 1 : -1;
        });
        //  console.log('after sortMenuItem ===> ', this.menuItems);
    }
    ngOnInit() {
        // console.log("sidebar menu");
        this.global.sideMenuUpdateWatch.subscribe(value => {
            this.menuItems.forEach(menu => {
                if (menu !== undefined && value !== undefined && menu.ids === value['ids']) {
                    menu.icon = value['icon'];
                    menu.sectionname = value['sectionname'];// remove log
                }
            });
            this.sortMenuItem();
        })
        this.subscriptionPI = this.userService.userProfileImageChangedEvent.subscribe((profileImageUrl) => {

            this.personalImg = profileImageUrl;
        });

        this.global.userDetailWatch.subscribe(value => {

            this.userdetailsRole.role = value['meta']['role'];
        })

        this.global.sideMenuWatch.subscribe(value => {
            // TODO: add section proper place
            this.menuItems.push(value);
        })

        this.global.sideMenuDeleteWatch.subscribe(value => {
            var index = this.menuItems.findIndex(x => x.ids === value['ids']);
            this.menuItems.splice(index, 1);
        })
        this.apiUrl = this.userService.getApiUrl();

        this.userId = localStorage.getItem('userId')
        var language = localStorage.getItem('locale');
        if (language == 'ar') {
            this.textDir = 'rtl';
        } else {
            this.textDir = 'ltr';
        }

        this.id = localStorage.getItem('resumeId');

        $.getScript('./assets/js/app-sidebar.js');

        this.updateLinks();

        this.userService.getUserProfile().subscribe(
            res => {

                this.userdetailsRole.role = res['user']['meta']['role']

            },
            err => {
                console.log(err);
            }
        );

    }

    updateLinks = () => {
        this.id = localStorage.getItem('resumeId');

        this.menuItems = ROUTES.filter(menuItem => menuItem.name == "Personal Info");
        this.sortMenuItem();
        this.menuItems[0].path = "/cv/" + this.id + "/personal-info";
        this.setPersonalMenu();
        this.sub = this.route.queryParams.subscribe(params => {
            this.id = params["property_id"];
            this.userSlug = params["userSlug"];
            this.resumeSlug = params["property_id"];
        });
        this.id = localStorage.getItem('resumeId');


    }
    setPersonalMenu() {
        // console.log("resumeLang", localStorage.getItem('resumeLanguage'));
        switch (localStorage.getItem('resumeLanguage')) {
            case 'Arabic':
                this.menuItems[0].sectionname = this.menuItems[0].sectionname_ar;
                break;
            case 'French':
                this.menuItems[0].sectionname = this.menuItems[0].sectionname_fr;
                break;
            case 'Spanish':
                this.menuItems[0].sectionname = this.menuItems[0].sectionname_es;
                break;
            case 'Portuguese':
                this.menuItems[0].sectionname = this.menuItems[0].sectionname_pt;
                break;
            case 'German':
                this.menuItems[0].sectionname = this.menuItems[0].sectionname_de;
                break;
            default:
                break;
        }
    }

    testing() {
        this.ngOnInit();
    }
    getPath(element, index) {
        return "/cv/" + this.id + ROUTES[index].path + "/" + element.ids;
        window.location.reload();
    }

    new_section() {

        const modalRef = this.modalService.open(NgbdModalAddNewSection, { size: 'lg', windowClass: 'modalSections' });
        modalRef.componentInstance.language = this.resumeLanguage;
        modalRef.componentInstance.data = this.id
        modalRef.componentInstance.resumeIdArray = this.resumeIdArray
    }

    ngOnDestroy() {
        this.userService.hero = this.id
        this.subscriptionPI.unsubscribe();
    }

    changeResumeTemplate() {

        const modalRef = this.modalService.open(NgbdModalTemplateLists, { size: 'lg', windowClass: 'modalTemplate' });
        this.userService.getTemplates().subscribe((res) => {
            modalRef.componentInstance.templates = res;
            modalRef.componentInstance.resumeId = localStorage.getItem('resumeId');
            modalRef.componentInstance.templateSelected = localStorage.getItem('selected_template');
            modalRef.componentInstance.resumeLanguage = this.resumeLanguage;
        });
    }

}

@Component({
    selector: 'app-sidebar',
    template: `
   
<div class="modal-header">
    <h4 class="modal-title">{{'Add New Section' | translate}}</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')"> <i class="ft-x font-medium-3"></i> </button>
</div>
<div class="modal-body">
 
    <div class="d-flex justify-content-between flex-wrap" *ngIf="language !== 'Arabic'">
        <div class="col-12 col-md-6 col-lg-6" *ngFor="let EnglishSectionArray of EnglishSectionArray">
                 <a class="d-flex m-1 p-2 align-items-start shadow flex-column mb-3" (click)="fucntionalityfucntion(EnglishSectionArray,data,resumeIdArray) ">
                    <div class="d-flex">
                      <i [ngClass]="[EnglishSectionArray.icon]" class="mx-2 flex-shrink-1"></i> 
                    <strong class="flex-grow-1 text-bold-400 primary"> {{EnglishSectionArray.name}} </strong> 
                    </div>
                  <p class="font-small-2">{{EnglishSectionArray.desc}}</p>
                </a>
        </div>
    </div>
    <div class="d-flex justify-content-between flex-wrap" *ngIf="language === 'Arabic'">
    <div class="col-12 col-md-6 col-lg-6" *ngFor="let ArabicSectionArray of ArabicSectionArray">
             <a class="d-flex m-1 p-2 align-items-start shadow flex-column mb-3" (click)="fucntionalityfucntion(ArabicSectionArray,data,resumeIdArray) ">
                <div class="d-flex">
                  <i [ngClass]="[ArabicSectionArray.icon]" class="mx-2 flex-shrink-1"></i> 
                <strong class="flex-grow-1 text-bold-400 primary"> {{ArabicSectionArray.name}} </strong> 
                </div>
              <p class="font-small-2">{{ArabicSectionArray.desc}}</p>
            </a>
    </div>
</div>  
</div>

    `
})

export class NgbdModalAddNewSection implements OnInit {
    @Input() meta;
    @Input() data;
    language;

    public menuItems = [];
    public sectiondynamicArray: any[];
    public sections = [];
    public sub: any;
    public id: any;


    public EnglishSectionArray = [

        {
            name: "Work Experience",
            sectionname: "Work Experience",
            type: "section-work",
            icon: "fa-building",
            rightChannel: true,
            desc: "A place to highlight your professional experience - including internships",
            ids: Math.floor(100000000 + Math.random() * 900000000),
            subsections: [],
        },
        {
            name: "Education",
            sectionname: "Education",
            type: "section-education",
            icon: "fa-graduation-cap",
            rightChannel: true,
            desc: "Show off your primary education, college degrees & exchange semesters.",
            ids: Math.floor(100000000 + Math.random() * 900000000),
            subsections: [],
        },
        {
            name: "Languages",
            sectionname: "Languages",
            type: "section-languages",
            icon: "fa-comments",
            rightChannel: true,
            desc: "You speak more than one language? Make sure to list them here.",
            ids: Math.floor(100000000 + Math.random() * 900000000),
            subsections: [],
        },
        {
            name: "Empty Space",
            sectionname: "Empty Space",
            type: "section-empty",
            icon: "fa-arrow-resize8",
            rightChannel: true,
            desc: "Do you need space between Sections? use this section to add space in any place",
            ids: Math.floor(100000000 + Math.random() * 900000000),
            subsections: [],
        },
        {
            name: "Awards",
            sectionname: "Awards",
            type: "section-awards",
            icon: "fa-trophy",
            rightChannel: true,
            desc: "Awards like student competitions or industry accolades belong here.",
            ids: Math.floor(100000000 + Math.random() * 900000000),
            subsections: [],
        },
        {
            name: "Certifications",
            sectionname: "Certifications",
            type: "section-certifications",
            icon: "fa-android",
            rightChannel: true,
            desc: "Drivers licenses and other industry-specific certificates you have belong here.",
            ids: Math.floor(100000000 + Math.random() * 900000000),
            subsections: [],
        },
        {
            name: "Courses",
            sectionname: "Courses",
            type: "section-courses",
            icon: "fa-android",
            rightChannel: true,
            desc: "Did you complete MOOCs or an evening course? Show them off in this section.",
            ids: Math.floor(100000000 + Math.random() * 900000000),
            subsections: [],
        },
        {
            name: "Hobbies",
            sectionname: "Hobbies",
            type: "section-Hobbies",
            icon: "fa-gamepad",
            rightChannel: true,
            desc: "Do you have hobbies that align with your career aspiration?",
            ids: Math.floor(100000000 + Math.random() * 900000000),
            subsections: [],
        },
        {
            name: "Strengths",
            sectionname: "Strengths",
            type: "section-strengths",
            icon: "fa-gamepad",
            rightChannel: true,
            desc: "Do you have Strengths that align with your career aspiration?",
            ids: Math.floor(100000000 + Math.random() * 900000000),
            subsections: [],
        },
        {
            name: "Interests",
            sectionname: "Interests",
            type: "section-interests",
            icon: "fa-price-tags",
            rightChannel: true,
            desc: "Do you have interests that align with your career aspiration?",
            ids: Math.floor(100000000 + Math.random() * 900000000),
            subsections: [],
        },
        // {
        //     name: "Clients Logos",
        //     sectionname: "Clients Logos",
        //     type: "section-logos",
        //     icon: "fa-images",
        //     rightChannel: true,
        //     ids: Math.floor(100000000 + Math.random() * 900000000),
        //     subsections: [],
        // },
        {
            name: "Personal Skills",
            sectionname: "Personal Skills",
            type: "section-skillset",
            icon: "fa-stats-bars",
            rightChannel: true,
            desc: "List your managerial or soft skills in this section.",
            ids: Math.floor(100000000 + Math.random() * 900000000),
            subsections: [],
        },
        {
            name: "Technical Skills",
            sectionname: "Technical Skills",
            type: "section-skillset",
            icon: "fa-android",
            rightChannel: true,
            desc: "List your technical skills in this section.",
            ids: Math.floor(100000000 + Math.random() * 900000000),
            subsections: [],
        },
        // {
        //     name: "Summary",
        //     sectionname: "Summary",
        //     type: "section-text",
        //     icon: "fa-file-text",
        //     rightChannel: true,
        //     desc: "Make a great first impression by presenting yourself in a few sentences.",
        //     ids: Math.floor(100000000 + Math.random() * 900000000),
        //     subsections: [],
        // },
        {
            name: "Free Text",
            sectionname: "Free Text",
            type: "section-text-inside",
            icon: "fa-file-text",
            rightChannel: true,
            desc: "You didn't find what you are looking for? Or you want to combine two sections to save space?",
            ids: Math.floor(100000000 + Math.random() * 900000000),
            subsections: [],
        },
        {
            name: "Donut Chart",
            sectionname: "Donut Chart",
            type: "section-donut-chart",
            thickness: "4",
            legend: "right",
            hasLegend: false,
            icon: "fa-pie-chart3",
            rightChannel: true,
            desc: "Add charts and diagrams to your resume and impress recruiters with easy to understand illustrations.",
            ids: Math.floor(100000000 + Math.random() * 900000000),
            subsections: [],
        },
        {
            name: "References",
            sectionname: "References",
            type: "section-references",
            icon: "fa-users",
            rightChannel: true,
            desc: "If you have former colleagues or bosses that vouch for you, list them.",
            ids: Math.floor(100000000 + Math.random() * 900000000),
            subsections: [],
        },
        // {
        //     name: "World Map",
        //     sectionname: "World Map",
        //     type: "section-world-map",
        //     icon: "fa-map",
        //     rightChannel: true,
        //     ids: Math.floor(100000000 + Math.random() * 900000000),
        //     subsections: [],
        // },
        // {
        //     name: "Portfolio",
        //     sectionname: "Portfolio",
        //     type: "section-portfolio",
        //     icon: "fa-image",
        //     rightChannel: true,
        //     ids: Math.floor(100000000 + Math.random() * 900000000),
        //     subsections: [],
        // },
        {
            name: "Publications",
            sectionname: "Publications",
            type: "section-publications",
            icon: "fa-image",
            rightChannel: true,
            desc: "Academic publications or book releases have a dedicated place here.",
            ids: Math.floor(100000000 + Math.random() * 900000000),
            subsections: [],
        }
    ]

    public ArabicSectionArray = [

        {
            name: "الخبرات السابقة",
            sectionname: "الخبرات السابقة",
            type: "section-work",
            icon: "fa-building",
            rightChannel: true,
            desc: "مكان لتوضيح خبراتك العملية بالإضافة لأي تدريب عملي",
            ids: Math.floor(100000000 + Math.random() * 900000000),
            subsections: [],
        },
        {
            name: "التحصيل الاكاديمي",
            sectionname: "التحصيل الاكاديمي",
            type: "section-education",
            icon: "fa-graduation-cap",
            rightChannel: true,
            desc: "أظهر تعليمك في المدرسة، الجامعة و أي فصول تبادل ثقافي",
            ids: Math.floor(100000000 + Math.random() * 900000000),
            subsections: [],
        },
        {
            name: "اللغات",
            sectionname: "اللغات",
            type: "section-languages",
            icon: "fa-comments",
            rightChannel: true,
            desc: "هل تتكلم بعدة لغات؟ تأكد من كتابتهم هنا",
            ids: Math.floor(100000000 + Math.random() * 900000000),
            subsections: [],
        },
        {
            name: "مساحة فارغة",
            sectionname: "مساحة فارغة",
            type: "section-empty",
            icon: "fa-arrow-resize8",
            rightChannel: true,
            desc: "قم بإضافة هذا القسم إذا رغبت ببعض المساحة الفارغة بين الأقسام",
            ids: Math.floor(100000000 + Math.random() * 900000000),
            subsections: [],
        },
        {
            name: "دونات الرسم البياني",
            sectionname: "دونات الرسم البياني",
            type: "section-donut-chart",
            icon: "fa-pie-chart3",
            thickness: "4",
            legend: "left",
            hasLegend: false,
            rightChannel: true,
            desc: "أضف الرسوم البيانية إلى سيرتك الذاتية واثر إعجب مسؤولي التوضيف برسوم توضيحية سهلة الفهم.",
            ids: Math.floor(100000000 + Math.random() * 900000000),
            subsections: [],
        },
        {
            name: "الجوائز والتقديرات",
            sectionname: "الجوائز والتقديرات",
            type: "section-awards",
            icon: "fa-trophy",
            rightChannel: true,
            desc: "قم بإضافة الجوائز التي حزت عليها من جوائز كطالب أو كموظف في القطاع ",
            ids: Math.floor(100000000 + Math.random() * 900000000),
            subsections: [],
        },
        {
            name: "الشهادات",
            sectionname: "الشهادات",
            type: "section-certifications",
            icon: "fa-android",
            rightChannel: true,
            desc: "أضف أي رخص للقيادة أو شهادات خبرة أو مزاولة خاصة بقطاع العمل",
            ids: Math.floor(100000000 + Math.random() * 900000000),
            subsections: [],
        },
        {
            name: "الدورات",
            sectionname: "الدورات",
            type: "section-courses",
            icon: "fa-android",
            rightChannel: true,
            desc: "هل أكملت أي دورات عبز الإنترت أو دورات مسائية؟ أذكرهم هنا",
            ids: Math.floor(100000000 + Math.random() * 900000000),
            subsections: [],
        },
        {
            name: "الهوايات",
            sectionname: "الهوايات",
            type: "section-Hobbies",
            icon: "fa-gamepad",
            rightChannel: true,
            desc: "هل لديك هوايات تتماشى مع طموحاتك الوظيفية؟ أذكرهم هنا",
            ids: Math.floor(100000000 + Math.random() * 900000000),
            subsections: [],
        },
        {
            name: "مركز القوة",
            sectionname: "مركز القوة",
            type: "section-strengths",
            icon: "fa-gamepad",
            rightChannel: true,
            desc: "Do you have Strengths that align with your career aspiration?",
            ids: Math.floor(100000000 + Math.random() * 900000000),
            subsections: [],
        },
        {
            name: "الاهتمامات",
            sectionname: "الاهتمامات",
            type: "section-interests",
            icon: "fa-price-tags",
            rightChannel: true,
            desc: "هل لديك إهتمامات تتماشى مع طموحاتك الوظيفية؟ أذكرهم هنا",
            ids: Math.floor(100000000 + Math.random() * 900000000),
            subsections: [],
        },
        // {
        //     name: "Clients Logos",
        //     sectionname: "Clients Logos",
        //     type: "section-logos",
        //     icon: "fa-images",
        //     rightChannel: true,
        //     ids: Math.floor(100000000 + Math.random() * 900000000),
        //     subsections: [],
        // },
        {
            name: "المهارات الشخصية",
            sectionname: "المهارات الشخصية",
            type: "section-skillset",
            icon: "fa-stats-bars",
            rightChannel: true,
            desc: "أذكر صفاتك القيادية أو أي دورات في المهارات العامة كالخطابة أو كتابة الرسائل الإلكترونية",
            ids: Math.floor(100000000 + Math.random() * 900000000),
            subsections: [],
        },
        {
            name: "المهارات التقنية",
            sectionname: "المهارات التقنية",
            type: "section-skillset",
            icon: "fa-android",
            rightChannel: true,
            desc: "أذكر مهاراتك التقنية في هذا القسم",
            ids: Math.floor(100000000 + Math.random() * 900000000),
            subsections: [],
        },
        // {
        //     name: "الملخص",
        //     sectionname: "الملخص",
        //     type: "section-text",
        //     icon: "fa-file-text",
        //     rightChannel: true,
        //     desc: "أترك إنطباعاً أولياً مميزاً بالتكلم عن نفسك في أول بضعة جمل",
        //     ids: Math.floor(100000000 + Math.random() * 900000000),
        //     subsections: [],
        // },
        {
            name: "نص حر",
            sectionname: "نص حر",
            type: "section-text-inside",
            icon: "fa-file-text",
            rightChannel: true,
            desc: "لم تجد القسم الذي تريد؟ قم بدمج القسمين لتوفير بعض الوقت",
            ids: Math.floor(100000000 + Math.random() * 900000000),
            subsections: [],
        },
        {
            name: "المعرفون",
            sectionname: "المعرفون",
            type: "section-references",
            icon: "fa-users",
            rightChannel: true,
            desc: "إذا لديك معارف يستطيعون الشهادة لك قم بذكرهم هنا",
            ids: Math.floor(100000000 + Math.random() * 900000000),
            subsections: [],
        },
        // {
        //     name: "World Map",
        //     sectionname: "World Map",
        //     type: "section-world-map",
        //     icon: "fa-map",
        //     rightChannel: true,
        //     ids: Math.floor(100000000 + Math.random() * 900000000),
        //     subsections: [],
        // },
        // {
        //     name: "المشاريع",
        //     sectionname: "المشاريع",
        //     type: "section-portfolio",
        //     icon: "fa-image",
        //     rightChannel: true,
        //     ids: Math.floor(100000000 + Math.random() * 900000000),
        //     subsections: [],
        // },
        {
            name: "المنشورات",
            sectionname: "المنشورات",
            type: "section-publications",
            icon: "fa-image",
            rightChannel: true,
            desc: "المنشورات الأكاديمية أو الكتب يمكنك إضافتها هنا",
            ids: Math.floor(100000000 + Math.random() * 900000000),
            subsections: [],
        }



    ]

    ngOnInit() {
        this.language = localStorage.getItem('resumeLanguage');
    }
    fucntionalityfucntion(array, id, resumeIdArray) {
        resumeIdArray.sections.push(array);
        this.userService.updateresumegenericsubject(id, resumeIdArray).subscribe((res: any) => {

            this.activeModal.close();
            let last: any = res.doc.sections[res.doc.sections.length - 1];

            var index = ROUTES.findIndex(x => x.type.toString().toLowerCase() === last.type.toString().toLowerCase());
            //console.log(index);
            last.path = "/cv/" + id + ROUTES[index].path + "/" + last.ids;
            if (last.sectionname) {
                ROUTES[index].sectionname = last.sectionname;
            } else {
                ROUTES[index].sectionname = last.name;
            }

            last.sectionname = ROUTES[index].sectionname;

            this.global.emitSideMenu(last);
            this.router.navigate([last.path]);
        });


    }

    // @Input() refreshResumeList;

    constructor(public router: Router, public activeModal: NgbActiveModal,
        public route: ActivatedRoute, public translate: TranslateService, public userService: UserService, public modalService: NgbModal, public global: GlobalService) {
    }


}
