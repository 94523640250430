import { Component } from '@angular/core';

@Component({
    selector: 'app-input-groups',
    templateUrl: './input-groups.component.html',
    styleUrls: ['./input-groups.component.scss']
})

export class InputGroupsComponent {

}