import { Component, OnInit, AfterViewChecked, Output, EventEmitter, Input, ViewEncapsulation } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NouisliderModule } from 'ng2-nouislider';
import { UserService } from '../user.service';
import { GlobalService } from '../global.service';
import { Router, NavigationExtras, ActivatedRoute } from "@angular/router";
import { HttpClient } from '@angular/common/http';
import { URLSearchParams, Headers, Http, RequestOptions } from '@angular/http';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DragulaService } from 'ng2-dragula';
import { Subscription } from 'rxjs';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { RenameSectionComponent } from '../../common_modals/rename-section/rename-section.component';
import { EditResumeRoutingModule } from 'app/sections/edit-resume/edit-resume-routing.module';
import { Options, ChangeContext } from 'ng5-slider';
import {
  PerfectScrollbarConfigInterface,
  PerfectScrollbarComponent, PerfectScrollbarDirective
} from 'ngx-perfect-scrollbar';


let personId = 0;

class Person {
  id: number;
  constructor(public name: string) {
    this.id = personId++;
  }
}

@Component({
  selector: 'app-customizer',
  templateUrl: './customizer.component.html',
  styleUrls: ['./customizer.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CustomizerComponent implements OnInit {



  constructor(private dragulaService: DragulaService,
    private userService: UserService,
    private router: Router,
    private http: HttpClient,
    private modalService: NgbModal,
    public global: GlobalService,
    public route: ActivatedRoute) {
    this.global.changeOnlyTemplateWatch.subscribe(value => {
      this.resumeMeta = value['doc']['meta'];
      this.resumeData = value['doc'];
      this.templateSelected = value['doc']['meta']['defaultTemplate']['name'];
      this.colorSelected = value['doc']['meta']['defaultTemplate']['resumeColor'] ? value['doc']['meta']['defaultTemplate']['resumeColor'] : 'original';

      this.channelSelected = value['doc']['meta']['defaultTemplate']['resumeColumn'] ? value['doc']['meta']['defaultTemplate']['resumeColumn'] : '';
      this.nameSizeSelected = value['doc']['meta']['defaultTemplate']['resumeNameSize'] ? value['doc']['meta']['defaultTemplate']['resumeNameSize'] : 'm';
      this.nameStyleSelected = value['doc']['meta']['defaultTemplate']['resumeNameStyle'] ? value['doc']['meta']['defaultTemplate']['resumeNameStyle'] : 'capitalize';
      this.hideImage = value['doc']['meta']['defaultTemplate']['hideImage'] ? value['doc']['meta']['defaultTemplate']['hideImage'] : false;
      this.headingStyleSelected = value['doc']['meta']['defaultTemplate']['resumeHeadingStyle'] ? value['doc']['meta']['defaultTemplate']['resumeHeadingStyle'] : 'uppercase';
      this.hideContactIcon = value['doc']['meta']['defaultTemplate']['hideContactIcon'] ? value['doc']['meta']['defaultTemplate']['hideContactIcon'] : false;
      this.fontsizeSelected = value['doc']['meta']['defaultTemplate']['ResumeFontSize'] ? value['doc']['meta']['defaultTemplate']['ResumeFontSize'] : '1';
      this.fontSelected = value['doc']['meta']['defaultTemplate']['headingFont'] ? value['doc']['meta']['defaultTemplate']['headingFont'] : 'Raleway';
      this.bgSelected = value['doc']['meta']['defaultTemplate']['resumeBg'] ? value['doc']['meta']['defaultTemplate']['resumeBg'] : 'bg7';
      this.bgGradientSelected = value['doc']['meta']['defaultTemplate']['resumeBgGradient'] ? value['doc']['meta']['defaultTemplate']['resumeBgGradient'] : '';


      localStorage.setItem('selected_template', this.templateSelected);
      this.resetArrangeSections();
    })
    this.subs.add(dragulaService.dropModel(this.MANY_ITEMS)
      .subscribe(({ el, target, source, sourceModel, targetModel, item }) => {
        if (this.resumeMeta.defaultTemplate.resumeColumn != "one") {
          var side = target.getAttribute('side');
          // console.log(side);
          if (side == "right") {
            this.rightresumeSections = targetModel.map((section) => {
              section.rightChannel = true;
              return section;
            })
            if (sourceModel != targetModel) {
              this.leftresumeSections = sourceModel.map((section) => {
                section.rightChannel = false;
                return section;
              })
            }

          } else {
            this.leftresumeSections = targetModel.map((section) => {
              section.rightChannel = false;
              return section;
            })
            if (sourceModel != targetModel) {
              this.rightresumeSections = sourceModel.map((section) => {
                section.rightChannel = true;
                return section;
              })
            }

          }
          this.summary = this.summary.splice(0, 1);
          this.newSection = [...this.summary, ...this.leftresumeSections, ...this.rightresumeSections];
          this.resumeData.sections = this.newSection;
          this.fullresumeSections = [...this.leftresumeSections, ...this.rightresumeSections];
        } else {
          // this.fullresumeSections = targetModel.map((section) => {
          //   if (section.type != "section-text") {
          //     section.rightChannel = false;
          //     return section;
          //   }
          // })
          // console.log(targetModel);
          this.fullresumeSections = targetModel;
          this.fullresumeSections.map((section) => {
            if (section.rightChannel) {
              this.rightresumeSections.push(section);
            } else {
              this.leftresumeSections.push(section);
            }
          })
          this.newSection = [...this.summary, ...this.fullresumeSections];
          this.resumeData.sections = this.newSection;
        }
        this.userService.updateresumegeneric(this.resumeId, this.resumeData).subscribe((res: any) => {

          this.global.emitTemplateChange(res);
        })
      })
    );

  }

  newSection;
  summary = [];
  currentPage: string = "Gradient";
  resumeChannel = null;
  resumeNameSize = null;
  resumeNameStyle = null;
  hideImage = null;
  grayscale = null;
  resumeHeadingStyle = null;
  hideContactIcon = null;
  hideHeadingIcon = null;
  resumeFontSize = null;
  colorsArray = [
    { name: "original" },
    { name: "blueII" },
    { name: "blueIII" },
    { name: "blueIV" },
    { name: "blueT" },
    { name: "bBlue" },
    { name: "green" },
    { name: "greenII" },
    { name: "greenIII" },
    { name: "greenIV" },
    { name: "brown" },
    { name: "brownI" },
    { name: "brownII" },
    { name: "blue" },
    { name: "purple" },
    { name: "purpleI" },
    { name: "purpleII" },
    { name: "purpleIII" },
    { name: "purpleIV" },
    { name: "red" },
    { name: "redI" },
    { name: "pink" },
    { name: "orange" },
    { name: "orangeI" },
    { name: "gray" }


  ];

  backgroundsArray = [
    { name: "1", bg: "bg1" },
    { name: "7", bg: "bg7" },
    { name: "3", bg: "bg3" },
    { name: "4", bg: "bg4" },
    { name: "5", bg: "bg5" },
    { name: "6", bg: "bg6" },
    { name: "2", bg: "bg2" },
    { name: "8", bg: "bg8" },
    { name: "9", bg: "bg9" },
    { name: "10", bg: "bg10" },
    { name: "11", bg: "bg11" },
    { name: "12", bg: "bg12" },
    { name: "13", bg: "bg13" },
    { name: "14", bg: "bg14" }
  ];
  gradientsArray = [
    { name: "gradient-mint", },
    { name: "gradient-pomegranate" },
    { name: "gradient-blackberry" },
    { name: "gradient-flickr" },
    { name: "gradient-blue-grey-blue-grey" },
    { name: "gradient-starfall" },
    { name: "gradient-ibiza-sunset" },
    { name: "gradient-purple-bliss" },
    { name: "gradient-purple-love" },
    { name: "gradient-yassoo" },
    { name: "gradient-amiro" },
    { name: "gradient-miro" },
    { name: "gradient-red-ocean" },
    { name: "gradient-virgin-america" },
    { name: "gradient-shdow-night" },
    { name: "gradient-man-of-steel" },
    { name: "bg-11" },
    { name: "bg-22" },
    { name: "bg-33" },
    { name: "bg-44" },
    { name: "bg-55" },
    { name: "bg-66" },
    { name: "bg-77" },
    { name: "bg-88" },
    { name: "bg-99" },
    { name: "bg-1010" },
    { name: "bg-1111" },
    { name: "bg-1212" },
    { name: "bg-1313" },
    { name: "bg-1414" },
    { name: "bg-1515" },
    { name: "bg-1616" }
  ];

  EnglishfontArray = [
    { type: "Raleway", img: "Raleway.svg" },
    { type: "Roboto", img: "Roboto.svg" },
    { type: "Lora", img: "Lora.svg" },
    { type: "CormorantGaramond", img: "Cormorant Garamond.svg" },
    { type: "Lusitana", img: "Lusitana.svg" },
    { type: "Ubuntu", img: "Ubuntu.svg" },
    { type: "Merriweather", img: "Merriweather.svg" },
    { type: "Overpass", img: "Overpass.svg" },
    { type: "Quicksand", img: "Quicksand.svg" },
    { type: "Rokkitt", img: "Rokkitt.svg" }

  ];
  ArabicfontArray = [
    { name: "Noto Kufi Arabic", img: "Noto Kufi Arabic.svg" },
    { name: "El Messiri", img: "El Messiri.svg" },
    { name: "Reem Kufi", img: "Reem Kufi.svg" },
    { name: "Cairo", img: "Cairo.svg" },
    { name: "Amiri", img: "Amiri.svg" },
    { name: "Changa", img: "Changa.svg" },
    { name: "Lateef", img: "Lateef.svg" },
    { name: "Lemonada", img: "Lemonada.svg" },
    { name: "Aref Ruqaa", img: "Aref Ruqaa.svg" },
    { name: "Droid Arabic Naskh", img: "Droid Arabic Naskh.svg" },
    { name: "Tajawal", img: "Tajawal.svg" }
  ];

  // the variable if current page is edit-resume or not.
  checkEditor: any;


  showMore = false;
  showMoreFont = false;
  public optionFonts: any = {

    step: 0.1,

    showTicksValues: true,
    stepsArray: [
      { value: 0.8 },
      { value: 0.9 },
      { value: 1.0 },
      { value: 1.1 },
      { value: 1.2 },
      { value: 1.3 },
      { value: 1.4 },
      { value: 1.5 },
      { value: 1.6 },
      { value: 1.7 },
      { value: 1.8 }
    ]
  };


  public isCollapsedBackgrounds = true;
  public isCollapsedFonts = true;
  public isPdfUploading = false;
  options = {
    direction: 'ltr'
  };

  @Output() directionEvent = new EventEmitter<Object>();

  @Input() updatedResumeTemplate;

  resumeId;
  resumeData;
  resumeMeta;
  userName;
  scrollbarConfig;
  channelSelected = "";
  nameSizeSelected = "";
  nameStyleSelected = "";
  colorSelected;
  bgSelected;
  bgGradientSelected;
  fontSelected = "";
  templateSelected;
  fontsizeSelected;
  hideImageSelected;

  resumeType = "";
  headingStyleSelected = "";

  userDetails = { role: '', month: '' };
  check_resumeLang: boolean;  // check if resume language is arabic or not
  rightresumeSections = [];
  leftresumeSections = [];
  fullresumeSections = [];
  resumeSections;
  MANY_ITEMS = 'SECTION_ITEMS';
  subs = new Subscription();
  channel_type: boolean;
  ngOnInit() {
    this.rightresumeSections = [];
    this.leftresumeSections = [];
    this.fullresumeSections = [];
    this.summary = [];

    this.checkEditor = false;
    if (window.location.pathname.indexOf("/preview-resume") > -1) {
      this.checkEditor = true;
    }
    this.resumeMeta = { defaultTemplate: { name: "" }, ResumeLanguage: "" };
    /**
     * Not sure if we require this file
     * but it refreshes the screen
     */
    $.getScript('./assets/js/customizer.js');
    this.resumeId = localStorage.getItem('resumeId');

    this.userService.getResume(this.resumeId).subscribe((res) => {
      this.global.emitResume(res);
      this.resumeData = res['data'][0];
      this.resumeMeta = res['data'][0].meta;
      this.resumeType = res['data'][0]['meta']['type'];
      this.check_resumeLang = this.resumeMeta.ResumeLanguage === "Arabic" ? false : true;

      this.channelSelected = this.resumeMeta.defaultTemplate.resumeColumn;
      this.colorSelected = this.resumeMeta.defaultTemplate.resumeColor;
      this.bgSelected = this.resumeMeta.defaultTemplate.resumeBg;
      this.bgGradientSelected = this.resumeMeta.defaultTemplate.resumeBgGradient;
      this.templateSelected = this.resumeMeta.defaultTemplate.name;
      this.nameSizeSelected = this.resumeMeta.defaultTemplate.resumeNameSize;
      this.nameStyleSelected = this.resumeMeta.defaultTemplate.resumeNameStyle;
      this.headingStyleSelected = this.resumeMeta.defaultTemplate.resumeHeadingStyle;

      this.fontsizeSelected = this.resumeMeta.defaultTemplate.ResumeFontSize == null ? this.fontsizeSelected : this.resumeMeta.defaultTemplate.ResumeFontSize;
      this.hideImage = this.resumeMeta.defaultTemplate.hideImage == null ? false : this.resumeMeta.defaultTemplate.hideImage;
      this.grayscale = this.resumeMeta.defaultTemplate.grayscale == null ? false : this.resumeMeta.defaultTemplate.grayscale;
      this.hideContactIcon = this.resumeMeta.defaultTemplate.hideContactIcon == null ? false : this.resumeMeta.defaultTemplate.hideContactIcon;
      this.hideHeadingIcon = this.resumeMeta.defaultTemplate.hideHeadingIcon == null ? false : this.resumeMeta.defaultTemplate.hideHeadingIcon;
      //this.global
      localStorage.setItem('selected_template', this.templateSelected);

      this.resetArrangeSections();

    });

    this.userService.getUserProfile().subscribe(
      res => {
        this.global.emitProfile(res['user']);
        this.userDetails.role = res['user']['meta']['role'];
        if (this.userDetails.role === "OPS")
          this.userDetails.month = res['user']['payment']['months'];
      },
      err => {
        console.log(err);
      }
    );
    setTimeout(() => this.fontSelected = this.resumeMeta.defaultTemplate.headingFont, 1000);
  }
  ngAfterViewInit() {

  }
  showPage(page: string) {
    this.currentPage = page;
  }
  ngOnDestroy() {
    this.subs.unsubscribe();
  }
  ngAfterViewChecked() {
    // after checking resumeLanguage, it chooses font type.


  }

  resetArrangeSections() {
    this.rightresumeSections = [];
    this.leftresumeSections = [];
    this.fullresumeSections = [];
    this.summary = [];

    this.channel_type = this.resumeMeta.defaultTemplate.resumeColumn == "one" ? true : false;

    this.resumeSections = this.resumeData.sections;

    this.summary.push(this.resumeSections[0]);

    this.resumeSections.map((section) => {
      if (section.type != "section-text") {
        this.fullresumeSections.push(section);
        if (section.rightChannel) {
          this.rightresumeSections.push(section);
        } else {
          this.leftresumeSections.push(section);
        }
      }
    })
    if (this.channel_type !== true) {
      let tempLeft = this.leftresumeSections;
      let tempRight = this.rightresumeSections;

      let leftIds = [];
      let rightIds = [];

      tempLeft.forEach(val => {
        if (leftIds.indexOf(val.ids) === -1) {
          leftIds.push(val.ids);
        }
      });
      tempRight.forEach(val => {
        if (rightIds.indexOf(val.ids) === -1) {
          rightIds.push(val.ids);
        }
      });

      this.leftresumeSections = [];
      this.rightresumeSections = [];
      this.resumeSections.forEach(item => {
        if (leftIds.indexOf(item.ids) > -1) {
          this.leftresumeSections.push(item);
        }
        if (rightIds.indexOf(item.ids) > -1) {
          this.rightresumeSections.push(item);
        }
      });
      this.fullresumeSections = [...this.leftresumeSections, ...this.rightresumeSections];
    } else {
      let tempFull = this.fullresumeSections;
      let fullIds = [];
      tempFull.forEach(val => {
        if (fullIds.indexOf(val.ids) === -1) {
          fullIds.push(val.ids);
        }
      });
      this.fullresumeSections = [];
      this.resumeSections.forEach(item => {
        if (fullIds.indexOf(item.ids) > -1) {
          this.fullresumeSections.push(item);
        }
      });
    }
  }
  backToEditor() {
    let resume_id = localStorage.getItem("resumeId");
    this.router.navigate(['/cv/', resume_id, 'personal-info'], { relativeTo: this.route });

  }
  changeResumeTemplate() {
    if (this.resumeType != 'WebSite') {
      const modalRef = this.modalService.open(NgbdModalTemplateLists, { size: 'lg', windowClass: 'modalTemplate' });
      this.userService.getTemplates().subscribe((res) => {
        modalRef.componentInstance.templates = res;
        modalRef.componentInstance.resumeId = this.resumeId;
        modalRef.componentInstance.resumeLanguage = this.resumeMeta.ResumeLanguage;
        modalRef.componentInstance.templateSelected = this.templateSelected;
        modalRef.componentInstance.changeTemplateEvent = this.changeTemplateEvent;

      });
    } else {
      const modalRef = this.modalService.open(NgbdModalWebsiteTemplateLists, { size: 'lg', windowClass: 'modalTemplate' });
      this.userService.getWebsiteTemplates().subscribe((res) => {
        modalRef.componentInstance.templates = res;
        modalRef.componentInstance.resumeId = this.resumeId;
        modalRef.componentInstance.resumeLanguage = this.resumeMeta.ResumeLanguage;
        modalRef.componentInstance.templateSelected = this.templateSelected;
        modalRef.componentInstance.changeTemplateEvent = this.changeTemplateEvent;

      });
    }

  }

  changeTemplateEvent(template) {
    // console.log("event Emitter", template);
  }
  // ngAfterViewChecked() {
  //   console.log("ngAfterViewChecked called")

  //   // setTimeout(() => {
  //   //   var wrapperDiv = document.getElementsByClassName("wrapper")[0];
  //   //   var dir = wrapperDiv.getAttribute("dir");
  //   //   this.options.direction = dir;
  //   // }, 3000);


  // }

  sendOptions() {
    this.directionEvent.emit(this.options);
  }

  changeImage() {
    this.resumeMeta.defaultTemplate.hideImage = this.hideImage;
    let meta = {
      meta: this.resumeMeta
    }
    this.userService.updateresumegeneric(this.resumeId, meta).subscribe((res: any) => {
      this.global.emitTemplateChange(res);
    });
  }
  changeGrayscale() {
    this.resumeMeta.defaultTemplate.grayscale = this.grayscale;
    let meta = {
      meta: this.resumeMeta
    }
    this.userService.updateresumegeneric(this.resumeId, meta).subscribe((res: any) => {
      this.global.emitTemplateChange(res);
    });
  }
  changeHideContactIcon() {
    this.resumeMeta.defaultTemplate.hideContactIcon = this.hideContactIcon;
    let meta = {
      meta: this.resumeMeta
    }
    this.userService.updateresumegeneric(this.resumeId, meta).subscribe((res: any) => {
      this.global.emitTemplateChange(res);
    });
  }
  changeResumeNameSize(size: any) {
    this.nameSizeSelected = size;
    this.resumeMeta.defaultTemplate.resumeNameSize = size;
    let meta = {
      meta: this.resumeMeta
    }
    this.userService.updateresumegeneric(this.resumeId, meta).subscribe((res: any) => {
      this.global.emitTemplateChange(res);
    });
  }
  changeResumeNameStyle(style: any) {
    this.nameStyleSelected = style;
    this.resumeMeta.defaultTemplate.resumeNameStyle = style;
    let meta = {
      meta: this.resumeMeta
    }
    this.userService.updateresumegeneric(this.resumeId, meta).subscribe((res: any) => {
      this.global.emitTemplateChange(res);
    });
  }
  changeResumeChannel(channel) {
    this.channelSelected = channel;
    this.resumeMeta.defaultTemplate.resumeColumn = channel;
    let meta = {
      meta: this.resumeMeta
    }
    this.resetArrangeSections();
    this.userService.updateresumegeneric(this.resumeId, meta).subscribe((res: any) => {
      this.global.emitTemplateChange(res);
    });
  }
  changeResumeHeadingStyle(style: any) {
    this.headingStyleSelected = style;
    this.resumeMeta.defaultTemplate.resumeHeadingStyle = style;
    let meta = {
      meta: this.resumeMeta
    }
    this.userService.updateresumegeneric(this.resumeId, meta).subscribe((res: any) => {
      this.global.emitTemplateChange(res);
    });
  }
  changeHideHeadingIcon() {
    this.resumeMeta.defaultTemplate.hideHeadingIcon = this.hideHeadingIcon;
    let meta = {
      meta: this.resumeMeta
    }
    this.userService.updateresumegeneric(this.resumeId, meta).subscribe((res: any) => {
      this.global.emitTemplateChange(res);
    });
  }
  changeColour(colour) {
    this.colorSelected = colour;
    this.resumeMeta.defaultTemplate.resumeColor = colour;
    let meta = {

      meta: this.resumeMeta
    }
    this.userService.updateresumegeneric(this.resumeId, meta).subscribe((res: any) => {
      this.global.emitTemplateChange(res);
    });
  }

  changeBackground(background) {
    this.bgSelected = background;
    this.resumeMeta.defaultTemplate.resumeBg = background;
    let meta = {
      meta: this.resumeMeta
    }
    this.userService.updateresumegeneric(this.resumeId, meta).subscribe((res: any) => {
      this.global.emitTemplateChange(res);
    });
  }
  changeGradient(gradient) {
    this.bgGradientSelected = gradient;
    this.resumeMeta.defaultTemplate.resumeBgGradient = gradient;
    let meta = {
      meta: this.resumeMeta
    }
    this.userService.updateresumegeneric(this.resumeId, meta).subscribe((res: any) => {
      this.global.emitTemplateChange(res);
    });
  }

  changeFontFamily(fontfamily) {
    this.fontSelected = fontfamily;
    this.resumeMeta.defaultTemplate.headingFont = fontfamily;
    let meta = {
      meta: this.resumeMeta
    }
    this.userService.updateresumegeneric(this.resumeId, meta).subscribe((res: any) => {
      this.global.emitTemplateChange(res);
    });

  }
  changeFontSize(fontSize): void {
    this.resumeMeta.defaultTemplate.ResumeFontSize = fontSize;
    this.fontsizeSelected = fontSize;
    let meta = {
      meta: this.resumeMeta
    }
    this.userService.updateresumegeneric(this.resumeId, meta).subscribe((res: any) => {
      this.global.emitTemplateChange(res);
    });
  }
  onUserChangeStart(changeContext: ChangeContext): void {
    this.resumeMeta.defaultTemplate.ResumeFontSize = changeContext.value;
  }
  onUserChange(changeContext: ChangeContext): void {
    this.resumeMeta.defaultTemplate.ResumeFontSize = changeContext.value;
  }
  data;
  id;
  headers: any;
  getData() {
    let urlSearchParams = new URLSearchParams();

  }

  arrangeSections() {
    //const modalRef = this.modalService.open(NgbdModalArrangeSections, { size: 'lg', windowClass: 'modalArrange' });
    //modalRef.componentInstance.data = this.resumeData;

  }

  handleFileUpload(files: FileList) {
    let formData = new FormData;
    formData.append('file', files[0]);
    this.isPdfUploading = true;
    this.userService.uploadFileToAws(formData)
      .subscribe(res => {
        this.resumeMeta.pdf = res['file']['location'];
        this.resumeMeta.pdfName = res['file']['originalname'];
        this.resumeMeta.pdfPath = res['file']['key'];
        this.isPdfUploading = false;
        this.metaUpdate();
      })
  }

  deletePDF(fileName) {
    this.userService.removeFileAws({ fileName: fileName })
      .subscribe(res => {
        delete this.resumeMeta['pdf'];
        delete this.resumeMeta['pdfName'];
        delete this.resumeMeta['pdfPath'];

        this.metaUpdate();
        this.isPdfUploading = false;
      })
  }
  metaUpdate() {
    let meta = {
      meta: this.resumeMeta
    }
    this.userService.updateresumegeneric(this.resumeId, meta).subscribe((res: any) => {
      this.global.emitTemplateChange(res);
    });
  }
}



@Component({
  selector: 'app-user-dashboard',

  template: `
  <div class="modal-header">
  <h2 style="color:black">{{'Template Lists' | translate}}</h2>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')"> <i class="ft-x font-medium-3"></i></button>
</div>
<div class="asdUUYHh" style="height: 77vh;overflow-y: scroll;">
<div class="modal-body text-center">
   <h6 class="text-center badge badge bg-yellow black bg-darken-1 d-inline-block text-wrap " style="line-height:1.5">{{'You can change the template and colors at any time without having to rewrite the content' | translate}}</h6>
  <div class="row">
    <div class="col col-12 col-md-12 col-lg-4" *ngFor="let item of templates; let i = index">
		 <div class="page-section">
      <div class="card border tempHover">
      <div class="card-body" [ngClass]="{'active op5': templateSelected==item.name}"><img   *ngIf="item.isPro"  class="position-absolute tempStatus d-none"  src="assets/img/prem-en.png"> <img   *ngIf="!item.isPro"  class="position-absolute  tempStatus d-none"  src="assets/img/basic-en.png"><picture *ngIf="resumeLanguage != 'Arabic'" class="card-img-top img-fluid" (click)="updateTemplate(item)" class="w-100"><source srcset="assets/img/templates-wizard/webp/webp-en/{{item.slug}}-en.webp" type="image/webp"  class="w-100"><img src="assets/img/templates-wizard/webp/webp-en/jpg/{{item.slug}}-en.jpg" alt="{{item.name}}"  class="w-100"/></picture><picture *ngIf="resumeLanguage == 'Arabic'" class="card-img-top img-fluid" (click)="updateTemplate(item)"  class="w-100"><source srcset="assets/img/templates-wizard/webp/webp-ar/{{item.slug}}-ar.webp" type="image/webp"  class="w-100"><img src="assets/img/templates-wizard/webp/webp-ar/jpg/{{item.slug}}-ar.jpg" alt="{{item.name}}"  class="w-100" /></picture>
      </div>
      </div>
    <div class="card-block  text-center py-1 light">
            <h4 class="card-title m-0">{{item.name}} <span *ngIf="item.isPro" class="badge bg-yellow black bg-darken-1 font-small-2">{{'Premium' | translate}}</span> <span *ngIf="!item.isPro" class="badge badge-secondary font-small-2">{{'Free' | translate}}</span> </h4>
            <a class="btn bg-primary white my-1 px-3  btn-ssm m-0 w-100" (click)="updateTemplate(item)"> {{'Use this template' | translate}} </a> </div>
		</div>
  </div>
	    </div>
      </div>
</div>
  `
})

export class NgbdModalTemplateLists {
  @Input() templates;
  @Input() resumeId;
  @Input() templateSelected;
  @Input() resumeLanguage;


  @Output() changeTemplateEvent = new EventEmitter<any>();

  constructor(private dragulaService: DragulaService,
    public activeModal: NgbActiveModal,
    private userService: UserService,
    private router: Router,
    private http: HttpClient,
    private modalService: NgbModal,
    public global: GlobalService) {
    this.changeTemplateEvent = new EventEmitter();
  }
  updateTemplate(template: any) {
    let meta = {
      meta: template,
      type: 'Template',
      language: localStorage.getItem('resumeLanguage')
    }
    this.userService.updateresumetemplate(this.resumeId, meta).subscribe((res: any) => {
      this.activeModal.dismiss('Cross click');
      this.global.emitOnlyTemplateChange(res);
      this.global.emitTemplateChange(res);
      //this.changeTemplateEvent.emit(template);
    });
  }
}

@Component({
  selector: 'app-user-dashboard',

  template: `
  <div class="modal-header">
  <h2 style="color:black">{{'Template Lists' | translate}}</h2>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')"> <i class="ft-x font-medium-3"></i></button>
</div>
<div class="asdUUYHh" style="height: 77vh;overflow-y: scroll;">
<div class="modal-body text-center">
   <h6 class="text-center badge badge bg-yellow black bg-darken-1 d-inline-block text-wrap " style="line-height:1.5">{{'You can change the template and colors at any time without having to rewrite the content' | translate}}</h6>
  <div class="row">
    <div class="col col-md-12 col-sm-12 col-lg-6" *ngFor="let item of templates; let i = index">
		 <div class="page-section">
      <div class="card border tempHover">
      <div class="card-body" [ngClass]="{'active op5 Webbbb': templateSelected==item.name}"><img   *ngIf="item.isPro"  class="position-absolute tempStatus d-none"  src="assets/img/prem-en.png"> <img   *ngIf="!item.isPro"  class="position-absolute  tempStatus d-none"  src="assets/img/basic-en.png"><picture *ngIf="resumeLanguage != 'Arabic'" class="card-img-top img-fluid" (click)="updateTemplate(item)" class="w-100"><source srcset="assets/img/templates-wizard/webp/webp-en/{{item.slug}}-en.webp" type="image/webp"  class="w-100"><img src="assets/img/templates-wizard/webp/webp-en/jpg/{{item.slug}}-en.jpg" alt="{{item.name}}"  class="w-100"/></picture><picture *ngIf="resumeLanguage == 'Arabic'" class="card-img-top img-fluid" (click)="updateTemplate(item)"  class="w-100"><source srcset="assets/img/templates-wizard/webp/webp-ar/{{item.slug}}-ar.webp" type="image/webp"  class="w-100"><img src="assets/img/templates-wizard/webp/webp-ar/jpg/{{item.slug}}-ar.jpg" alt="{{item.name}}"  class="w-100" /></picture>

        </div>
      </div>
    <div class="card-block  text-center py-1 light">
            <h4 class="card-title m-0">{{item.name}} <span *ngIf="item.isPro" class="badge bg-yellow black bg-darken-1 font-small-2">{{'Premium' | translate}}</span> <span *ngIf="!item.isPro" class="badge badge-secondary font-small-2">{{'Free' | translate}}</span> </h4>
            <a class="btn bg-primary white my-1 px-3  btn-ssm m-0 w-100" (click)="updateTemplate(item)"> {{'Use this template' | translate}} </a> </div>
		</div>
  </div>
  </div></div>
</div>
  `
})

export class NgbdModalWebsiteTemplateLists {
  @Input() templates;
  @Input() resumeId;
  @Input() templateSelected;
  @Input() resumeLanguage;


  @Output() changeTemplateEvent = new EventEmitter<any>();

  constructor(private dragulaService: DragulaService,
    public activeModal: NgbActiveModal,
    private userService: UserService,
    private router: Router,
    private http: HttpClient,
    private modalService: NgbModal,
    public global: GlobalService) {
    this.changeTemplateEvent = new EventEmitter();
  }
  updateTemplate(template: any) {
    let meta = {
      meta: template,
      type: 'Template',
      language: localStorage.getItem('resumeLanguage')
    }
    this.userService.updateresumetemplate(this.resumeId, meta).subscribe((res: any) => {
      this.activeModal.dismiss('Cross click');
      this.global.emitOnlyTemplateChange(res);
      this.global.emitTemplateChange(res);
      //this.changeTemplateEvent.emit(template);
    });
  }
}
