import {
  Component, OnInit,
  OnDestroy,
  ViewChild,
  ElementRef,
  ChangeDetectorRef,
  HostListener
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgForm } from '@angular/forms';
import { UserService } from '../shared/user.service';
import { GlobalService } from '../shared/global.service';
import { environment } from '../../environments/environment';
import { ActivatedRoute, Router } from '@angular/router';
import { data } from 'app/shared/data/smart-data-table';
import { isEmpty } from 'lodash';
import { timeout } from 'rxjs/operators';
import * as moment from 'moment';
declare var elements: any;
declare var $: any;
declare var fastspring: any;

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss']
})
export class PaymentComponent implements OnInit {
  // TwoCoInlineCart = window['TwoCoInlineCart'];
  userName;
  cardInfo;
  paypalcardInfo;
  userdetails = { firstName: '', lastName: '', email: '', role: '', id: '' };
  user = { id: '', email: '' };
  windows_detail;

  window_stripe;
  card: any;
  error: string;
  subscription_duration = null;
  subscription = null;
  subscriptionmessage = "";
  showPaymentForm: boolean = false;
  showLoader: boolean = false;
  showError: boolean = false;
  showBtnLoader: boolean = false;
  cardError = null;
  cancelText = null;
  alert = { type: '', message: '' };
  total;
  amount;
  months;
  recursive;
  responseAmount;
  transactionId;
  city: string;
  country: string;
  zipcode: string;
  phone: string;
  state: string;
  address: string;
  showAddressEdit: Boolean = true;
  showPleaseWait;
  paypalstripInfo;
  userEmail;
  fastspring_path;
  @ViewChild('cardInfo') stripCardInfo: ElementRef;
  @ViewChild('cardNumber') myStripeCardNumber: ElementRef;
  @ViewChild('cardExpiry') myStripeCardExpiry: ElementRef;
  @ViewChild('cardCVC') myStripeCardCVC: ElementRef;
  mStripeCardNumber;
  mStripeCardExpiry;
  mStripeCardCVC;
  stripeElements;
  stripeCard;
  stripeClientSecret;
  stripeCardHandler = this.onStripeChange.bind(this);

  @HostListener('window:onFSPopupClosed', ['$event.detail'])
  onFSPopupClosed(detail) {
    if (detail && detail.orderReference && detail.orderReference.id) {

      let cardInfo = {
        userid: this.user.id,
        email: this.user.email,
        dura: this.Duration,
        recurring: 'yes',
        order_id: detail.orderReference.id,
        automatic_payment_methods: {
          enabled: false,
        },
        order_reference: detail.orderReference.reference,
        created_date: moment().format('YYYY-MM-DD'),
        end_date: moment().add(this.Duration, 'months').format('YYYY-MM-DD')
      }
      // this.userService.getFastspringSubscriptionDetail({order_id: cardInfo.order_id})
      //   .subscribe(res => {console.log(res)});
      this.userService.fastspringCheckout(cardInfo)
        .subscribe(res => {
          // console.log(res);
          this.router.navigateByUrl('payment/success?thank-you=true');
        })
      // Payment done successfull
    }
  }

  constructor(public userService: UserService,
    private activatedRoute: ActivatedRoute,
    public global: GlobalService,
    private router: Router,
    private cd: ChangeDetectorRef) {
    this.global.userDetailWatch.subscribe(value => {
      this.userdetails.firstName = value['firstName'];
      this.userdetails.lastName = value['lastName'];
      this.userdetails.email = value['email'];
      this.userdetails.role = value['meta']['role'];
      this.userdetails.id = value['_id'];
      this.user.id = this.userdetails.id;
    })
  }

  getIPInformation(loc) {
  }
  ngAfterViewInit() {
    this.showingStripCard();

  }

  changeStripTab() {

    if (this.card) {
      this.card.destroy();
    }

    this.showingStripCard();
  }
  showingStripCard() {
    this.initializeStripe();
  }
  ngOnInit() {
    // console.log("sdfsdfsd");
    this.showPleaseWait = true;
    this.userService.getIPInformation('').subscribe((data: IPInformation) => {
      this.city = data.city;
      this.phone = data.country_calling_code;
      this.zipcode = data.country_calling_code.replace('+', '00');
      this.address = data.region + ', ' + data.country_name;
      this.country = data.country_name;
      this.state = data.region;

      // console.log('ips info', data);
    });
    // $.getScript('https://www.2checkout.com/checkout/api/2co.min.js');


    this.userService.getUserProfile().subscribe(
      res => {
        var userDetails = res['user'];
        this.user.id = userDetails._id;
        this.user.email = userDetails.email;
        this.userdetails.firstName = userDetails['firstName'];
        this.userdetails.lastName = userDetails['lastName'];
        this.userdetails.email = userDetails['email'];
        this.userdetails.role = userDetails['meta']['role'];
        this.userdetails.id = userDetails['_id'];
      },
      err => {
        console.log(err);
      }
    );
    this.windows_detail = window;
    this.window_stripe = this.windows_detail.stripe;

    // this.windows_detail.TCO.loadPubKey('sandbox')
    this.windows_detail.TCO.loadPubKey('production')

    this.activatedRoute.queryParams.subscribe(params => {
      // if (params.type == "one_month") {
      //   this.amount = 19.99;
      //   this.months = 1;
      //   this.total = "19.99";
      //   this.Duration = 1;
      //   this.recurring = 'yes';

      //   //also here we have to put product ID later
      // }
      // if (params.type == "three_months") {
      //   this.amount = 9.99;
      //   this.months = 3;
      //   this.total = 9.99 * 3;
      //   this.Duration = 3;
      //   this.recurring = 'no';
      //   //console.log("in 3 months", this.total)
      // }
      // if (params.type == "yearly") {
      //   this.amount = 4.99;
      //   this.months = 12;
      //   this.total = 4.99 * 12;
      //   this.Duration = 12;
      //   this.recurring = 'yes';
      //   // console.log("in 12 months", this.total)
      // }
      if (params.type == "one_month") {
        this.amount = 19.99;
        this.months = 1;
        this.total = "19.99";
        this.Duration = 1;
        this.recurring = 'yes';
        this.fastspring_path = 'silver';

        //also here we have to put product ID later
      }
      if (params.type == "three_months") {
        this.amount = 13.33;
        this.months = 3;
        this.total = "39.99";
        this.Duration = 3;
        this.recurring = 'no';
        this.fastspring_path = 'gold';
        //console.log("in 3 months", this.total)
      }
      if (params.type == "yearly") {
        this.amount = 8.33;
        this.months = 12;
        this.total = "99.99";
        this.Duration = 12;
        this.recurring = 'yes';
        this.fastspring_path = 'platinum';
        // console.log("in 12 months", this.total)
      }


      // if (params.type == "one_month") {
      //   this.amount = 13.50;
      //   this.months = 1;
      //   this.total = "13.50";
      //   this.Duration = 1;
      //   this.recurring = 'yes';

      //   //also here we have to put product ID later
      // }
      // if (params.type == "three_months") {
      //   this.amount = 7.50;
      //   this.months = 3;
      //   this.total = "22.50";
      //   this.Duration = 3;
      //   this.recurring = 'no';
      //   //console.log("in 3 months", this.total)
      // }
      // if (params.type == "yearly") {
      //   this.amount = 3.75;
      //   this.months = 12;
      //   this.total = "45.00";
      //   this.Duration = 12;
      //   this.recurring = 'yes';
      //   // console.log("in 12 months", this.total)
      // }


      // this.responseAmount = params.total_amount
      this.onPaymentResponse(params['response_type']);
    });

  }

  ngOnDestroy() {
    if (this.stripeCard) {
      this.stripeCard.removeEventListener('change', this.stripeCardHandler);
      this.stripeCard.destroy();
    }

  }
  // pi_3KAYtwK62HihIcP61TJosq8t_secret_wYAbwEgVYlvBBp44pzVFaKHlZ
  // pi_3KAYsGK62HihIcP61cCYsQbK_secret_m92pFsU3clQpXJyhyGxpVKSkF
  onStripeChange(val) {
    // if (error) {
    //   this.error = error.message;
    // } else {
    //   this.error = null;
    // }
    this.cd.detectChanges();
  }

  async initializeStripe() {

    //return this.http.post(environment.apiBaseUrl + '/order', cardinfo);
    this.userName = this.userdetails.email;
    let lang = localStorage.getItem('locale');
    let stripeInfo = {
      username: this.userName,
      dura: this.Duration,
      email: this.userdetails.email,
      recurring: this.recurring
    }
    this.showLoader = true;
    this.cd.detectChanges();
    this.userService.getStripeTemplate(stripeInfo)
      .subscribe(response => {
        // console.log(response);
        const clientSecret = response['clientSecret'];
        const setupId = response['setup_id'];
        this.stripeClientSecret = setupId;
        const appearance = {
          theme: 'stripe',

        };
        // this.stripeElements = this.window_stripe.elements({ appearance, clientSecret, locale: lang, loader: 'auto' });
        this.stripeElements = this.window_stripe.elements({ locale: lang, loader: 'auto' });
        var style = {
          base: {
            iconColor: '#303030',
            color: '#303030',
            lineHeight: '26px',
            fontWeight: 400,
            fontFamily: '"Open Sans", "Helvetica Neue", "Helvetica", sans-serif',
            fontSize: '17px',
            "::placeholder": {
              color: "#8c8c8c"
            }
          },
          invalid: {
            fontFamily: '"Open Sans", "Helvetica Neue", "Helvetica", sans-serif',
            color: "#ce4840",
            iconColor: "#fa755a"
          }
        };
        this.mStripeCardNumber = this.stripeElements.create('cardNumber', {
          style: style,
          // placeholder: 'Custom CVC placeholder',
        });
        this.mStripeCardExpiry = this.stripeElements.create('cardExpiry', { style: style });
        this.mStripeCardCVC = this.stripeElements.create('cardCvc', {
          style: style,
          // placeholder: 'CVC....',
        });
        // this.stripeCard = this.stripeElements.create("card", {style: style, hidePostalCode: true});
        this.showLoader = false;
        setTimeout(() => {
          this.mStripeCardNumber.mount(this.myStripeCardNumber.nativeElement);
          this.mStripeCardExpiry.mount(this.myStripeCardExpiry.nativeElement);
          this.mStripeCardCVC.mount(this.myStripeCardCVC.nativeElement);
          // this.stripeCard.mount(this.stripCardInfo.nativeElement);
          // this.stripeCard.addEventListener('change', this.stripeCardHandler);
        }, 10);
      })
  }

  onChange({ error }) {
    if (error) {
      this.error = error.message;
    } else {
      this.error = null;
    }
    this.cd.detectChanges();
  }
  Duration;
  recurring;
  hideForm() {
    this.showPaymentForm = (this.showPaymentForm == true ? false : true);
  }


  onPaymentResponse(type) {
    if (type == "success") {
      this.alert = {
        type: 'success',
        message: 'Transaction Successful',
      }
      this.close();

    }
    else if (type == "error") {
      this.alert = {
        type: 'danger',
        message: 'Transaction Failed Please Try Again',
      }
      this.close()
    }
    else if (type && type.length) {
      this.alert = {
        type: 'warning',
        message: 'transaction in progress.. please donot refresh!',
      }
    }

  }
  // close(alert: Alert) {
  //   //this.alerts.splice(this.alerts.indexOf(alert), 1);
  //
  close() {
    setTimeout(() => this.alert = { type: '', message: '' }, 8000);
  }

  successCallback = (data) => {
    this.cardInfo['token'] = data.response.token;

    this.userService.payment(this.cardInfo).subscribe(finaldata => {
      if (finaldata['status'] == 1) {
        this.showPleaseWait = true
        this.alert = {
          type: 'success',
          message: 'Transaction Successful',
        }
        this.close()
        this.router.navigate(['/payment/success'], { queryParams: { 'thank-you': 'true' } });
      } else {
        this.showPleaseWait = true
        this.alert = {
          type: 'danger',
          message: `${finaldata['error']}. Try-Again`
        }
      }

    }, (error) => {

    })
  }

  errorCallback = (data) => {
    console.log(data);
    this.showPleaseWait = true
    this.alert = {
      type: 'danger',
      message: data.errorMsg || "Something went wrong!"

    }
    this.close();
  }

  tokenRequest() {
    // Setup token request arguments
    this.cardInfo['publishableKey'] = environment.Towcheckout_publishableKey;
    this.cardInfo['sellerId'] = environment.Towcheckout_sellerId;

    // console.log(this.windows_detail)
    // Make the token request
    let check = this.windows_detail.TCO.requestToken(this.successCallback, this.errorCallback, this.cardInfo);

    // setTimeout (() => {
    //   this.showPleaseWait = true;
    // }, 5000);
  }

  onSubmit(form: NgForm) {
    this.showPleaseWait = false;
    this.userName = localStorage.getItem('userName');

    this.cardInfo = {

      ccNo: form.value.ccNumber,
      expMonth: form.value.expMonth,
      expYear: form.value.expYear,
      cvv: form.value.cvv,
      firstname: form.value.firstname,
      lastname: form.value.lastname,
      address: form.value.address,
      phone: form.value.phone,
      city: form.value.city,
      state: form.value.state,
      // zipcode: '00966',
      zipcode: form.value.zipcode,
      email: this.userdetails.email,
      country: form.value.country,
      username: this.userName,
      months: this.months,
      total_amount: this.total,
    }

    this.tokenRequest();
    // form.reset();
  }

  async onStripSubmit(form: NgForm) {
    this.showBtnLoader = true;
    // let response = await this.window_stripe.confirmSetup({
    //   elements: this.stripeElements, // it is mac u have to use command + z
    //   confirmParams: {
    //     // Return URL where the customer should be redirected after the SetupIntent is confirmed.
    //     return_url: 'http://localhost:4200/payment/success?thank-you=true',
    //   },
    //   redirect: 'if_required'
    // })
    // console.log("response", response);
    // if(response.error){
    //   this.showBtnLoader = false;
    //   this.showError = true;
    //   this.cardError = response.error.message;
    //   this.stripeCard.clear();
    //   return;
    // }
    // if (response['error']) {
    //   this.showError = true;
    //   this.showBtnLoader = false;
    //   this.cardError = response['error']['message'];
    //   return;
    // }
    // console.log(this.stripeElements);
    // console.log(this.stripeCard);
    // return;


    let response = await this.window_stripe.createPaymentMethod({
      type: 'card',
      card: this.mStripeCardNumber,
      billing_details: {
        name: this.userName,
        address: {
          line1: this.address,
          line2: this.state,
          postal_code: this.zipcode
        }
      },
    })

    // console.log(response);
    this.userName = this.userdetails.email;
    this.paypalcardInfo = {
      username: this.userName,
      dura: this.Duration,
      email: this.userdetails.email,
      recurring: this.recurring,
      cancel: "no",
      // clientSecret: response['setupIntent']['id'],
      // paymentMethodId: response['setupIntent']['payment_method'],
      paymentMethodId: response.paymentMethod.id,
      billingAddr: {
        line1: this.address,
        line2: this.state,
        postal_code: this.zipcode,
        state: this.state,
        city: this.zipcode,
        country: this.country
      },
    }
    // console.log(this.paypalcardInfo);
    this.userService.stripecheckout(this.paypalcardInfo)
      .subscribe(async (finaldata) => {
        // console.log('here here')
        // console.log({ finaldata })

        if (finaldata['error'] != undefined) {
          this.showLoader = false;
          this.showBtnLoader = false;
          this.showError = true;
          this.cardError = finaldata['error'].raw.message;
        } else {

          var sub = finaldata['subscription'];

          if ((sub.status == "active" || sub.status == false) && sub.latest_invoice.payment_intent.status == "succeeded") {
            this.showBtnLoader = false;
            this.subscriptionmessage = "Congrats!!! Successfully Subscribed!";
            this.subscription_duration = this.Duration
            this.userName = localStorage.getItem('userName');
            this.router.navigateByUrl('payment/success?thank-you=true');
          }
          else if ((sub.status == "incomplete" || sub.status == true) && sub.latest_invoice.payment_intent.status == "requires_payment_method") {

            this.showBtnLoader = false;
            this.showError = true;
            this.cardError = sub.latest_invoice.payment_intent.last_payment_error.message;
          }
          else if ((sub.status == "incomplete" || sub.status == true) && (sub.latest_invoice.payment_intent.status == "requires_action" || sub.latest_invoice.payment_intent.status == "requires_source_action")) {
            // console.log("subscription", sub);
            // console.log("💰 Payment error here!");
            // console.log(sub.latest_invoice);
            this.window_stripe
              .confirmCardPayment(sub.latest_invoice.payment_intent.client_secret, {
                payment_method: sub.latest_invoice.payment_intent.payment_method,
              })
              .then((result) => {
                // console.log(result);
                if (result.error) {
                  // Start code flow to handle updating the payment details.
                  // Display error message in your UI.
                  // The card was declined (that is, insufficient funds, card has expired, etc).
                  // console.log(result.error);

                  throw result.error;
                } else {
                  if (result.paymentIntent.status === 'succeeded') {
                    // Show a success message to your customer.
                    this.router.navigateByUrl('payment/success?thank-you=true');
                  }
                }
              })
              .catch((error) => {
                // console.log("Error occurred", error)
                this.showBtnLoader = false;
                this.showError = true;
                this.cardError = error.message;
              });
            // var action = sub.latest_invoice.payment_intent.next_action;
            // if (action.use_stripe_sdk.stripe_js) {
            //   console.log("step 1 ..... if:action.use_stripe_sdk.stripe_js) ");
            //   fetch(action.use_stripe_sdk.stripe_js)
            //     .then(response => {
            //       return response.text();
            //     })
            //     .then(body => {
            //       let formData = new FormData;
            //       formData.append('PaRes', 'success');
            //       formData.append('MD', '');
            //       let return_url = $(body).find("input[name='return_url']").val();
            //       fetch(return_url, { method: 'post', body: formData }).then(res => {
            //         return res.text();
            //       }).then(res => {
            //         return_url = $($(res)[3]).attr('action');
            //         let merchant = $(res).find("input[name='merchant']").val();
            //         formData.append('merchant', merchant);
            //         fetch(return_url, { method: 'post', body: formData }).then(res => {
            //           return res.text();
            //         }).then(res => {
            //           console.log("subbbbbb idd:", sub.id);
            //           this.userService.getStripeSubscriptionDetail(sub.id)
            //             .subscribe(res => {
            //               let subscription = res['subscriptions'];
            //               console.log("subscription", subscription);
            //               if (subscription['status'] == 'active') this.router.navigateByUrl('payment/success?thank-you=true');
            //               else {
            //                 this.showBtnLoader = false;
            //                 this.showError = true;
            //                 if (subscription.latest_invoice.payment_intent.last_payment_error) {
            //                   this.cardError = subscription.latest_invoice.payment_intent.last_payment_error.message;
            //                 } else {
            //                   this.cardError = 'Error';
            //                 }

            //               }
            //             })
            //           //console.log("ssssss", res);
            //           //this.router.navigateByUrl('payment/success?thank-you=true');
            //         })
            //       })
            //     })
            // } else {
            //   console.log("Error occurred")
            //   this.showBtnLoader = false;
            //   this.showError = true;
            //   this.cardError = 'Error';
            // }
          }
        }
      });
  }

  paypalSubmit(form: NgForm) {
    this.userName = localStorage.getItem('userName');

    this.recursive = form.value.recursive

    this.paypalcardInfo = {
      billingaddress: form.value.billingaddress,
      city: form.value.city,
      phoneno: form.value.phoneno,
      country: form.value.country,
      firstname: form.value.firstname,
      lastname: form.value.lastname,
      amount_per_months: this.amount,
      total_amount: this.total,
      months: this.months,
      username: this.userName
    }
    // form.reset();
    if (this.months == 1 || this.months == 12) {
      this.userService.createAgreement(this.paypalcardInfo).subscribe(data => {
        if (data['approved_url']) {
          this.windows_detail.location.href = data['approved_url'];
        }
      })
    } else {
      this.userService.paypalpayment(this.paypalcardInfo).subscribe(data => {
        if (data['approved_url']) {
          this.windows_detail.location.href = data['approved_url'];

        }

      })
    }

  }

  showAddressEditForm() {
    this.showAddressEdit = !this.showAddressEdit;
  }
  openURL_1m() {

    const url = 'https://buy.stripe.com/fZeg040uOb2X6M8cMQ?prefilled_email={{userdetails.email}}&client_reference_id={{userdetails.id}}'; // Replace with your desired URL
    window.open(url, '_self');
  }
  openURL_3m() {

    const url = 'https://buy.stripe.com/cN2dRWa5o7QL6M828j?prefilled_email={{userdetails.email}}&client_reference_id={{userdetails.id}}'; // Replace with your desired URL
    window.open(url, '_self');
  }
  openURL_12m() {

    const url = 'https://buy.stripe.com/fZeaFKb9sc715I4149?prefilled_email={{userdetails.email}}&client_reference_id={{userdetails.id}}'; // Replace with your desired URL
    window.open(url, '_self');
  }
  popupStoreFront(itemIdentifier: string): void {
    let lang = localStorage.getItem('locale');
    fastspring.builder.push({
      language: lang,
      reset: true,
      products: [
        { path: this.fastspring_path, quantity: 1 }
      ],
      checkout: true,
      paymentContact: {
        email: this.userdetails.email
      }
    });
  }
  // initInlinePayment() {
  //   this.TwoCoInlineCart.products.add({
  //     code: "10001"
  //   });
  //   // Style 1 (all data at once)
  //   this.TwoCoInlineCart.billing.setData({
  //     name: 'John Doe',
  //     email: 'john.snow@2checkout.com',
  //     phone: '+33 892 70 12 39',
  //     country: 'FR',
  //     city: 'Paris',
  //     state: '',
  //     zip: '75007',
  //     address: 'Champ de Mars',
  //     address2: ''
  //   });
  //   this.TwoCoInlineCart.cart.setCartLockedFlag(true);
  //   this.TwoCoInlineCart.cart.setTest(true);
  //   this.TwoCoInlineCart.cart.setReturnMethod({
  //     type: 'redirect',
  //     url: 'https://37674145.ngrok.io/payment/success?thank-you=true'
  //   });
  //   //	x_receipt_link_url=http://www.yoursite.com/return
  //   this.TwoCoInlineCart.cart.checkout();
  // }

}
interface IPInformation {
  city: string,
  country_name: string,
  region: string,
  country_calling_code: string,
  zipcode: string
}
