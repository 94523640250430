import { Component, ElementRef, ViewChild, ViewEncapsulation, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Observable, from } from 'rxjs';
import { NgForm } from '@angular/forms';
import { Subscription } from 'rxjs';
import { QuillEditorComponent } from 'ngx-quill';
import { UserService } from '../../shared/user.service';
import { ActivatedRoute, Router, Params, NavigationExtras } from "@angular/router";
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { SidebarComponent } from 'app/shared/sidebar/sidebar.component'
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DragulaService } from 'ng2-dragula';
import { RenameSectionComponent } from '../../common_modals/rename-section/rename-section.component';
export { FormBuilder }
import * as alertFunctions from '../../shared/data/sweet-alerts';
import swal from 'sweetalert2';
import Quill from 'quill';
import { workers } from 'cluster';
import { ModalsComponent } from 'app/components/bootstrap/modals/modals.component';
// import { DragAndDropModule } from 'angular-draggable-droppable';
import { RenameModalComponent } from '../../common_modals/rename_modal/rename-modal.component';
import { TranslateService } from '@ngx-translate/core';
import { text } from '@angular/core/src/render3';
import { GlobalService } from 'app/shared/global.service';

@Component({
  selector: 'app-work-experience',
  templateUrl: './work-experience.component.html',
  styleUrls: ['./work-experience.component.scss'],
  encapsulation: ViewEncapsulation.None,
  exportAs: 'child'

})
export class WorkExperienceComponent implements OnInit, OnDestroy {
  //declarations
  WorkExperinceArray: any;
  resumeIdArray;
  sectionArray;
  workexperinceform;
  temporaryArray;
  title = 'Quill works!';
  hide = false;
  isReadOnly = false;
  form: FormGroup;
  public sub: any;
  public id: any;
  resumeId: {};
  sectionname;
  sectionicon;
  icons;
  sectionUniqueId;
  startdate: Boolean = true;
  enddate: Boolean = true;
  start_time_MM;
  start_time_YYYY;
  end_time_MM;
  end_time_YYYY;
  workexperience: Boolean = true;
  renamesection: Boolean = false;
  currentYear;
  writingTipsVisibility: boolean = false;

  showLoadingIframe: boolean = true;
  public startDate: Array<Object> = [
    { id: 0, value: "MM", name: "MM" },
    { id: 1, value: "", name: "Dont' Show" },
    { id: 2, value: "YearOnly", name: "Year Only" },
    { id: 3, value: "Jan", name: 'Jan' },
    { id: 4, value: "Feb", name: 'Feb' },
    { id: 5, value: "Mar", name: 'Mar' },
    { id: 6, value: "Apr", name: 'Apr' },
    { id: 7, value: "May", name: 'May' },
    { id: 8, value: "Jun", name: 'Jun' },
    { id: 9, value: "Jul", name: 'Jul' },
    { id: 10, value: "Aug", name: 'Aug' },
    { id: 11, value: "Sep", name: 'Sep' },
    { id: 12, value: "Oct", name: 'Oct' },
    { id: 13, value: "Nov", name: 'Nov' },
    { id: 14, value: "Dec", name: 'Dec' }
  ];
  public endDate: Array<Object> = [
    { id: 0, value: "MM", name: "MM" },
    { id: 1, value: "Present", name: "Present" },
    { id: 2, value: "", name: "Dont' Show" },
    { id: 3, value: "YearOnly", name: "Year Only" },
    { id: 4, value: "Jan", name: 'Jan' },
    { id: 5, value: "Feb", name: 'Feb' },
    { id: 6, value: "Mar", name: 'Mar' },
    { id: 7, value: "Apr", name: 'Apr' },
    { id: 8, value: "May", name: 'May' },
    { id: 9, value: "Jun", name: 'Jun' },
    { id: 10, value: "Jul", name: 'Jul' },
    { id: 11, value: "Aug", name: 'Aug' },
    { id: 12, value: "Sep", name: 'Sep' },
    { id: 13, value: "Oct", name: 'Oct' },
    { id: 14, value: "Nov", name: 'Nov' },
    { id: 15, value: "Dec", name: 'Dec' }
  ];
  public year: Array<Object> = [
    { id: 1, name: "YYYY" }
  ];
  startdateManual: boolean = false;
  enddateManual: boolean = false;

  startdatecustomfunction(data) {
    if (data == "Manual") {
      this.startdate = false;
      this.startdateManual = true;

    } else {
      this.startdate = true;
      this.startdateManual = false;
    }

  }


  startdatechangefunction(data) {
    if (data == "") {
      this.startdate = false;

    } else {
      this.startdate = true;
    }

  }

  enddatechangefunction(data) {
    if (data == "" || data == "Present") {
      this.enddate = false;

    } else {
      this.enddate = true;
    }
  }

  countryForm: FormGroup;
  message: any;
  subscription: Subscription;
  subs = new Subscription();
  MANY_ITEMS = 'WORK_EXP_MANY_ITEMS';
  constructor(public fb: FormBuilder, public userService: UserService,
    public dragulaService: DragulaService, private route: ActivatedRoute,
    public router: Router, public modalService: NgbModal,
    public activeModal: NgbActiveModal, public translate: TranslateService,
    public global: GlobalService) {
    this.form = fb.group({
      editor: ['test']


    });

    if (!dragulaService.find(this.MANY_ITEMS)) {
      dragulaService.createGroup(this.MANY_ITEMS, {
        moves: (el, container, handle) => {
          return handle.className === 'handle p-2 pointer cursorMove';
        }
      });

    }




    this.subs.add(dragulaService.dropModel(this.MANY_ITEMS)
      .subscribe(({ el, target, source, sourceModel, targetModel, item }) => {

        var data = this.resumeIdArray.sections;
        data.forEach(element => {
          if (element['name'] == this.sectionname) {
            element.subsections = sourceModel;
          }
        });
        this.userService.updateresumegenericsubject(this.id, this.resumeIdArray).subscribe((res: any) => {
        });

      })
    );
    this.subs.add(dragulaService.removeModel(this.MANY_ITEMS)
      .subscribe(({ el, source, item, sourceModel }) => {
      })
    );

    this.subscription = this.userService.getMessage().subscribe(message => {
      this.message = message;

    });

  }
  @ViewChild('editor') editor: QuillEditorComponent
  @ViewChild('myModal') myModal;
  ngOnInit() {
    this.showLoadingIframe = true;
    this.route.params.subscribe((params) => {
      this.sectionUniqueId = params['ids'];
      localStorage.setItem("sectionid", this.sectionUniqueId);
      var i, j;
      var date = new Date();

      for (i = date.getFullYear(), j = "1"; i >= "1950"; i--, j++) {
        this.year.push({ id: j, name: i })
      }
      this.id = localStorage.getItem('resumeId');


      this.form
        .controls
        .editor
        .valueChanges.pipe(
          debounceTime(400),
          distinctUntilChanged()
        )
        .subscribe(data => {
        });
      this.startDate["id"] = "2";
      // this.start_time_MM = "01 - Jan";

      this.userService.getResume(this.id).subscribe((res) => {
        this.global.emitResume(res);
        this.userService.setCurrentTab(res, "section-work");

        this.onComponentupdate(res)

      });
      /*** 
       * Get single section service
       * */
      // this.userService.getSingleSection(params.ids)
      //   .subscribe((res) => {
      //   });
    });
  }

  typeWarning() {
    var titleY;
    var textY;
    var confirmButtonText;
    var cancelButtonText;
    var DeletedY;
    var successY;
    this.translate.get("Delect Section Text").subscribe((text: string) => { titleY = text; });
    this.translate.get("You won't be able to revert this!").subscribe((text: string) => { textY = text; });
    this.translate.get("Yes, delete it!").subscribe((text: string) => { confirmButtonText = text; });
    this.translate.get("No, cancel!").subscribe((text: string) => { cancelButtonText = text; });
    this.translate.get("Deleted").subscribe((text: string) => { DeletedY = text; });
    this.translate.get("Success").subscribe((text: string) => { successY = text; });
    swal({
      title: titleY,
      text: textY,
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: confirmButtonText,
      cancelButtonText: cancelButtonText,
      reverseButtons: true
    }).then((result) => {
      if (result.value) {

        swal(
          DeletedY,
          successY,
          'success'
        )
        var data = this.resumeIdArray.sections;
        var index = data.findIndex(x => x.ids === this.sectionUniqueId);
        let selectedSection = data[index];

        data.splice(index, 1);

        this.userService.updateresumegenericsubject(this.id, this.resumeIdArray).subscribe((res: any) => {
          this.router.navigate(['/cv/', this.id, 'personal-info'], { relativeTo: this.route });

          this.global.emitSidemenuDelete(selectedSection);
        });

      } else if (

        result.dismiss === swal.DismissReason.cancel
      ) {
        //   swal(
        //     'Cancelled',
        //     'Your imaginary file is safe :)',
        //     'error'
        //   )
      }
    })
    //alertFunctions.customWarningDeleteResume();
  }

  onComponentupdate(res) {
    // this.resumeId["id"] = res['data'][0]._id;
    this.resumeIdArray = res.data ? res.data[0] : res.doc;
    let selectedSection = this.resumeIdArray.sections.find((section) => {
      return section.ids == this.sectionUniqueId;
    })

    this.global.emitUpdateSideMenu(selectedSection);
    this.onSectionupdate(selectedSection || {})
  }

  onSectionupdate(res) {
    this.sectionUniqueId = res['ids'];
    // var  workexperince  = element;
    this.sectionArray = res;
    this.WorkExperinceArray = res.subsections;

    this.sectionicon = res.icon
    this.sectionname = res.sectionname
  }


  savesection(icon, sectionname, id, sectionArray, resumeIdArray) {
    sectionArray.sectionname = this.sectionname
    this.userService.updateresumegenericsubject(this.id, this.resumeIdArray).subscribe((res: any) => {
    });
    this.modalService.dismissAll();
    this.activeModal.dismiss()
  }

  iconschangeFunction(id, data, sectionArray, resumeIdArray) {
    sectionArray.icon = data
    this.icons = sectionArray.icon;
    this.sectionicon = sectionArray.icon
  }

  openModal() {
    this.myModal.nativeElement.className = 'modal fade show';
  }



  ngOnDestroy() {

  }

  sectionrename(newValue) {
    this.sectionname = newValue
  }

  confirmCancelButton() {
    this.typeWarning();

  }


  // savesection(){
  //   this.workexperience = true;
  //   this.renamesection = false;
  // }


  onRenameSection(id, sectionicon, sectionname, sectionArray, resumeIdArray) {

  }

  editWorkexpErience() {
    // this.modalService.open(content, { size: 'lg' });
    const modalRef = this.modalService.open(RenameSectionComponent);
    modalRef.componentInstance.sectionname = this.sectionname;
    modalRef.componentInstance.sectionicon = this.sectionicon;
    modalRef.componentInstance.sectionArray = this.sectionArray;
    modalRef.componentInstance.resumeIdArray = this.resumeIdArray;
    modalRef.componentInstance.WorkExperinceArray = this.WorkExperinceArray;
    modalRef.componentInstance.id = this.id;
    modalRef.componentInstance.sub = this.sub;
    modalRef.componentInstance.workexperinceform = this.workexperinceform;
    modalRef.componentInstance.temporaryArray = this.temporaryArray;
    modalRef.componentInstance.FormGroup = this.form;
    modalRef.componentInstance.sectionUniqueId = this.sectionUniqueId;

    modalRef.componentInstance.passEntry.subscribe((receivedEntry) => {
      this.onComponentupdate(receivedEntry)
    })

  }
  //events starts
  setFocus($event) {
    $event.focus();
  }

  patchValue() {
    this.form.controls['editor'].patchValue(`${this.form.controls['editor'].value} patched!`)
  }

  toggleReadOnly() {
    this.isReadOnly = !this.isReadOnly;
  }

  logChange($event: any) {
    //your code here
  }

  logSelection($event: any) {
    //your code here
  }
  //events ends
  add = false;
  list = true;
  edit = false;

  onAdd(id: string) {

    let scrollToTop = window.setInterval(() => {
      let pos = window.pageYOffset;
      if (pos > 0) {
        window.scrollTo(0, pos - 20); // how far to scroll on each step
      } else {
        window.clearInterval(scrollToTop);
      }
      this.workexperinceform = "";
      if (this.workexperinceform == "") {
        this.workexperinceform = {
          end_time_MM: "Select Month",
          end_time_YYYY: "Select Year",
          start_time_MM: "Select Month",
          start_time_YYYY: "Select Year",
          about: ""
        }
      }

      this.edit = true;
      this.list = false;
    }, 16);


  }

  onSubmit(form: NgForm) {
    var subsections = {
      ids: Math.floor(10000000000 + Math.random() * 90000000000),
      company_url: "",
      Country: form.value.Country,
      about: form.value.about,
      end_time_MM: form.value.end_time_MM,
      end_time_YYYY: form.value.end_time_YYYY,
      end_time_present: false,
      name: form.value.name,
      opened: false,
      speciality: form.value.speciality,
      start_time_MM: form.value.start_time_MM,
      start_time_YYYY: form.value.start_time_YYYY,
    }

    this.WorkExperinceArray.push(subsections)

    this.userService.updateresumegeneric(this.id, this.resumeIdArray).subscribe((res: any) => {

      form.reset()
      this.add = false;
      this.list = true;

    });




  }

  onDelete(id: String) {
    this.confirmDelete(id);
  }

  confirmDelete(id: any) {
    var titleY;
    var textY;
    var confirmButtonText;
    var cancelButtonText;
    var DeletedY;
    var successY;
    this.translate.get("Delect Item Text").subscribe((text: string) => { titleY = text; });
    this.translate.get("You won't be able to revert this!").subscribe((text: string) => { textY = text; });
    this.translate.get("Yes, delete it!").subscribe((text: string) => { confirmButtonText = text; });
    this.translate.get("No, cancel!").subscribe((text: string) => { cancelButtonText = text; });
    this.translate.get("Deleted").subscribe((text: string) => { DeletedY = text; });
    this.translate.get("Success").subscribe((text: string) => { successY = text; });
    swal({
      title: titleY,
      text: textY,
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: confirmButtonText,
      cancelButtonText: cancelButtonText,
      reverseButtons: true
    }).then((result) => {
      if (result.value) {

        swal(
          DeletedY,
          successY,
          'success'
        )
        var index = this.WorkExperinceArray.findIndex(x => x.ids === id);
        this.WorkExperinceArray.splice(index, 1);
        this.userService.updateresumegeneric(this.id, this.resumeIdArray).subscribe((res: any) => {

          this.add = false;
          this.list = true;

        });
      } else if (

        result.dismiss === swal.DismissReason.cancel
      ) {
      }
    })
  }
  onClone(WorkExperinceArray: any) {
    var temporaryArray = {
      ids: Math.floor(10000000000 + Math.random() * 90000000000),
      company_url: WorkExperinceArray.company_url,
      Country: WorkExperinceArray.Country,
      about: WorkExperinceArray.about,
      end_time_MM: WorkExperinceArray.end_time_MM,
      end_time_YYYY: WorkExperinceArray.end_time_YYYY,
      end_time_present: WorkExperinceArray.end_time_present,
      name: WorkExperinceArray.name,
      opened: WorkExperinceArray.opened,
      speciality: WorkExperinceArray.speciality,
      start_time_MM: WorkExperinceArray.start_time_MM,
      start_time_YYYY: WorkExperinceArray.start_time_YYYY
    }
    this.WorkExperinceArray.push(temporaryArray)

    this.userService.updateresumegeneric(this.id, this.resumeIdArray).subscribe((res: any) => {

      this.add = false;
      this.list = true;
      // location.reload();
    });



  }

  onEdit(WorkExperinceArray: any) {
    if (WorkExperinceArray.start_time_MM == "") {
      this.startdate = false;
    }
    if (WorkExperinceArray.end_time_MM == "" || WorkExperinceArray.end_time_MM == "Present") {
      this.enddate = false;
    }
    this.startdateManual = WorkExperinceArray.start_date_manual;
    this.enddateManual = WorkExperinceArray.end_date_manual;
    this.workexperinceform = {
      ids: WorkExperinceArray.ids,
      company_url: WorkExperinceArray.company_url,
      Country: WorkExperinceArray.Country,
      about: WorkExperinceArray.about,
      end_time_MM: WorkExperinceArray.end_time_MM,
      end_time_YYYY: WorkExperinceArray.end_time_YYYY,
      end_time_present: WorkExperinceArray.end_time_present,
      name: WorkExperinceArray.name,
      opened: WorkExperinceArray.opened,
      speciality: WorkExperinceArray.speciality,
      start_time_MM: WorkExperinceArray.start_time_MM,
      start_time_YYYY: WorkExperinceArray.start_time_YYYY

    }
    this.add = false;
    this.list = false;
    this.edit = true;

    let scrollToTop = window.setInterval(() => {
      let pos = window.pageYOffset;
      if (pos > 0) {
        window.scrollTo(0, pos - 20); // how far to scroll on each step
      } else {
        window.clearInterval(scrollToTop);
      }
    }, 16);
  }

  onEditSubmit(form: NgForm) {
    this.showLoadingIframe = false;
    var subsections;

    if (!form.value.ids) {
      subsections = {
        ids: Math.floor(10000000000 + Math.random() * 90000000000),
        company_url: "",
        Country: form.value.Country,
        about: form.value.about,
        end_time_MM: form.value.end_time_MM,
        end_time_YYYY: form.value.end_time_YYYY,
        end_time_present: false,
        name: form.value.name,
        opened: false,
        speciality: form.value.speciality,
        start_time_MM: form.value.start_time_MM,
        start_time_YYYY: form.value.start_time_YYYY,
        start_date_manual: this.startdateManual,
        end_date_manual: this.enddateManual
      }

      this.WorkExperinceArray.push(subsections)
    } else {
      subsections = {
        ids: form.value.ids,
        company_url: "",
        Country: form.value.Country,
        about: form.value.about,
        end_time_MM: form.value.end_time_MM,
        end_time_YYYY: form.value.end_time_YYYY,
        end_time_present: false,
        name: form.value.name,
        opened: false,
        speciality: form.value.speciality,
        start_time_MM: form.value.start_time_MM,
        start_time_YYYY: form.value.start_time_YYYY,
        start_date_manual: this.startdateManual,
        end_date_manual: this.enddateManual
      }

      this.WorkExperinceArray.forEach(element => {
        var index = this.WorkExperinceArray.findIndex(x => x.ids === form.value.ids);
        this.WorkExperinceArray.splice(index, 1);
        this.WorkExperinceArray.splice(index, 0, subsections);
      });

    }


    let index = this.resumeIdArray.sections.findIndex((section) => {
      return section.ids == this.sectionUniqueId;
    })
    this.resumeIdArray.sections[index].subsections = this.WorkExperinceArray;

    this.userService.updateresumegeneric(this.id, this.resumeIdArray).subscribe((res: any) => {
      form.reset()
      this.edit = false;
      this.list = true;
      window.scrollTo(0, 0)
      this.showLoadingIframe = true;
    });
  }

  onCancel() {
    this.list = true;
    this.edit = false;
    this.add = false;
  }

  config = {
    toolbar: [
      ['bold', 'italic', 'underline', { 'list': 'ordered' }, { 'list': 'bullet' }, { 'align': [] }, 'clean', 'link']
    ]
  }
  showWritingTips() {
    this.writingTipsVisibility = !this.writingTipsVisibility;
  }
}