import { Component, OnInit, Pipe, PipeTransform, Renderer2, Input } from '@angular/core';
import { UserService } from '../../shared/user.service';
import { GlobalService } from '../../shared/global.service';
import { DomSanitizer } from "@angular/platform-browser";
import { TranslateService } from '@ngx-translate/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router, NavigationExtras, ActivatedRoute } from "@angular/router";
import { NgbdModalTemplateLists, NgbdModalWebsiteTemplateLists } from '../../shared/customizer/customizer.component';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-edit-resume',
  templateUrl: './edit-resume.component.html',
  styleUrls: ['./edit-resume.component.scss']
})


export class EditResumeComponent implements OnInit {
  framePath;
  userSlug;
  resume_name;
  resumeSlug;
  resumeId;
  userName;
  resumeLanguage;
  id;
  resumeMeta;
  checkAutoSaving;
  isPro;
  userDetails = { role: '', payment: { month: '' } };
  options = {
    direction: 'ltr'
  };
  textDir; // direction according to language
  showResumeBuilding = false;
  showResumeThankYou = false;
  showLoadingIframe;
  userId;
  showCustomizer = true;
  selected_resume;
  resumeType = "";
  domainName = "";
  constructor(
    public userService: UserService,
    private modalService: NgbModal,
    public globalService: GlobalService,
    private sanitizer: DomSanitizer,
    public router: Router,
    private renderer: Renderer2,
    public translate: TranslateService,
    public route: ActivatedRoute) {

    this.resumeId = localStorage.getItem('resumeId');
    this.userName = localStorage.getItem('userName');

    this.userSlug = this.userName;
    this.resumeSlug = this.resumeId;

    setTimeout(() => {
      this.showLoadingIframe = true;
    }, 10)
    this.userId = localStorage.getItem('userId');
    this.framePath = "/api/renderTemplate/" + this.userId + "/" + this.resumeSlug + '?t=' + new Date().getTime();
    //this.framePath = "/me/ssss";


    this.globalService.changeTemplateWatch.subscribe(value => {
      setTimeout(() => {
        this.showLoadingIframe = true;
      }, 10)
      this.framePath = "/api/renderTemplate/" + this.userId + "/" + this.resumeSlug + '?t=' + new Date().getTime();
      // console.log(value)
      this.resumeMeta.ResumeLanguage = value['doc'].meta.ResumeLanguage;
      this.resumeMeta.isPro = value['doc'].meta.defaultTemplate.isPro;
      this.selected_resume = value['doc'].meta.defaultTemplate.name;
      // console.log("selected", this.selected_resume);
      //this.ngOnInit();
    })
    this.globalService.userDetailWatch.subscribe(value => {
      this.userDetails.role = value['meta']['role'];
      // can u put here if user 
      if (value['meta']['role'] === "OPS")
        this.userDetails.payment.month = value['payment']['months'];
    });
    this.globalService.resumeDetailWatch.subscribe(value => {

      this.resumeType = value['data'][0]['meta']['type'];
      if (value['data'][0].meta.ResumeLanguage === "" || value['data'][0].meta.ResumeLanguage === undefined) {
        const modalRef = this.modalService.open(NgbdModalResumeMissing);
        modalRef.componentInstance.resumeId = this.resumeId;
        modalRef.componentInstance.resumeData = value['data'][0];
      } else {
        this.resumeMeta.ResumeLanguage = value['data'][0].meta.ResumeLanguage;
        this.resumeMeta.isPro = value['data'][0].meta.defaultTemplate.isPro;
        this.resume_name = value['data'][0].meta.name;
        this.selected_resume = value['data'][0].meta.defaultTemplate.name;

      }
      if (this.resumeType == 'WebSite') {
        this.domainName = value['data'][0]['meta']['domainName'];
      }
    })
  }

  public getSantizeUrl(url: string) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
  async showAutoSaving() {

    await
      setInterval(() => {
        this.checkAutoSaving = false;
      }, 6000)

    await setInterval(() => {
      this.checkAutoSaving = true;
    }, 4000)
  }
  ngOnInit() {
    this.checkAutoSaving = true;
    this.showAutoSaving();
    this.showCustomizer = true;
    if (localStorage.getItem('locale') === "ar") {
      this.textDir = "rtl";
      document.getElementsByTagName("body")[0].classList.add("langAR");

    } else {
      this.textDir = "ltr"
    }
    this.translate.use(localStorage.getItem('locale'));

    $.getScript('./assets/js/app-sidebar.js');
    // $.getScript('./assets/js/iframe-resize.js');

    this.id = localStorage.getItem('resumeId');
    this.resumeMeta = { ResumeLanguage: "" };
    this.resumeMeta = { isPro: "" };
    // this.userService.getResume(this.id).subscribe((res) => {
    //   this.resumeMeta.ResumeLanguage = res['data'][0].meta.ResumeLanguage;
    //   this.resumeMeta.isPro = res['data'][0].meta.defaultTemplate.isPro;
    //   this.resume_name = res['data'][0].meta.name;
    // });
    this.showLoadingIframe = false;

  }

  ngOnDestroy() {
    this.showCustomizer = false;
  }
  dismissLoading() {
    this.showLoadingIframe = false;
  }
  setDirection(language: any) {
    if (language == 'ar') {
      this.textDir = 'rtl';
    } else {
      this.textDir = 'ltr';
    }
    //this.translate.use(language);
  }
  download() {
    if (this.userDetails.role === 'USER' && this.resumeMeta.isPro === true) {
      this.router.navigate(['/pricing']);
    } else {
      this.userName = localStorage.getItem('userName');
      let urlSearchParams = {
        resumeId: this.resumeId,
        pageSize: 'A4',
        userName: this.userName,
        userId: this.userId

      }
      this.showResumeBuilding = true;

      this.userService.downloadResume(urlSearchParams).subscribe(data => {
        this.showResumeBuilding = false;
        this.showResumeThankYou = true;

        if (data) {
          let body = data;

          let contentType = 'application/pdf';
          let filename = 'MyResume.pdf';
          var linkElement = document.createElement('a');

          try {
            var blob = new Blob([data], { type: contentType });
            var url = window.URL.createObjectURL(blob);
            linkElement.setAttribute('href', url);
            linkElement.setAttribute("download", filename);
            var clickEvent = new MouseEvent("click", {
              "view": window,
              "bubbles": true,
              "cancelable": false
            });
            linkElement.dispatchEvent(clickEvent);

          } catch (ex) {
            console.log(ex);
          }
        }
      },
        error => {
          console.log(JSON.stringify(error.json()));
        }
      )
    }
  }
  closeThankYou() {
    this.showResumeThankYou = false;
  }

  publishFull() {

    if (this.userDetails.role === 'USER') {
      this.router.navigate(['/pricing']);
    }
    else if (this.userDetails.role === 'OPS' && Number(this.userDetails.payment['month']) === 3) {
      this.router.navigate(['/pricing']);
    }
    else if (this.userDetails.role === 'OPS' && Number(this.userDetails.payment['month']) === 1) {
      this.router.navigate(['/pricing']);
    }
    else if (this.userDetails.role === 'OPS' && Number(this.userDetails.payment['month']) === 12) {
      // console.log(`/me/${this.domainName}`);
      this.router.navigate(['../../me/' + this.domainName]);
    }


  }


  downloadFull() {
    if (this.userDetails.role === 'USER' && this.resumeMeta.isPro === true) {
      this.router.navigate(['/pricing']);
    }

    else {
      this.userName = localStorage.getItem('userName');
      let urlSearchParams = {
        resumeId: this.resumeId,
        pageSize: 'Full',
        userName: this.userName,
        userId: this.userId
      }
      this.showResumeBuilding = true;
      this.userService.downloadResume(urlSearchParams).subscribe(data => {
        this.showResumeBuilding = false;
        this.showResumeThankYou = true;
        if (data) {
          let body = data;
          let contentType = 'application/pdf';
          let filename = 'MyResume.pdf';
          var linkElement = document.createElement('a');

          try {
            var blob = new Blob([data], { type: contentType });
            var url = window.URL.createObjectURL(blob);
            linkElement.setAttribute('href', url);
            linkElement.setAttribute("download", filename);
            var clickEvent = new MouseEvent("click", {
              "view": window,
              "bubbles": true,
              "cancelable": false
            });
            linkElement.dispatchEvent(clickEvent);

          } catch (ex) {
            console.log(ex);
          }
        }
      },
        error => {
          console.log(JSON.stringify(error.json()));
        }
      )
    }
  }
  downloadLetter() {
    if (this.userDetails.role === 'USER' && this.resumeMeta.isPro === true) {
      this.router.navigate(['/pricing']);
    }

    else {
      this.userName = localStorage.getItem('userName');
      let urlSearchParams = {
        resumeId: this.resumeId,
        pageSize: 'Letter',
        userName: this.userName,
        userId: this.userId
      }
      this.showResumeBuilding = true;
      this.userService.downloadResume(urlSearchParams).subscribe(data => {
        this.showResumeBuilding = false;
        this.showResumeThankYou = true;
        if (data) {
          let body = data;
          let contentType = 'application/pdf';
          let filename = 'MyResume.pdf';
          var linkElement = document.createElement('a');

          try {
            var blob = new Blob([data], { type: contentType });
            var url = window.URL.createObjectURL(blob);
            linkElement.setAttribute('href', url);
            linkElement.setAttribute("download", filename);
            var clickEvent = new MouseEvent("click", {
              "view": window,
              "bubbles": true,
              "cancelable": false
            });
            linkElement.dispatchEvent(clickEvent);

          } catch (ex) {
            console.log(ex);
          }
        }
      },
        error => {
          console.log(JSON.stringify(error.json()));
        }
      )
    }
  }
  backToEditor() {
    let resume_id = localStorage.getItem("resumeId");
    this.router.navigate(['/cv/', resume_id, 'personal-info'], { relativeTo: this.route });

  }
  changeResumeTemplate() {

    if (this.resumeType != 'WebSite') {
      const modalRef = this.modalService.open(NgbdModalTemplateLists, { size: 'lg', windowClass: 'modalTemplate' });
      this.userService.getTemplates().subscribe((res) => {
        modalRef.componentInstance.templates = res;
        modalRef.componentInstance.resumeId = localStorage.getItem('resumeId');
        modalRef.componentInstance.templateSelected = this.selected_resume;
        modalRef.componentInstance.resumeLanguage = this.resumeMeta.ResumeLanguage;
        modalRef.componentInstance.changeTemplateEvent.subscribe((template) => {
          this.resumeMeta.isPro = template.isPro;
        })

      });
    } else {
      const modalRef = this.modalService.open(NgbdModalWebsiteTemplateLists, { size: 'lg', windowClass: 'modalTemplate' });
      this.userService.getWebsiteTemplates().subscribe((res) => {
        modalRef.componentInstance.templates = res;
        modalRef.componentInstance.resumeId = localStorage.getItem('resumeId');
        modalRef.componentInstance.templateSelected = this.selected_resume;
        modalRef.componentInstance.resumeLanguage = this.resumeMeta.ResumeLanguage;
        modalRef.componentInstance.changeTemplateEvent.subscribe((template) => {
          this.resumeMeta.isPro = template.isPro;
        })

      });
    }
  }


  languageChangedHandler(language: any) {
    this.renderer.removeClass(document.body, 'langAR');
    if (language == 'ar') {
      this.textDir = 'rtl';
      this.renderer.addClass(document.body, 'langAR');
    } else {
      this.textDir = 'ltr';
    }
    //this.translate.use(language);
  }

  showResumeUrlModal() {
    var resumeUrl = environment.domain + environment.apiBaseUrl + "/me/" + this.userSlug + "/" + this.resume_name;
    const modalRef = this.modalService.open(NgbdModalResumeUrl);
    modalRef.componentInstance.data = resumeUrl;
    modalRef.componentInstance.userDetails = this.userDetails;
  }
}

//   Mission Meta // 

@Component({
  selector: 'app-resume-missing',
  template: `
   
<div class="modal-header">
<h4 class="modal-title">{{'Something Missing' | translate}}</h4>
<button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')"> <i class="ft-x font-medium-3"></i> </button>
</div>

<div class="modal-body"  >

<label for="basic-url">{{'Resume Language' | translate}}</label>

<select class="custom-select" id="inputGroupSelect01" [(ngModel)]="resumeLang">
<option selected>Choose...</option>
<option value="Arabic">Arabic</option>
<option value="English">English</option>
<option value="French">French</option>
<option value="Germany">Germany</option>
<option value="Spanish">Spanish</option>
<option value="Portuguese">Portuguese</option>
</select>
<div class="modal-footer text-center" > <button  class="btn  btn-sm btn-round btn-success mb-0 mt-1 px-3 btn-raised mr-2 tasdkU" (click)="saveResumeLang()"> {{'Save' | translate}} <i class="fa fa-rocket white ml-1"></i> </button> </div>
</div>
 
 
  `
})


export class NgbdModalResumeMissing {
  constructor(public activeModal: NgbActiveModal, private userService: UserService, private router: Router, private global: GlobalService) { }

  resumeLang;

  @Input() resumeId;
  @Input() resumeData;

  saveResumeLang() {
    this.resumeData.meta.ResumeLanguage = this.resumeLang;
    console.log(this.resumeData);
    this.userService.updateresumegeneric(this.resumeId, this.resumeData).subscribe((res: any) => {
      this.global.emitTemplateChange(res);
      this.activeModal.close();
    });
  }

}





//   Rename Resume // 

@Component({
  selector: 'app-resume-url',
  template: `
   
<div class="modal-header">
<h4 class="modal-title">{{'My Personal Domain' | translate}}</h4>
<button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')"> <i class="ft-x font-medium-3"></i> </button>
</div>
 
<div *ngIf="userDetails.payment.month !== 1 && userDetails.role==='OPS'">
<div class="modal-body"  >
 <p>
 {{'shareThisLink' | translate}}
 </p>
  <div class="input-group">
    <input type="text" class="form-control" placeholder="Button on right" aria-describedby="button-addon4" readonly value="{{data}}" style="font-size:11px">
     
  </div>
 
 
  <p class="font-small-2">You can change your URL from <a href="/profile">My Account</a> page</p>
</div>
 
<div class="modal-footer text-center">
  <div class="d-flex justify-content-between w-100 align-items-center">
        <div>
        <a target="_blank" class="font-small-3" href="https://www.facebook.com/sharer/sharer.php?u={{data}}//"  class="btn btn-social-icon mr-2 mb-2 btn-outline-facebook"> 
        <span class="fa fa-facebook"></span>
       </a> 
      <a href="http://twitter.com/share?url={{data}}//" target="_blank"   class="btn btn-social-icon mr-2 mb-2 btn-outline-twitter"> 
      <span class="fa fa-twitter"></span>
      </a>
        </div>
        <div>
        <a href="{{data}}" target="_blank" class="btn btn-white info mr-2" >{{'openURL' | translate}}</a>
        <button (click)="copyUrl(data)" class="btn btn-raised btn-primary" type="button">{{'copyURL' | translate}}</button>
    
        </div>
       
   </div>
</div>
</div>

<div class="modal-body" *ngIf="userDetails.role==='USER'"  >

<p class="text-center">
{{'This Option is for Premium User' | translate}}
<br/>

<p>

</div>
<div class="modal-footer text-center" *ngIf="userDetails.role==='USER'" >
<a routerLink="/pricing" class="btn  btn-sm btn-round btn-success mb-0 mt-1 px-3 btn-raised mr-2 tasdkU">
{{'Upgrade' | translate}}
<i class="fa fa-rocket white ml-1"></i>
</a>

</div>


 
  `
})

export class NgbdModalResumeUrl {
  @Input() meta;
  @Input() data;
  @Input() userDetails;

  onSubmitRename(data) {

    this.userService.updateresumegeneric(data._id, data).subscribe((res) => {

      this.activeModal.close();
      // this.resetForm(form);
    });
    // console.log("hello")
  }

  copyUrl(str) {

    // Create new element
    var el = document.createElement('textarea');
    // Set value (string to be copied)
    el.value = str;
    // Set non-editable to avoid focus and move outside of view
    el.setAttribute('readonly', '');
    // el.style = { position: 'absolute', left: '-9999px' };
    document.body.appendChild(el);
    // Select text inside element
    el.select();
    // Copy text to clipboard
    document.execCommand('copy');
    // Remove temporary element
    document.body.removeChild(el);
  }
  constructor(public activeModal: NgbActiveModal, private userService: UserService, private router: Router) { }


}
