import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GlobalService {

  constructor() { }

  userRole: '';
  public changeTemplateWatch = new Subject<JSON>();
  public changeOnlyTemplateWatch = new Subject<JSON>();

  public userDetailWatch = new Subject<JSON>();
  public resumeDetailWatch = new Subject<JSON>();

  public personalInfoWatch = new Subject<JSON>();
  public languageChangeWatch = new Subject<string>();
  public visibleNextWatch = new Subject<Boolean>();
  public sideMenuWatch = new Subject<JSON>();
  public sideMenuUpdateWatch = new Subject<JSON>();
  public sideMenuDeleteWatch = new Subject<JSON>();
  public changeProfileLogoWatch = new Subject<String>();

  public emitProfileLogo(val) {
    this.changeProfileLogoWatch.next(val);
  }
  public emitTemplateChange(val) {
    this.changeTemplateWatch.next(val);
  }
  public emitOnlyTemplateChange(val) {
    this.changeOnlyTemplateWatch.next(val);
  }

  public emitProfile(val) {
    this.userDetailWatch.next(val);
  }

  public emitResume(val) {
    this.resumeDetailWatch.next(val);
  }

  public emitPersonalInfo(val) {
    this.personalInfoWatch.next(val);
  }

  public emitSideMenu(val) {
    this.sideMenuWatch.next(val);
  }

  public emitUpdateSideMenu(val) {
    this.sideMenuUpdateWatch.next(val);
  }
  public emitSidemenuDelete(val) {
    this.sideMenuDeleteWatch.next(val);
  }
  public emitLanuage(val) {
    this.languageChangeWatch.next(val);
  }
  public setVisibleNext() {
    this.visibleNextWatch.next(false);
  }
}
