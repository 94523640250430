import { Injectable, Output, EventEmitter } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { User } from './user.model';
import { Resumes } from './resume.model';
import { Download } from './download.model';
import { map } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';
import 'rxjs/add/observable/fromPromise';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/mergeMap';
import { $ } from 'protractor';
import { PARAMETERS } from '@angular/core/src/util/decorators';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { subSeconds } from 'date-fns';


@Injectable({
  providedIn: 'root'
})
export class UserService {
  private abc = new Subject<any>();
  private subject = new Subject<any>();
  apiURL: string = '/api/order';


  private modal_data = new Subject<any>();


  private messageSource = new BehaviorSubject('default message');
  currentMessage = this.messageSource.asObservable();

  // @Output()
  // userProfileImageChangedEvent: EventEmitter<string> = new EventEmitter<string>();

  public userProfileImageChangedEvent = new Subject<string>();

  userProfileImageChanged(imageUrl) {

    this.userProfileImageChangedEvent.next(imageUrl);
  }

  selectedUser: User = {
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    preferred_language: ''
  };

  selectedDownload: Download = {
    resumeId: '',
    pageSize: '',
    userId: ''
  }

  public hero: any

  selectedResume: Resumes;
  resume: Resumes[];
  data: {};

  noAuthHeader = { headers: new HttpHeaders({ 'NoAuth': 'True' }) };

  httpHeader = { headers: new HttpHeaders({ "Content-Type": "application/json" }) };
  constructor(private http: HttpClient) { }

  userID;
  //HttpMethods
  payment(cardinfo) {
    return this.http.post(environment.apiBaseUrl + '/order', cardinfo);
  }
  paypalpayment(paypalcardInfo) {
    return this.http.post(environment.apiBaseUrl + '/paypalorder', paypalcardInfo);
  }
  createAgreement(paypalcardInfo) {
    return this.http.post(environment.apiBaseUrl + '/createAgreement', paypalcardInfo);
  }
  stripecheckout(userName) {

    return this.http.post(environment.apiBaseUrl + '/stripecheckout', userName);
  }

  fastspringCheckout(cardInfo) {
    return this.http.post(environment.apiBaseUrl + '/fastspringCheckout', cardInfo);
  }
  getFastspringSubscriptions(data) {
    return this.http.post(environment.apiBaseUrl + '/getFastspringSubscriptions', data);
  }
  getActiveSubscription(username): Observable<any> {
    return this.http.get<any>(environment.apiBaseUrl + '/getsubscription' + `/${username}`);
  }
  postUser(user: User, language: string) {
    return this.http.post(environment.apiBaseUrl + `/register/${language}`, user, this.noAuthHeader);
  }

  login(authCredentials) {
    return this.http.post(environment.apiBaseUrl + '/authenticate', authCredentials, this.noAuthHeader);

  }
  validateEmail(email) {
    return this.http.post(environment.apiBaseUrl + '/validateEmail', email);
  }
  getUserProfile() {
    return this.http.get(environment.apiBaseUrl + '/userProfile');
  }
  searchUsersByEmail(email) {
    return this.http.post(environment.apiBaseUrl + '/searchUsersByEmail', { email: email });
  }
  getResumeList() {
    if (this.userID === undefined) {
      this.getUserPayload();
    }
    return this.http.get(environment.apiBaseUrl + '/resumeList' + `/${this.userID}`);
  }

  renderTemplate(userId: string, resumeSlug: string) {
    return this.http.get(environment.apiBaseUrl + '/renderTemplate' + `/${userId}` + `/${resumeSlug}`);
  }



  resumeOps(userSlug: string, resumeSlug: string) {

    return this.http.get(environment.baseUrl + '/me' + `/${resumeSlug}`);
  }
  getTemplates() {
    return this.http.get(environment.apiBaseUrl + '/getTemplates');
  }
  getWebsiteTemplates() {
    return this.http.get(environment.apiBaseUrl + '/getWebsiteTemplates');
  }
  getResume(id: string) {
    return this.http.get(environment.apiBaseUrl + '/getResume' + `/${id}`);
  }

  forgotPassword(user: User) {
    return this.http.post(environment.apiBaseUrl + '/forgotPassword', user, this.noAuthHeader);
  }


  resetPassword(user: User) {
    return this.http.post(environment.apiBaseUrl + '/resetPassword', user, this.noAuthHeader);
  }

  deleteResume(_id: string) {
    return this.http.delete(environment.apiBaseUrl + '/resumeDelete' + `/${_id}`);
  }

  resumeSave(resume) {
    return this.http.post(environment.apiBaseUrl + '/resumesave', resume);
  }

  checkWebsiteUnique(website_name) {
    return this.http.post(environment.apiBaseUrl + '/checkWebsiteUnique', { website: website_name });
  }

  updateresume(id: string, resume) {
    return this.http.put(environment.apiBaseUrl + '/updateResume' + `/${id}`, resume);
  }

  updateResumeMetaEmail(id: string, oldEmail: string, email: string) {
    return this.http.post(environment.apiBaseUrl + '/updateResumeMetaEmail', { userid: id, oldEmail: oldEmail, email: email });
  }
  download(downloadData: Download) {
    return this.http.post(environment.apiBaseUrl + '/download', downloadData, this.noAuthHeader);
  }

  updateresumegeneric(id: string, resume) {
    return this.http.put(environment.apiBaseUrl + '/updateResumeGeneric' + `/${id}`, resume);
  }
  updateresumetemplate(id: string, template) {
    return this.http.put(environment.apiBaseUrl + '/updateResumeTemplate' + `/${id}`, template);
  }
  saveBase64File(subsection: any) {
    return this.http.post(environment.apiBaseUrl + '/upload-base64-file', subsection);
  }
  saveBase64PersonalLogo(data: any) {
    // console.log(data);
    return this.http.post(environment.apiBaseUrl + '/upload-base64-personal-logo', data);
  }
  updatePersonalLogo(data: any) {
    return this.http.post(environment.apiBaseUrl + '/updatePersonalLogo', data);
  }
  updateresumegenericsubject(id: string, resume) {
    this.subject.next(resume);
    return this.http.put(`${environment.apiBaseUrl}/updateResumeGeneric/${id}`, resume);
  }
  downloadResume(urlSearchParams) {
    return this.http.post(environment.apiBaseUrl + '/download', urlSearchParams, { responseType: 'arraybuffer' })
  }
  postChangePassword(user: any) {
    return this.http.post(environment.apiBaseUrl + '/changePassword', user);
  }

  deleteAccount(data: any) {
    return this.http.post(environment.apiBaseUrl + '/deleteAccount', data);
  }
  updatePasswordByEmail(email, password) {
    return this.http.post(environment.apiBaseUrl + '/updatePasswordByEmail', { email: email, password: password });
  }
  updateUserProfile(data: any) {
    return this.http.post(environment.apiBaseUrl + '/updateUserProfile', data);
  }
  updateUserDetail(data: any) {
    return this.http.post(environment.apiBaseUrl + '/updateUserDetail', data);
  }
  updateUserPermalink(data: any) {
    return this.http.post(environment.apiBaseUrl + '/updateUserPermalink', data); 
  }
  getSales(userId, email) {
    return this.http.get(environment.apiBaseUrl + `/users/${userId}/sales?customer_email=${email}`);
  }
  getStripeSubscriptions(userId) {
    return this.http.get(environment.apiBaseUrl + `/users/${userId}/stripe_subscriptions`);
  }
  getStripeSubscriptionDetail(id) {
    return this.http.get(environment.apiBaseUrl + `/users/${id}/stripe_subscriptions_detail`);
  }
  stopSubscription(lineitemId) {
    return this.http.delete(environment.apiBaseUrl + `/invoices/${lineitemId}`);
  }
  stopFastspringSubscription(lineitemId) {
    return this.http.delete(environment.apiBaseUrl + `/invoices/fastspring/${lineitemId}`);
  }
  stopStripeSubscription(lineitemId) {
    return this.http.delete(environment.apiBaseUrl + `/invoices/stripe/${lineitemId}`);
  }
  getInvoices(saleId) {
    return this.http.get(environment.apiBaseUrl + `/sales/${saleId}/invoices`);
  }
  getStripeTemplate(items) {
    return this.http.post(environment.apiBaseUrl + '/stripe/create-payment-intent', JSON.stringify({ items }), this.httpHeader);
  }


  // modaldata(id  : string,sectionicon : string , sectionname : string , sectionArray,resumeIdArray,iconsArray){
  modaldata(message) {
    this.modal_data.next(message)

  }

  changeMessage(message: string) {
    this.messageSource.next(message)
  }






  sendMessage(message: string) {
    this.abc.next({ text: message });
  }

  getabcMessage(): Observable<any> {
    return this.abc.asObservable();
  }

  getMessage(): Observable<any> {
    return this.subject.asObservable();
  }

  getModaldata(): Observable<any> {
    return this.modal_data.asObservable();
  }



  savePreferredLanguage(user: User) {
    return this.http.post(environment.apiBaseUrl + '/savePreferredLanguage', user);

  }
  linkedinlogin() {
    // console.log("linkedinlogin service called")
    // let url = 'http://localhost:3000/api/auth/linkedin';
    // let url = 'http://localhost:3000/api/user';
    var callback = console.log;
    let url = '/api/auth/linkedin';
    // window.callback = () => console.log('');
    // let headers = new HttpHeaders({'Access-Control-Allow-Origin':'*'});
    // return this.http.get('http://127.0.0.1:3000/api/auth/linkedin',headers);
    // return this.http.get(url,this.noAuthHeader);
    return this.http.jsonp(url, 'callback');
  }
  callback = console.log;


  //Helper Methods

  setToken(token: string) {
    localStorage.setItem('token', token);
  }

  getToken() {
    return localStorage.getItem('token');
  }

  deleteToken() {
    localStorage.removeItem('token');
  }

  getUserPayload() {

    var token = this.getToken();
    if (token && token != 'undefined') {
      var userPayload = atob(token.split('.')[1]);
      this.userID = (JSON.parse(userPayload)._id);
      return JSON.parse(userPayload);
    }
    else
      return null;
  }


  isLoggedIn() {
    var userPayload = this.getUserPayload();
    // console.log("userpayload", userPayload.exp > Date.now() / 1000);
    if (userPayload)
      return userPayload.exp > Date.now() / 1000;
    else
      return false;
  }

  getSingleSection(sectionId) {
    return this.http.get(environment.apiBaseUrl + '/sections' + `/${sectionId}`);
  }

  getApiUrl() {
    return environment.apiBaseUrl;
  }

  public sectionChangeWatch = new Subject<Boolean>();
  public visibleNextWatch = new Subject<Boolean>();
  setCurrentTab(res: any, type: any) {
    var data = res['data'][0].sections;
    data.forEach((element, key) => {
      if ((element.type == type) && (element.ids == localStorage.getItem("sectionid"))) {
        localStorage.setItem("currentTab", key);
        //this.sectionChangeWatch.next(true);
      }
    });

  }
  setVisibleNext() {
    this.visibleNextWatch.next(false);
  }
  removePersonalLogo(data) {
    return this.http.post(environment.apiBaseUrl + '/removePersonalLogo', data);
  }
  getIPInformation(ip) {
    //return this.http.get(`https://ipapi.co/${ip}/json/`);
    // return this.http.get(`https://ipapi.co/json/`);
    return this.http.get(`https://ipapi.co/json/?key=TIqP1t56aAnpS9BS0mkv0RLB0gSs3mhB9dUDL5Yo2NzaClNwbS`);
  }

  uploadFileToAws(formData) {
    return this.http.post(environment.apiBaseUrl + '/uploadFileToAws', formData);
  }
  removeFileAws(data) {
    return this.http.post(environment.apiBaseUrl + '/removeFileAws', data);
  }
}
