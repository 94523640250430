import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { GlobalService } from '../../app/shared/global.service';
import { UserService } from '../../app/shared/user.service';
import { NgForm } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router, ActivatedRoute, NavigationExtras } from "@angular/router";
import * as moment from 'moment';
@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss']
})
export class UserProfileComponent implements OnInit {

  //Variable Declaration
  currentPage: string = "account";
  userdetails = { firstName: '', lastName: '', email: '', role: '', jobTitle: '', contactNumber: '', country: '', city: '', permalinkSlug: '', expiryDate: '', id: '', preiumMonths: '', preiumCounry: '', preiumCity: '', paymentTime: '', invoiceId: '' };

  user = { id: '', email: '', oldPass: '', newPass: '', repeatPass: '' };
  errorMessages: any;
  successMessages: any;

  permalinkErrorMessage: any;
  permalinkSuccessMessage: any;
  sales: any = null;
  invoices: any = [];
  saleIdForDetail: string = '';
  saleFastspringDetail: any;
  saleStripeDetail: any;
  subscription_duration = null;
  subscription = null;
  userName: any;
  subscriptionmessage = "";
  stripeCardInfo: any;
  constructor(public global: GlobalService, public userService: UserService, private modalService: NgbModal, private router: Router) {
    this.global.userDetailWatch.subscribe((value: any) => {
      this.userdetails.firstName = value['firstName'];
      this.userdetails.lastName = value['lastName'];
      this.userdetails.email = value['email'];
      this.userdetails.role = value['meta']['role'];
      this.userdetails.id = value['_id'];
      this.userName = value['permalinkSlug'];

      this.user.id = this.userdetails.id;

      if (value.payment != undefined) {
        if (value.payment.status === 'active' && value.stripe_cus_id != undefined) {
          this.getActiveSubscription();

        }
        //console.log("sadasdas");
        this.getSales();
      }

      if (value['payment']) {
        this.userdetails.expiryDate = value['payment']['expiryDate'];
        this.userdetails.preiumMonths = value['payment']['months'];
        this.userdetails.invoiceId = value['payment']['transactionId'];
      }
    })
  }

  getActiveSubscription() {
    this.userService.getActiveSubscription(this.userName).subscribe((user) => {
      // console.log(user);
      if (user != null || user != '') {
        this.subscription = user.activeSubscription;
        this.subscription_duration = this.subscription.months;
      }
    })
  }
  getSales() {
    this.sales = [];
    // this.userService.getSales(this.user.id, this.userdetails.email).subscribe((data:any) => {
    //   //this.sales = data == null ? this.sales = [] : this.sales = data;
    //   if(data != null) this.sales = this.sales.concat(data);
    //   this.sales = this.sales.filter(item => {
    //     if (item.recurring != "1") return false;
    //     return true;
    //   })
    //   // console.log("sales", this.sales)
    // }, (error) => {
    //   //this.sales = [];
    // });
    this.userService.getStripeSubscriptions(this.user.id)
      .subscribe(res => {
        if (res['subscriptions']) this.sales = this.sales.concat(res['subscriptions']);

        // console.log("asiudtaiusdiuasyda aiusdy asd", this.sales);
      });
    this.userService.getFastspringSubscriptions({ email: this.userdetails.email })
      .subscribe(res => {
        if (res['subscriptions']) this.sales = this.sales.concat(res['subscriptions']);
      });
  }
  ngOnInit() {
    this.userService.getUserProfile().subscribe(
      res => {
        var userDetails = res['user'];
        this.user.id = userDetails._id;
        this.user.email = userDetails.email;
        this.userdetails.firstName = userDetails['firstName'];
        this.userdetails.lastName = userDetails['lastName'];
        this.userdetails.email = userDetails['email'];
        this.userdetails.role = userDetails['meta']['role'];
        this.userdetails.id = userDetails['_id'];
        this.userdetails.jobTitle = userDetails['jobTitle'];
        this.userdetails.contactNumber = userDetails['contactNumber'];
        this.userdetails.country = userDetails['country'];

        if (userDetails['payment']) {
          this.userdetails.invoiceId = userDetails['payment']['transactionId'];
          this.userdetails.expiryDate = userDetails['payment']['expiryDate'];
          this.userdetails.preiumMonths = userDetails['payment']['months'];
        }

        this.userdetails.city = userDetails['city'];
        //this.userdetails.permalinkSlug = userDetails['permalinkSlug'];
        if ((userDetails['permalinkSlug'] != undefined) || userDetails['permalinkSlug'] != '') {
          this.userdetails.permalinkSlug = userDetails['permalinkSlug'];
        } else {
          this.userdetails.permalinkSlug = userDetails['firstName'] + "_" + userDetails['lastName'];
        }

      },
      err => {
        console.log('User profile error ', err);
        if (err.status === 404 || err.status === 403) {
          this.userService.deleteToken();
          this.router.navigateByUrl('/login');
        }
      }
    );

  }

  // getActiveSubscription() {

  //   this.userService.getActiveSubscription(this.userName).subscribe((user) => {

  //     if (user != null || user != '') {
  //       this.subscription = user.activeSubscription;
  //       this.subscription_duration = this.subscription.months;
  //     }
  //   })
  // }
  ngAfterViewInit() {
    //this.userdetails = this.tempDetails;
    // console.log(this.userdetails);
  }
  showPage(page: string) {
    this.currentPage = page;
  }

  resetForm(form: NgForm) {

  }
  onProfileSubmit(form: NgForm) {
    this.userService.updateUserProfile(form.value).subscribe(
      res => {
        if (res['status'] == false) {
          this.errorMessages = res['message'];
          this.successMessages = '';
        } else {
          this.successMessages = res['message'];
          this.errorMessages = '';
        }
      },
      err => {
        if (err.status === 422) {

        }
      }
    );
  }
  onPermalinkSubmit(form: NgForm) {
    this.userService.updateUserPermalink(form.value).subscribe(
      res => {
        // console.log(res)
        if (res['status'] == false) {
          this.permalinkErrorMessage = res['message'];
          this.permalinkSuccessMessage = "";
        } else {
          this.permalinkSuccessMessage = "Updated successfully.";
          this.permalinkErrorMessage = "";
        }
      },
      err => {
      }
    );
  }
  onSubmit(form: NgForm) {
    this.userService.postChangePassword(form.value).subscribe(
      res => {
        if (res['status'] == false) {
          this.errorMessages = res['message'];
          this.successMessages = '';
        } else {
          this.successMessages = res['message'];
          this.errorMessages = '';
        }
      },
      err => {
        if (err.status === 422) {

        }
      }
    );
  }
  deleteConfirmAlert() {
    const modalRef = this.modalService.open(NgbdModalUserDeleteContent);
    modalRef.componentInstance.user_id = this.userdetails.id;
  }
  showDetail(saleId) {
    let invoices = this.invoices.filter((invoice) => {
      return invoice.sale_id == saleId;
    });
    return invoices != null && invoices.length > 0
  }
  showSaleDetail(saleId) {
    this.userService.getInvoices(saleId).subscribe((data) => {
      this.invoices = data;
      this.saleIdForDetail = saleId;
    });
  }

  showFastspringSaleDetail(sale) {
    this.saleFastspringDetail = sale;
  }
  showStripeSaleDetail(sale) {
    this.saleStripeDetail = sale;
  }
  cancelSubscription(lineitemId) {
    const modalRef = this.modalService.open(NgbdModalCancelSubscriptionContent);
    modalRef.componentInstance.userId = this.userdetails.id;
    modalRef.componentInstance.lineitemId = lineitemId;
    modalRef.componentInstance.type = '';
    modalRef.componentInstance.onSucess.subscribe((data) => {
      this.saleIdForDetail = '';
      this.invoices = [];
      // console.log(data);
    });
  }

  cancelFastspringSubscription(id, index) {
    const modalRef = this.modalService.open(NgbdModalCancelSubscriptionContent);
    modalRef.componentInstance.userId = this.userdetails.id;
    modalRef.componentInstance.lineitemId = id;
    modalRef.componentInstance.type = 'fastspring';
    modalRef.componentInstance.onSucess.subscribe((data) => {
      this.saleFastspringDetail = null;
      this.sales[index].state = 'canceled';
      this.sales[index].deactivationDate = moment().format('YYYY/MM/DD');
    });
  }
  cancelStripeSubscription(id, index) {
    const modalRef = this.modalService.open(NgbdModalCancelSubscriptionContent);
    modalRef.componentInstance.userId = this.userdetails.id;
    modalRef.componentInstance.lineitemId = id;
    modalRef.componentInstance.type = 'stripe';
    modalRef.componentInstance.onSucess.subscribe((data) => {
      this.saleStripeDetail = null;
      this.sales[index].status = 'canceled';
      this.sales[index].deactivationDate = moment().format('YYYY/MM/DD');
    });
  }
  // cancelStripeSubscription() {
  //   this.stripeCardInfo = {
  //     sub_id: this.subscription.transactionId,
  //     cancel: "yes",
  //     username: this.userName
  //   }
  //   this.userService.stripecheckout(this.stripeCardInfo)
  //     .subscribe(finaldata => {
  //       if (finaldata) {
  //         this.subscriptionmessage = finaldata['message'];
  //         this.subscription_duration = null;
  //         // console.log(finaldata);
  //       }
  //     });
  // }
  onLogout() {
    this.userService.deleteToken();
    this.router.navigate(['/login']);
  }

}

@Component({
  selector: 'app-user-profile',
  template: `
      <div class="modal-header">
      <h4 class="modal-title">{{"Delete Account" | translate}}</h4>
        <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
          <i class="ft-x font-medium-3"></i>
        </button>
      </div>
      <form #cloneForm="ngForm" (ngSubmit)="cloneForm.valid && onSubmitDelete()">
        <div class="modal-body">
          <p>{{"Are You Sure You want to delete your account?" | translate}}</p>
            <button type="button" class="btn btn-outline-dark" (click)="activeModal.close('Close click')">{{"Cancel" | translate}}</button>
            <button class="btn btn-raised btn-danger right mx-1 mb-0"  type="submit" [disabled]="!cloneForm.valid">{{"Yes, Delete" | translate}}</button>
        </div>
      </form>

    `
})
export class NgbdModalUserDeleteContent {
  @Input() user_id;

  onSubmitDelete() {
    let data = { user_id: this.user_id };
    this.userService.deleteAccount(data).subscribe(
      res => {
        // after deleting, log out
        this.userService.deleteToken();
        this.router.navigate(['/login']);

        this.activeModal.close();
      },
      err => {
        if (err.status === 422) {

        }
      }
    );
    // console.log(this.user_id);
    // console.log("hello")
  }


  constructor(public activeModal: NgbActiveModal, private userService: UserService, private router: Router) { }

}
@Component({
  selector: 'app-cancel-subscription',
  template: `
      <div class="modal-header">
      <h4 class="modal-title">
      <span *ngIf="isSubStopReqProcessed==false">{{"Cancel Subscription" | translate}}</span>


      <span *ngIf="isSubStopReqProcessed==true">{{'We Will Miss You!' | translate}}</span>
      </h4>
        <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
          <i class="ft-x font-medium-3"></i>
        </button>
      </div>
      <form #cloneForm="ngForm" (ngSubmit)="cloneForm.valid && onSubmitCancel()">
        <div class="modal-body">
          <p *ngIf="isSubStopReqProcessed==false">{{"Are you sure you want to cancel subscription?" | translate}}</p>
            <button type="button" class="btn btn-outline-dark" (click)="activeModal.close('Close click')"  *ngIf="isSubStopReqProcessed==false">{{"Cancel" | translate}}</button>
            <button class="btn btn-raised btn-danger right mx-1 mb-0"  type="submit"  *ngIf="isSubStopReqProcessed==false">{{"Yes, cancel my subscription" | translate}}</button>

            <div class="alert alert-success" role="alert" *ngIf="isSubStopReqProcessed==true">

              <span>{{message}}</span>
              <br>
              {{'Thanks for your business. Your auto-renewal option is disabled. Your account will downgrade to basic at the end of packge expiration date.' | translate}}
            </div>
            <button class="btn btn-raised btn-danger right mx-1" *ngIf="isSubStopReqProcessed==true" (click)="activeModal.dismiss('Cross click')">{{"OK" | translate}}</button>

        </div>
      </form>
    `
})
export class NgbdModalCancelSubscriptionContent {

  @Input() userId;
  @Input() lineitemId;
  @Input() type;
  @Output() onSucess = new EventEmitter<any>();

  message: string = '';
  isSubStopReqProcessed: boolean = false;

  constructor(public activeModal: NgbActiveModal, private userService: UserService, private router: Router) { }

  onSubmitCancel() {
    this.isSubStopReqProcessed = true;
    if (this.type == '') {
      this.userService.stopSubscription(this.lineitemId).subscribe((data) => {
        this.onSucess.emit(data);
        this.message = data['response_message'];
      }, (error) => {
        this.isSubStopReqProcessed = false;
        this.message = 'There is some error';
      });
    } else if (this.type == 'fastspring') {
      this.userService.stopFastspringSubscription(this.lineitemId).subscribe((data) => {
        this.onSucess.emit(data);
        this.message = data['response_message'];
      }, (error) => {
        this.isSubStopReqProcessed = false;
        this.message = 'There is some error';
      });
    } else if (this.type == 'stripe') {
      this.userService.stopStripeSubscription(this.lineitemId).subscribe((data) => {
        this.onSucess.emit(data);
        this.message = data['response_message'];
      }, (error) => {
        this.isSubStopReqProcessed = false;
        this.message = 'There is some error';
      });
    }

  }
}

