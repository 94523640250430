import { Component, OnInit, ViewEncapsulation } from '@angular/core';

import { UserService } from '../../shared/user.service';
import { TranslateService } from '@ngx-translate/core';
import { NgForm } from '@angular/forms';
import { Router, ActivatedRoute, NavigationExtras } from "@angular/router";
import { OwlModule } from 'ngx-owl-carousel';
@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'app-registration-wizard',
  templateUrl: 'registration-wizard.component.html',
  styleUrls: ['registration-wizard.component.scss']
})
export class RegistrationWizardComponent implements OnInit {
  showPassword: boolean = true;
  carouselOptions = {
    margin: 20,
    nav: true,
    navText: ["<div class='nav-btn prev-slide shadow'><svg width='20' height='37' viewBox='0 0 20 37' fill='none' xmlns='http://www.w3.org/2000/svg'><path fill-rule='evenodd' clip-rule='evenodd' d='M0.584736 18.4287C0.517309 19.0767 0.709177 19.742 1.17123 20.235L15.741 35.7814C16.5682 36.664 17.9275 36.6382 18.7696 35.7396C19.6268 34.825 19.651 33.3745 18.8239 32.4919L5.64407 18.4287L18.8239 4.36549C19.651 3.48287 19.6268 2.03246 18.7696 1.11782C17.9275 0.219223 16.5682 0.193378 15.741 1.07599L1.17123 16.6224C0.709177 17.1154 0.517309 17.7808 0.584736 18.4287Z' fill='white'/></svg></div>", "<div class='nav-btn next-slide shadow'><svg width='20' height='37' viewBox='0 0 20 37' fill='none' xmlns='http://www.w3.org/2000/svg'><path fill-rule='evenodd' clip-rule='evenodd' d='M19.4155 18.4287C19.4829 19.0767 19.2911 19.742 18.829 20.235L4.25924 35.7814C3.43207 36.664 2.07277 36.6382 1.23063 35.7396C0.373442 34.825 0.349221 33.3745 1.17639 32.4919L14.3562 18.4287L1.17639 4.36549C0.349221 3.48287 0.373442 2.03246 1.23063 1.11782C2.07277 0.219223 3.43207 0.193378 4.25924 1.07599L18.829 16.6224C19.2911 17.1154 19.4829 17.7808 19.4155 18.4287Z' fill='white'/></svg></div>"],
    responsiveClass: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: false,
    autoWidth: false,
    center: true,
    responsive: {
      0: {
        items: 1,
        nav: true
      },
      600: {
        items: 1,
        nav: true
      },
      1000: {
        items: 6,
        nav: true,
        loop: true
      },
      1200: {
        items: 5,
        nav: true,
        loop: true
      },
      1400: {
        items: 5,
        nav: true,
        loop: true
      }
    }

  }


  emailExisting: any;
  public templates: any;
  data = {};
  public contactInfo = { resName: 'My Resume', firstName: '', email: '', password: '', lastName: '' };
  public functionalityArray: Array<Object> = [
    { id: 1, name: 'Accounting', img: 'acc' },
    { id: 2, name: 'Administrative', img: 'admin' },
    { id: 3, name: 'Advertising', img: 'adv' },
    { id: 4, name: 'Art/Creative', img: 'art' },
    { id: 5, name: 'Business Development', img: 'businessD' },
    { id: 6, name: 'Consulting', img: 'cons' },
    { id: 7, name: 'Customer Service', img: 'customerC' },
    { id: 8, name: 'Education', img: 'edu' },
    { id: 9, name: 'Engineering', img: 'eng' },
    { id: 10, name: 'Health Care', img: 'health' },
    { id: 11, name: 'Human Resources', img: 'hr' },
    { id: 12, name: 'Information Technology', img: 'it' },
    { id: 13, name: 'Legal', img: 'legal' },
    { id: 14, name: 'Management', img: 'manag' },
    { id: 15, name: 'Manufacturing', img: 'manf' },
    { id: 16, name: 'Marketing', img: 'mark' },
    { id: 17, name: 'Production', img: 'prod' },
    { id: 18, name: 'Project Management', img: 'proj' },
    { id: 19, name: 'PR', img: 'pr' },
    { id: 20, name: 'Sales', img: 'sales' },
    { id: 21, name: 'Science', img: 'sci' },
    { id: 22, name: 'Writing', img: 'we' },
    { id: 23, name: 'Skip', nameclass: 'skipped', img: 'skip' },
  ];



  showJobRole: Boolean = true;
  showResumeLanguage: Boolean = true;
  showContactsInfo: Boolean = true;
  showExperienceLevel: Boolean = true;
  showTemplate: Boolean = false;


  public dashboardItems: Array<Object> = [

  ];
  selectedLanguage: string;
  constructor(
    public userService: UserService,
    public translate: TranslateService,
    public router: Router,
    public route: ActivatedRoute) {
    this.selectedLanguage = translate.getBrowserLang();
  }

  abc = {};
  model = {
    email: '',
    password: ''
  };
  emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  serverErrorMessages: string;

  preferred_langulage: any;

  ngOnInit() {
    // $.getScript('./assets/js/owl.carousel.js');

    // get templates
    this.getTemplates();
    this.dashboardItems =
      [
        {
          Dashboard: 'Dashboard',
          CreateCoverLetter: 'Create Cover Letter',
          CreateResume: 'Create Resume',
          // HI:'HI',
          MyDocument: 'My Document',
          Name: 'Name',
          Template: 'Template',
          CreatedDate: 'Created Date',
          YourJobFunctionality: 'Your Job Functionality',
          Awellcomposedresumecan: 'A well-composed resume can',
          literallychange: 'literally change',
          ResumeName: 'Resume Name',
          EnterResumeName: 'Enter Resume Name',
          LetsStart: 'Lets Start',
          name1: 'Select text direction (Resume language)',
          name2: 'You can change the template at any time without having to rewrite the content',
          ExperienceLevel: 'Experience Level',
          StudentJunior: ' Student / Junior',
          Senior: 'Senior',
          Executive: 'Executive',
          name3: '0-2',
          Years: 'Years',
          name4: '  2-6 ',
          name5: ' +6',
          template1: 'template 1',
          template2: 'template 2',
          ResumeMarketing: 'ex: Resume-Marketing',
          Resumelanguage: 'Resume language',
          specialty: 'specialty',
          Selecttextdirection: 'Select text direction',
          CoverLetterName: 'Cover Letter Name:',
        }
      ];

    if (this.route.snapshot.params.language === "ar") {
      this.data['language'] = "Arabic";
    } else {
      this.data['language'] = "";
    }

  }

  getTemplates() {
    this.userService.getTemplates().subscribe((res) => {

      this.templates = res;

    });
  }
  template(value) {
    this.data['template'] = value;
    localStorage.setItem("resumeTemplate", JSON.stringify(this.data));
    this.showJobRole = false;
    this.showTemplate = true;

  }

  fucntionalityfucntion(value) {

    this.showJobRole = true;
    this.showResumeLanguage = false;

  }
  language(language) {

    localStorage.setItem("resumeLanguage", language);
    this.data['language'] = language;
    this.showContactsInfo = false;
    this.showResumeLanguage = true;

  }
  next(form: NgForm) {
    this.data['name'] = form.value.resName;
    let data = { email: form.value.email };
    this.userService.validateEmail(data).subscribe(
      res => {
        if (res['status'] == false) {
          this.emailExisting = res['message'];
        } else {
          this.data['firstName'] = form.value.firstName;
          this.data['lastName'] = form.value.lastName;
          this.data['email'] = form.value.email;
          this.data['type'] = "Resume";
          this.showExperienceLevel = false;
          this.showContactsInfo = true;
          this.registerUser();
        }
      },
      err => {

      }
    );
  }
  experience(exp) {
    this.data['contentTemplate'] = exp;

    this.createResume();
  }

  registerUser() {
    let userInfo = {
      firstName: this.contactInfo.firstName,
      lastName: this.contactInfo.lastName,
      email: this.contactInfo.email,
      password: this.contactInfo.password,
      preferred_language: ""
    }
    this.userService.postUser(userInfo, localStorage.getItem("resumeLanguage")).subscribe(
      res => {
        this.data['userId'] = res['_id'];

        let data = { email: userInfo.email.toLowerCase(), password: userInfo.password }

        this.userService.login(data).subscribe(
          res => {
            // console.log(data);
            this.userService.setToken(res['token']);

          },
          err => {
            this.serverErrorMessages = err.error.message;
          }
        );
      },
      err => {
        if (err.status === 422) {
          this.emailExisting = err.error;
          this.serverErrorMessages = err.error.join('<br/>');
        }
        else
          this.serverErrorMessages = 'Something went wrong.Please contact admin.';
      }
    );
  }
  createResume() {
    this.userService.resumeSave(this.data).subscribe((res) => {
      localStorage.setItem('resumeId', res["user"]._id);
      // console.log(res);
      this.router.navigate(['/cv/', res["user"]._id, 'personal-info'], { queryParams: { newUser: true } });
    });
  }

  resetForm(form: NgForm) {
    this.userService.selectedUser = {
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      preferred_language: ''
    };
    form.resetForm();
    // this.serverErrorMessages = '';
  }
  changePasswordStatus(type) {
    if (type === 'show')
      this.showPassword = false;
    else
      this.showPassword = true;
  }
}
