// import { UserService } from 'app/shared/user.service';
import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router, ActivatedRoute } from "@angular/router";
import { UserService } from '../../shared/user.service';
import { SocialUser, AuthServiceConfig, GoogleLoginProvider, FacebookLoginProvider, LinkedinLoginProvider, AuthService } from 'ng-social-login-module';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.scss'],
  providers: [UserService]
})
export class SignUpComponent implements OnInit {
  captcha: string;
  public signupItems: Array<Object> = [

  ];



  emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  nameRegex = /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]+$/;
  showSucessMessage: boolean;
  serverErrorMessages: string;
  abc = {}; //used in
  textDir;
  preferred_language;


  emailExisting: any;

  checkedSignup: boolean = true;

  public user: any = SocialUser;

  showPassword: boolean = true;
  constructor(

    public userService: UserService,
    private activatedRoute: ActivatedRoute,
    private socialAuthService: AuthService,
    public router: Router,
    public translate: TranslateService
  ) {
    this.captcha = ''
  }

  ngOnInit() {
    if (this.activatedRoute.snapshot.params.language) {
      this.preferred_language = this.activatedRoute.snapshot.params.language;
      localStorage.setItem("locale", this.preferred_language);

    } else {
      if (localStorage.getItem("locale") === null) {
        this.preferred_language = "en";
        localStorage.setItem("locale", this.preferred_language);
      } else {
        this.preferred_language = localStorage.getItem("locale");

      }

    }
    this.translate.use(this.preferred_language);

    // which langulage is selected in url
    if (this.preferred_language == 'ar') {
      this.textDir = 'rtl';
    } else {
      this.textDir = 'ltr';
    }

    this.showPassword = true;
    this.checkedSignup = true;
    this.signupItems =
      [
        {
          Creatingaresumehasneverbeen: 'Creating a resume has never been',
          Easier: 'Easier',
          CreateyourFREEaccount: 'Create your FREE account',
          Professional: 'Professional',
          Resume: 'Resume',
          Free: '2 Free',
          twoResumes: '2 Resumes',
          Multilingual: 'Multilingual',
          Templates: '+20 Templates',
          Design: 'Design',
          Unlimited: 'Unlimited',
          Entries: 'Entries',
          PDFDownloads: 'PDF Downloads',
          FreeAccount: 'Free Account',
          FirstName: 'First Name',
          LastName: 'Last Name',
          Email: 'Email',
          Password: 'Password',
          Thisieldisrequired: 'This field is required.',
          Enteratleast4characters: 'Enter atleast 4 characters',
          StartCreatingMyResume: 'Start Creating My Resume',
          or: 'or',
          Signinwith: 'Sign in with',
          Haveanaccount: 'Have an account?',
          Login: 'Login',
          ByusingStylingCVyouareagreeingtoour: 'By using StylingCV you are agreeing to our',
          TermsofService: 'Terms of Service',
          Registeredsuccessfully: 'Registered successfully',
          Create: 'Create',
        }

      ];

  }
  resolved(captchaResponse: string) {
    this.captcha = captchaResponse;
    console.log('Resolved captcha with response:' + this.captcha);
  }
  onSubmit(form: NgForm) {
    this.checkedSignup = false;
    let language = localStorage.getItem('locale');
    this.userService.postUser(form.value, language).subscribe(
      res => {
        let data = { email: form.value.email.toLowerCase(), password: form.value.password }

        this.userService.login(data).subscribe(
          res => {
            //console.log("login success");
            this.checkedSignup = true;
            this.userService.setToken(res['token']);
            this.router.navigate(['/dashboard'], { queryParams: { newUser: true } });
          },
          err => {
            console.log("login failed");
            this.serverErrorMessages = err.error.message;
          }
        );
      },
      err => {
        this.checkedSignup = true;
        if (err.status === 422) {
          this.emailExisting = err.error;
          this.serverErrorMessages = err.error.join('<br/>');
        }
        else
          this.serverErrorMessages = 'Something went wrong.Please contact admin.';
      }
    );
  }

  resetForm(form: NgForm) {
    this.userService.selectedUser = {
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      preferred_language: ''
    };
    form.resetForm();
    this.serverErrorMessages = '';
  }

  // SignUp with facebook
  facebooklogin() {
    this.socialAuthService.signIn(FacebookLoginProvider.PROVIDER_ID).then((userData) => {

      this.user = userData;
      this.abc['email'] = this.user.email;
      let x = this.user.name.split(" ")
      this.abc['firstName'] = x[0];
      this.abc['lastName'] = x[1];
      this.abc['password'] = this.user.id;
      this.user = {};
      this.user = this.abc;
      let language = localStorage.getItem('locale');
      this.userService.postUser(this.user, language).subscribe(
        res => {
          this.userService.setToken(res['token']);
          this.router.navigateByUrl('/dashboard');
          this.showSucessMessage = true;
          setTimeout(() => this.showSucessMessage = false, 4000);
        },
        err => {
          if (err.status === 422) {
            this.serverErrorMessages = err.error.join('<br/>');
          }
          else
            this.serverErrorMessages = 'Something went wrong.Please contact admin.';
        }
      );
    });
  }

  // SignUp with Google
  googlelogin() {
    this.socialAuthService.signIn(GoogleLoginProvider.PROVIDER_ID).then((userData) => {
      this.user = userData;
      this.abc['email'] = this.user.email;
      let x = this.user.name.split(" ")
      this.abc['firstName'] = x[0];
      this.abc['lastName'] = x[1];
      this.abc['password'] = this.user.id;
      this.user = {};
      this.user = this.abc;
      let language = localStorage.getItem('locale');
      this.userService.postUser(this.user, language).subscribe(
        res => {
          this.userService.setToken(res['token']);
          this.router.navigateByUrl('/dashboard');
          this.showSucessMessage = true;
          setTimeout(() => this.showSucessMessage = false, 4000);
        },
        err => {
          if (err.status === 422) {
            this.serverErrorMessages = err.error.join('<br/>');
          }
          else
            this.serverErrorMessages = 'Something went wrong.Please contact admin.';
        }
      );
    });
  }

  // SignUp with linkedin
  linkedinlogin() {

    this.socialAuthService.signIn(LinkedinLoginProvider.PROVIDER_ID).then((userData) => {
      this.user = userData;
      let x = this.user.name.split(" ")
      this.abc['firstName'] = x[0];
      this.abc['lastName'] = x[1];
      this.abc['password'] = this.user.token;
      this.user = {};
      this.user = this.abc;
      let language = localStorage.getItem('locale');
      this.userService.postUser(this.user, language).subscribe(
        res => {
          this.showSucessMessage = true;
          setTimeout(() => this.showSucessMessage = false, 4000);
        },
        err => {
          if (err.status === 422) {
            this.serverErrorMessages = err.error.join('<br/>');
          }
          else
            this.serverErrorMessages = 'Something went wrong.Please contact admin.';
        }
      );
    });
  }

  changePasswordStatus(type) {
    if (type === 'show')
      this.showPassword = false;
    else
      this.showPassword = true;
  }
  signOut(): void {
    this.socialAuthService.signOut();
  }
}
