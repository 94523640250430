// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  apiBaseUrl: '/api',
  ///live
  Towcheckout_publishableKey: "B9089D0A-6BC6-472C-9EF4-A6FF19218469",
  Towcheckout_sellerId: "103094923",

  //sandbox
  // Towcheckout_publishableKey: "180F938B-AA31-4604-81C4-466EB5647DB5",
  // Towcheckout_sellerId: "901411359",
  baseUrl: '/',
  domain: 'http://localhost:4200'
};
