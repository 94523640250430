
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from "@angular/common/http";
import { Injectable } from '@angular/core';
import { tap, catchError } from 'rxjs/operators';
import { Router } from "@angular/router";
import { UserService } from "../shared/user.service";
// import { UserService } from "../../app/user/";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(private userService: UserService, private router: Router) { }

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    const token = this.userService.getToken();
    if (req.headers.get('noauth') || token === null)
      return next.handle(req.clone());
    else {
      const clonedreq = req.clone({
        headers: req.headers.set("Authorization", "Bearer " + token)
      });
      return next.handle(clonedreq).pipe(
        tap(
          event => {
            //  console.log('Interceptor ', event);
          },
          err => {
            console.log('Error intercept ', err);
            if (err.error.auth == false) {
              this.router.navigateByUrl('/signup');
            }
          })
      );
    }
  }
}
