import { Component } from '@angular/core';

@Component({
    selector: 'app-dropdowns',
    templateUrl: './dropdowns.component.html',
    styleUrls: ['./dropdowns.component.scss'],
})

export class DropdownsComponent {
    myDrop: any;
    myDrop1: any;
}