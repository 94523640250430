import { Component, AfterViewChecked, OnInit, Output, EventEmitter } from '@angular/core';
import { Router } from "@angular/router";
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UserService } from '../user.service';
import { GlobalService } from '../global.service';
import { NgbdModalTemplateLists, NgbdModalWebsiteTemplateLists } from '../customizer/customizer.component';
@Component({
    selector: 'app-editor-navbar',
    templateUrl: './editor-navbar.component.html',
    styleUrls: ['./editor-navbar.component.scss'],
    // providers: [NgbdModalArrangeSections]

})



export class EditorNavbarComponent {

    resumeData;
    resumeId;
    checkAutoSaving;
    userId;
    resumeLanguage;
    selected_template;
    resumeType = "";
    @Output() languageChanged: EventEmitter<string> = new EventEmitter();

    constructor(
        private router: Router,
        private modalService: NgbModal,
        private userService: UserService,
        private globalService: GlobalService) {
        this.globalService.resumeDetailWatch.subscribe(res => {

            this.resumeType = res['data'][0]['meta']['type'];
            this.userId = res['data'][0]['userId'];
            this.resumeLanguage = res['data'][0].meta.ResumeLanguage;
            this.selected_template = res['data'][0].meta.defaultTemplate.name;
            localStorage.setItem("userId", this.userId)
        })
        this.globalService.changeTemplateWatch.subscribe(res => {
            // console.log("tempalte change", res);
            this.selected_template = res['doc'].meta.defaultTemplate.name;
        })
    }

    async showAutoSaving() {

        await
            setInterval(() => {
                this.checkAutoSaving = false;
            }, 6000)

        await setInterval(() => {
            this.checkAutoSaving = true;
        }, 4000)
    }

    ngOnInit() {
        this.checkAutoSaving = true;
        this.showAutoSaving();
        this.resumeId = localStorage.getItem('resumeId');
        this.selected_template = localStorage.getItem('selected_template');
        let language = localStorage.getItem('locale');
        this.languageChangedHandler(language);
    }
    textDir;
    languageChangedHandler(language: any) {
        if (language == 'ar') {
            this.textDir = 'rtl';
        } else {
            this.textDir = 'ltr';
        }


    }

    changeResumeTemplate() {
        if (this.resumeType != 'WebSite') {
            const modalRef = this.modalService.open(NgbdModalTemplateLists, { size: 'lg', windowClass: 'modalTemplate' });
            this.userService.getTemplates().subscribe((res) => {
                modalRef.componentInstance.templates = res;
                modalRef.componentInstance.resumeId = localStorage.getItem('resumeId');
                modalRef.componentInstance.templateSelected = this.selected_template;
                modalRef.componentInstance.resumeLanguage = this.resumeLanguage;

            });
        } else {
            const modalRef = this.modalService.open(NgbdModalWebsiteTemplateLists, { size: 'lg', windowClass: 'modalTemplate' });
            this.userService.getWebsiteTemplates().subscribe((res) => {
                modalRef.componentInstance.templates = res;
                modalRef.componentInstance.resumeId = localStorage.getItem('resumeId');
                modalRef.componentInstance.templateSelected = this.selected_template;
                modalRef.componentInstance.resumeLanguage = this.resumeLanguage;

            });
        }
    }





    localeChangedHandler(language: any) {

        this.languageChanged.emit(language);
    }
}
