import { Component, OnInit } from '@angular/core';
import { UserService } from 'app/shared/user.service';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss']
})
export class AdminComponent implements OnInit {

  constructor(public userService: UserService) { }
  role: string;

  email: string;

  userDetail: any;
  emailView = false;
  packageView = false;
  passwordView = false;

  user = { email: '', months: '' };
  isExistingEmail = false;
  currentDate = new Date();
  isExistingUser = true;
  password = '';
  resumeData = { resume: 0, coverLetter: 0, website: 0 };
  resumes = [];
  ngOnInit() {
    this.userService.getUserProfile().subscribe(
      res => {
        var userDetails = res['user'];
        this.role = userDetails['meta']['role'];


      },
      err => {
        console.log('User profile error ', err);
        if (err.status === 404 || err.status === 403) {
        }
      }
    );

  }
  submit() {
    this.resumeData = { resume: 0, coverLetter: 0, website: 0 };
    this.userService.searchUsersByEmail(this.email).subscribe((res: any) => {
      if (res.status) {

        if (res.resumes) {
          this.resumes = res.resumes;
          res.resumes.forEach(obj => {
            if (obj.meta.type === 'Resume') this.resumeData.resume++;
            if (obj.meta.type === 'CoverLetter') this.resumeData.coverLetter++;
            if (obj.meta.type === 'WebSite') this.resumeData.website++;
          })
        }
        this.userDetail = res.user;

        this.user.email = this.userDetail.email;
        if (this.userDetail.payments) {
          this.user.months = Number(this.userDetail.payments.months).toString();
        } else {
          this.user.months = '0';
        }

        this.isExistingUser = true;
      } else {
        this.isExistingUser = false;
      }
    })
  }

  updateDetail(type) {
    if (type === 'email') {
      this.emailView = true;
      this.packageView = false;

    }
    if (type === 'package') {
      this.packageView = true;
      this.emailView = false;
    }
  }

  cancelUpdate(type) {
    if (type === 'email') this.emailView = false;
  }

  updateUser(type) {
    let oldEmail = this.userDetail.email;
    if (type === 'email') {
      this.userDetail.email = this.user.email;
    } else {
      if (this.user.months === '0') {
        this.userDetail.payment = null;
      }
      else if (this.user.months === '1') {

        let expiryDate = new Date();
        this.userDetail.payment = {
          paymentTime: new Date().getTime(),
          months: parseFloat(this.user.months),
          expiryDate: new Date(expiryDate.setDate(new Date().getDate() + 30)).toISOString()
        };
      }
      else if (this.user.months === '3') {

        let expiryDate = new Date();
        this.userDetail.payment = {
          paymentTime: new Date().getTime(),
          months: parseFloat(this.user.months),
          expiryDate: new Date(expiryDate.setDate(new Date().getDate() + 90)).toISOString()
        };
      }
      else {
        let expiryDate = new Date();
        this.userDetail.payment = {
          paymentTime: new Date().getTime(),
          months: parseFloat(this.user.months),
          expiryDate: new Date(expiryDate.setDate(new Date().getDate() + 365)).toISOString()
        };
      }
      if (this.user.months === '0') {
        this.userDetail.meta.role = 'USER';
        this.userDetail.meta.max_cvs = 2;
      } else {
        this.userDetail.meta.role = 'OPS';
        this.userDetail.meta.max_cvs = 10;
      }
    }


    console.log(this.userDetail);
    //return;
    this.userService.updateUserDetail(this.userDetail).subscribe((res: any) => {


      if (res.status) {
        this.userService.updateResumeMetaEmail(this.userDetail._id, oldEmail, this.userDetail.email).subscribe((res: any) => {

        })
        this.emailView = false;
        this.packageView = false;
      } else {
        this.isExistingEmail = true;
      }

    })
  }
  checkActive() {
    if (new Date(this.userDetail.payment.expiryDate) >= this.currentDate) return true;
    return false;
  }

  deleteUser() {
    this.userService.deleteAccount({ user_id: this.userDetail._id })
      .subscribe(res => {
        this.userDetail = null;
      })
  }

  showPassword() {
    this.passwordView = true;
  }

  updatePassword() {

    this.userService.updatePasswordByEmail(this.email, this.password)
      .subscribe(res => {
        this.passwordView = false;
        this.password = "";
      })
  }

  deleteResume(resumeId, index) {
    this.userService.deleteResume(resumeId)
      .subscribe(res => {
        this.resumes.splice(index, 1);
      })
  }
}
