import { Component, OnInit, Input } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router, ActivatedRoute, NavigationExtras } from "@angular/router";
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { GlobalService } from '../shared/global.service';
import { UserService } from '../shared/user.service';

import { Resumes } from 'app/shared/resume.model';
import { WorkExperienceComponent, FormBuilder } from '../../app/sections/work-experience/work-experience.component';
import * as alertFunctions from '../shared/data/sweet-alerts';
import swal from 'sweetalert2';
import { TranslateService } from '@ngx-translate/core';
import { trigger, style, transition, animate, query, stagger } from '@angular/animations';

@Component({
  selector: 'app-user-dashboard',
  templateUrl: './user-dashboard.component.html',
  styleUrls: ['./user-dashboard.component.scss'],
  providers: [WorkExperienceComponent, FormBuilder],// exp
  animations: [
    trigger('functionalityAnimation', [
      transition('* => *', [
        query(':enter', [
          style({ opacity: 0, marginTop: '-8px' }),
          stagger(100, [
            animate('0.3s', style({ opacity: 1, marginTop: '0' }))
          ])
        ])
      ])
    ]),
    trigger('languageAnimation', [
      transition(':enter', [

        query('.pointer', [
          style({ opacity: 0 }),
          stagger(100, [
            animate('0.3s', style({ opacity: 1 }))
          ])
        ])

      ])
    ]),

    trigger('experienceAnimation', [
      transition('* => *', [
        query('.col-12', [
          style({ opacity: 0, marginTop: '-20px' }),
          stagger(100, [
            animate('0.7s', style({ opacity: 1, marginTop: '0' }))
          ])
        ])
      ])
    ]),


  ],
})
export class UserDashboardComponent implements OnInit {

  public resumeName: String;

  maxNumber(type: any) {
    var html22;
    var notAllow;
    if (type == "USER")
      this.translate.get("HTML for Max CV2").subscribe((text: string) => { html22 = text; });
    else
      this.translate.get("HTML for Max OPS").subscribe((text: string) => { html22 = text; });

    this.translate.get("Not Allow").subscribe((text: string) => { notAllow = text; });
    swal({
      title: notAllow,
      type: 'info',
      html: html22,
      showCloseButton: true,
      showCancelButton: false,
      showConfirmButton: false,
      focusConfirm: false,
      confirmButtonText: 'OK!',
      confirmButtonAriaLabel: 'Thumbs up, great!',
      cancelButtonText: '<i class="fa fa-thumbs-down"></i>',
      cancelButtonAriaLabel: 'Thumbs down',
    })
  }

  typeWarning(resume: any) {
    var titleY;
    var textY;
    var confirmButtonText;
    var cancelButtonText;
    var DeletedY;
    var successY;
    this.translate.get("Delete Document").subscribe((text: string) => { titleY = text; });
    this.translate.get("You won't be able to revert this!").subscribe((text: string) => { textY = text; });
    this.translate.get("Yes, delete it!").subscribe((text: string) => { confirmButtonText = text; });
    this.translate.get("No, cancel!").subscribe((text: string) => { cancelButtonText = text; });
    this.translate.get("Deleted").subscribe((text: string) => { DeletedY = text; });
    this.translate.get("Success").subscribe((text: string) => { successY = text; });
    swal({
      title: titleY,
      text: textY,
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: confirmButtonText,
      cancelButtonText: cancelButtonText,
      reverseButtons: true
    }).then((result) => {
      if (result.value) {

        swal(
          DeletedY,
          successY,
          'success'
        )
        this.userService.deleteResume(resume._id).subscribe((res) => {
          this.userService.getResumeList().subscribe((res) => {
            this.userService.resume = res as Resumes[];
          });
          // this.resetForm(form);
        });
      } else if (
        // Read more about handling dismissals
        result.dismiss === swal.DismissReason.cancel
      ) {
        //   swal(
        //     'Cancelled',
        //     'Your imaginary file is safe :)',
        //     'error'
        //   )
      }
    })
    //alertFunctions.customWarningDeleteResume();
  }


  data = {};
  userdetailsRole = { role: '' };
  userdetails1stName = { firstname: '' };
  userdetails = { permalinkSlug: '', expiryDate: '', preiumMonths: '', paymentTime: '' };
  showloadingDIV: boolean = true;

  checkShowingResumeList: boolean = true;

  USER_CREATE_RESUME_LIMIT;
  OPS_CREATE_RESUME_LIMIT;
  public dashboardItems: Array<Object> = [

  ];
  public functionalityArray: Array<Object> = [
    { id: 1, name: 'Accounting', img: 'acc' },
    { id: 2, name: 'Administrative', img: 'admin' },
    { id: 3, name: 'Advertising', img: 'adv' },
    { id: 4, name: 'Art/Creative', img: 'art' },
    { id: 5, name: 'Business Development', img: 'businessD' },
    { id: 6, name: 'Consulting', img: 'cons' },
    { id: 7, name: 'Customer Service', img: 'customerC' },
    { id: 8, name: 'Education', img: 'edu' },
    { id: 9, name: 'Engineering', img: 'eng' },
    { id: 10, name: 'Health Care', img: 'health' },
    { id: 11, name: 'Human Resources', img: 'hr' },
    { id: 12, name: 'Information Technology', img: 'it' },
    { id: 13, name: 'Legal', img: 'legal' },
    { id: 14, name: 'Management', img: 'manag' },
    { id: 15, name: 'Manufacturing', img: 'manf' },
    { id: 16, name: 'Marketing', img: 'mark' },
    { id: 17, name: 'Production', img: 'prod' },
    { id: 18, name: 'Project Management', img: 'proj' },
    { id: 19, name: 'PR', img: 'pr' },
    { id: 20, name: 'Sales', img: 'sales' },
    { id: 21, name: 'Science', img: 'sci' },
    { id: 22, name: 'Writing', img: 'we' },
    { id: 23, name: 'Skip', nameclass: 'skipped', img: 'skip' },
  ];

  showDashboardContent1: Boolean = true;
  showDashboardContent2: Boolean = true;
  showDashboardContent3: Boolean = true;
  showDashboardContent4: Boolean = true;
  showDashboardContent5: Boolean = true;
  showDashboardContent6: Boolean = true;
  showCoverLetterContent: Boolean = true;
  showWebSiteContent: Boolean = false;
  showCoverLetterLanguage: Boolean = true;

  // 03.05 jupiter
  public templates: any;
  public websiteTemplates: any;
  public userDetails;

  public selectedType;
  public isUniqueWebsite = true;
  public urlRegex = /^[a-zA-Z0-9,/.\s\\-]+$/;
  public isCheckWebsite = false;
  constructor(
    public global: GlobalService,
    public userService: UserService,
    public route: ActivatedRoute,
    public router: Router,
    private modalService: NgbModal,
    public translate: TranslateService,
    private app: WorkExperienceComponent) { }



  ngOnInit() {
    this.showloadingDIV = true;
    this.checkShowingResumeList = false;
    this.global.userDetailWatch.subscribe(value => {
      this.userdetailsRole.role = value['meta']['role'];
      this.userdetails1stName.firstname = value['firstName'];
      this.resumeName = value['firstName'];
      if (value['payment']) {
        this.userdetails.expiryDate = value['payment']['expiryDate'];
        this.userdetails.preiumMonths = value['payment']['months'];

      }
    })
    this.dashboardItems =
      [
        {
          Dashboard: 'Dashboard',
          CreateCoverLetter: 'Create Cover Letter',
          CreateResume: 'Create Resume',
          MyDocument: 'My Document',
          Name: 'Name',
          Template: 'Template',
          CreatedDate: 'Created Date',
          YourJobFunctionality: 'Your Job Functionality',
          Awellcomposedresumecan: 'A well-composed resume can',
          literallychange: 'literally change',
          ResumeName: 'Resume Name',
          EnterResumeName: 'Enter Resume Name',
          LetsStart: 'Lets Start',
          name1: 'Select text direction (Resume language)',
          name2: 'You can change the template at any time without having to rewrite the content',
          ExperienceLevel: 'Experience Level',
          StudentJunior: 'Student / Junior',
          Senior: 'Senior',
          Executive: 'Executive',
          name3: '0-2',
          Years: 'Years',
          name4: '  2-6 ',
          name5: ' +6',
          template1: 'template 1',
          template2: 'template 2',
          ResumeMarketing: 'ex: Resume-Marketing',
          Resumelanguage: 'Resume language',
          Selecttextdirection: 'Select text direction',
          CoverLetterName: 'Cover Letter Name:',
        }
      ];

    //this.refreshResumeList();

    this.userService.getUserProfile().subscribe(
      res => {
        this.userDetails = res['user'];
        this.global.emitProfile(this.userDetails);
        this.userdetails1stName.firstname = res['user']['firstName'];
        this.userdetailsRole.role = res['user']['meta']['role'];
        this.global.userRole = res['user']['meta']['role'];
        if (res['user']['meta']['role'] = 'USER') this.USER_CREATE_RESUME_LIMIT = res['user']['meta']['max_cvs'];
        if (res['user']['meta']['role'] = 'OPS') this.OPS_CREATE_RESUME_LIMIT = res['user']['meta']['max_cvs'];

        this.userService.getResumeList().subscribe((res) => {
          this.userService.resume = res as Resumes[];
          if (this.userService.resume.length == 0) {
            this.data['userId'] = this.userDetails._id;
            this.showDashboardContent1 = false;
            this.showDashboardContent2 = false;
          }
          this.checkShowingResumeList = true;
        });
      },
      err => {
        console.log(err);

      }
    );
  }

  getTemplates() {
    this.userService.getTemplates().subscribe((res) => {
      this.templates = res;
    });
  }
  getWebsiteTemplates() {
    this.userService.getWebsiteTemplates().subscribe((res) => {
      this.websiteTemplates = res;
    });
  }

  onLogout() {
    this.userService.deleteToken();
    this.router.navigate(['/login']);
  }

  // refreshResumeList() {
  //   this.userService.getResumeList().subscribe((res) => {
  //     this.userService.resume = res as Resumes[];
  //   });
  // }

  onDelete(resume: Resumes) {

  }
  onRename(resume: Resumes) {
    const modalRef = this.modalService.open(NgbdModalResumeRenameContent);
    modalRef.componentInstance.data = resume;
  }

  onClone(resume: any) {
    var userMeta = this.userDetails.meta;
    if (userMeta.role == "USER" && this.userService.resume.length >= this.USER_CREATE_RESUME_LIMIT) {

      this.maxNumber("USER");

      // alert("You are only allowed to create "+this.USER_CREATE_RESUME_LIMIT+" resumes.");
      return;
    } else if (userMeta.role == "OPS" && this.userService.resume.length >= this.OPS_CREATE_RESUME_LIMIT) {
      this.maxNumber("OPS");
      //alert("You are only allowed to create " + this.OPS_CREATE_RESUME_LIMIT + " resumes.");
      return;
    }

    const modalRef = this.modalService.open(NgbdModalContent);
    modalRef.componentInstance.meta = resume.meta['name'] + "-clone";
    modalRef.componentInstance.data = resume;
  }



  add(resume: any, type) {
    this.selectedType = type;
    var userMeta = this.userDetails.meta;
    if (userMeta.role == "USER" && this.userService.resume.length >= this.USER_CREATE_RESUME_LIMIT) {

      this.maxNumber("USER");

      // alert("You are only allowed to create "+this.USER_CREATE_RESUME_LIMIT+" resumes.");
      return;
    } else if (userMeta.role == "OPS" && this.userService.resume.length >= this.OPS_CREATE_RESUME_LIMIT) {
      this.maxNumber("OPS");
      // alert("You are only allowed to create " + this.OPS_CREATE_RESUME_LIMIT + " resumes.");
      return;
    } //this should

    this.data['userId'] = resume._id;
    this.data['firstName'] = resume.firstName;
    this.data['lastName'] = resume.lastName;
    this.data['email'] = resume.email;
    this.data['type'] = type;
    if (type == "Resume") {
      this.showDashboardContent1 = false;
      this.showDashboardContent2 = false;

      this.showCoverLetterContent = true; // dont show cover letter for resume
    } else if (type == "CoverLetter") {
      console.log("CoverLetter");
      this.showCoverLetterContent = false; // show cover ltr
      this.showDashboardContent1 = false; // hide btns
      this.showCoverLetterLanguage = true; // hide language 
    }
    else if (type == "WebSite") {

      this.showWebSiteContent = true; // show WebSite
      // this.showDashboardContent1 = true; // hide btns
      // this.showCoverLetterLanguage = true; // hide language 
      this.showCoverLetterContent = true; // show cover ltr
      this.showDashboardContent1 = false; // hide btns

    }

    else {

      this.showDashboardContent1 = false;
      this.showDashboardContent3 = false;
    }
  }

  fucntionalityfucntion(value) {
    if (this.data['firstName'] == undefined) {
      this.userService.getUserProfile().subscribe(
        res => {
          // this.userDetails = res['user'];
          this.data['firstName'] = res['user'].firstName;
          this.data['lastName'] = res['user'].lastName;
          this.data['email'] = res['user'].email;
          this.data['type'] = "Resume";
        },
        err => {
          console.log(err);

        }
      );

    }

    this.showDashboardContent2 = true;
    this.showDashboardContent3 = false;
  }

  next(form: NgForm) {
    // console.log("next",form.value);
    this.data['name'] = form.value.meta;
    this.showDashboardContent3 = true;
    this.showDashboardContent4 = false; // show resume language selection

  }
  nextCvrLtr(form: NgForm) {
    if (this.selectedType != 'WebSite') {
      this.data['name'] = form.value.meta;
      this.showWebSiteContent = false; // show WebSite    
      this.showCoverLetterContent = true; // hide letter content from UI
      this.showCoverLetterLanguage = false; //show language selection for cover letter
    } else {
      this.data['domainName'] = form.value.meta;
      this.data['name'] = form.value.meta;
      this.isCheckWebsite = true;
      this.userService.checkWebsiteUnique(this.data['domainName']).subscribe((res: any) => {

        if (res['status'] == true) {
          setTimeout(() => {
            this.isUniqueWebsite = true;
            this.showWebSiteContent = false; // show WebSite    
            this.showCoverLetterContent = true; // hide letter content from UI
            this.showCoverLetterLanguage = false; //show language selection for cover letter
            this.isCheckWebsite = false;
          }, 100);

        } else {
          this.isUniqueWebsite = false;
          this.isCheckWebsite = false;
        }

      });
    }


  }

  language(language_resume) {
    this.data['language'] = language_resume;
    this.showWebSiteContent = false; // show WebSite
    this.showDashboardContent4 = true;
    this.showDashboardContent5 = false; // show template selection page

    // get templates
    this.selectedType != 'WebSite' ? this.getTemplates() : this.getWebsiteTemplates();
  }

  cvrLtrlanguage(language_resume) {
    this.showWebSiteContent = false; // show WebSite
    this.data['language'] = language_resume;
    this.selectedType != 'WebSite' ? this.getTemplates() : this.getWebsiteTemplates();
    this.showCoverLetterLanguage = true; // hide lang selection after language is selected
    this.showDashboardContent5 = false; // show template selection page
  }

  template(value) {
    // console.log("Till now Data-->", this.data);

    this.data['template'] = value;
    if (this.data['type'] == "Resume") {
      this.showDashboardContent5 = true;
      this.showDashboardContent6 = false;
      this.showWebSiteContent = false; // show WebSite
    } else {

      this.userService.resumeSave(this.data).subscribe((res) => {
        localStorage.setItem('resumeId', res["user"]._id);
        this.router.navigate(['/cv/', res["user"]._id, 'personal-info'], { relativeTo: this.route });
      });
    }
  }

  experience(value) {
    this.showloadingDIV = false;
    this.data['contentTemplate'] = value;
    setTimeout(() => {
      this.showloadingDIV = true;
      this.userService.resumeSave(this.data).subscribe((res) => {
        localStorage.setItem('resumeId', res["user"]._id);
        this.router.navigate(['/cv/', res["user"]._id, 'personal-info'], { relativeTo: this.route });
      });
    }, 1000)

  }

  onEdit(id) {
    localStorage.setItem('resumeId', id._id);
    localStorage.setItem('userName', this.userDetails.permalinkSlug);

    this.router.navigate(['/cv/', id._id, 'personal-info'], { relativeTo: this.route });

  }

  onButtonClick(content) {
    this.app.openModal();
  }


}

// modeal clone  // 

@Component({
  selector: 'app-user-dashboard',
  template: `
  <div class="modal-header">
  <h4 class="modal-title">
  Copy Your Resume</h4>
  <button aria-label="Close" (click)="activeModal.dismiss('Cross click')" class="close" type="button">
  <span aria-hidden="true">×</span>
  </button></div>
  <form #cloneForm="ngForm" (ngSubmit)="cloneForm.valid && onSubmit(cloneForm,data)">
  <div class="modal-body">
  
   
  <label _ngcontent-c11="" for="meta">Resume Name</label>
  <div  class="input-group">
  <input   class="form-control" name="meta"  [(ngModel)]="meta" placeholder="Enter full name" required type="text"></div>
 
  </div>
 
  <div class="modal-footer">
  <button class="btn btn-primary btn-raised" type="submit" [disabled]="!cloneForm.valid">
  Copy
  </button> 
  </div>
  </form>
  `
})
export class NgbdModalContent {
  @Input() meta;
  @Input() data;

  // @Input() refreshResumeList;

  onSubmit(form: NgForm, data) {

    var userId = data.userId
    var name = form.value.meta;
    var template = data.meta['defaultTemplate'];
    var sections = data.sections;
    var type = data.meta['type'];
    var language = data.meta['ResumeLanguage'];
    var aboutSection = data.meta['aboutSection'];
    var email = data.meta.email;
    var phone = '';
    var address = '';
    var linkedin = '';
    var twitter = '';

    var maritalStatus = aboutSection.maritalStatus;
    var DOB = aboutSection.DOB;
    var Nationality = aboutSection.Nationality;

    if (data.meta.aboutSection && data.meta.aboutSection.contacts && data.meta.aboutSection.contacts.length > 0) {
      var contacts = data.meta.aboutSection.contacts;
      var phoneObj = contacts.filter((t) => { return t.type == 'phone' })[0];
      if (phoneObj)
        phone = phoneObj.value;

      var addressObj = contacts.filter((t) => { return t.type == 'address' })[0];
      if (addressObj)
        address = addressObj.value;

      var linkedinObj = contacts.filter((t) => { return t.type == 'linkedin' })[0];
      if (linkedinObj)
        linkedin = linkedinObj.value;

      var twitterObj = contacts.filter((t) => { return t.type == 'twitter' })[0];
      if (twitterObj)
        twitter = twitterObj.value;
    }


    data = {};
    data['userId'] = userId;
    data['firstName'] = aboutSection.firstName;
    data['lastName'] = aboutSection.lastName;
    data['email'] = email
    data['name'] = name;
    data['sections'] = sections
    data['type'] = type;
    data['language'] = language;
    data['template'] = template;
    data['phone'] = phone;
    data['address'] = address;
    data['linkedin'] = linkedin;
    data['twitter'] = twitter;

    data['Nationality'] = Nationality;
    data['DOB'] = DOB;
    data['maritalStatus'] = maritalStatus;

    this.userService.resumeSave(data).subscribe((res) => {
      this.userService.getResumeList().subscribe((res) => {
        this.userService.resume = res as Resumes[];
        this.activeModal.close();
      });
    },
      (error) => {
        console.log(error);
      });
  }

  constructor(public activeModal: NgbActiveModal, private userService: UserService) {

  }
}






//   Rename Resume // 

@Component({
  selector: 'app-user-dashboard',
  template: `
    <div class="modal-header">
      <h4 class="modal-title">{{'Rename Document' | translate}}</h4>
      <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
        <i class="ft-x font-medium-3"></i>
      </button>
    </div>
    <form #cloneForm="ngForm" (ngSubmit)="cloneForm.valid && onSubmitRename(data)">
      <div class="modal-body">
      <label for="resumeName">{{'Resume Name' | translate}}</label>
      <div class="input-group">
        <input type="text" class="form-control"  [(ngModel)]='data.meta.name' name="resumeName" aria-describedby="basic-addon3">
      </div>
          
      </div>
      <div class="modal-footer">
      <button type="button" class="btn btn-outline-dark mr-1" (click)="activeModal.close('Close click')">
            {{'Cancel' | translate}}
          </button>
          <button class="btn btn-raised btn-custom bg-primary white" type="submit" [disabled]="!cloneForm.valid">
            {{'Yes, rename' | translate}}
          </button>
      </div>
    </form>
  `
})

export class NgbdModalResumeRenameContent {
  @Input() meta;
  @Input() data;

  onSubmitRename(data) {

    this.userService.updateresumegeneric(data._id, data).subscribe((res) => {

      this.activeModal.close();
      // this.resetForm(form);
    });
    // console.log("hello")
  }


  constructor(public activeModal: NgbActiveModal, private userService: UserService, private router: Router) { }


}


//   create modal   //   ///  below code is extra will be deleted later just for observation  ////

@Component({
  selector: 'app-user-dashboard',
  template: `
    <div class="modal-header">

      <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
        <i class="ft-x font-medium-3"></i>
      </button>
    </div>
    <div *ngIf="showMainContent1">
    <form #cloneForm="ngForm" (ngSubmit)="cloneForm.valid && ShowHideButton1(cloneForm,data)">
      <div class="modal-body">
        <p>Resume  Name</p>
        <div class = "col-sm-6">
        <input type="text"  name="meta"  [(ngModel)]="meta" placeholder="Enter full name" required>
        </div>
      </div>
      <div class="modal-footer">
        <button class="btn btn-custom right" type="submit" [disabled]="!cloneForm.valid">Next</button>
      </div>
    </form>
    </div>
    <div *ngIf="!showMainContent2">
    <form #cloneForm="ngForm" (ngSubmit)="cloneForm.valid && ShowHideButton2(cloneForm,data)">
    <div class="modal-body">
      <p>Template Type</p>
      <div class = "col-sm-6">
      <input type="text"  name="sections"  [(ngModel)]="sections" placeholder="Enter full name" required>
      </div>
    </div>
    <div class="modal-footer">
      <button class="btn btn-custom right" type="submit" [disabled]="!cloneForm.valid">Submit</button>
    </div>
  </form>
    </div>
  `
})

export class NgbdModalResumeContent {
  @Input() meta;
  @Input() array;

  showMainContent1: Boolean = true;
  showMainContent2: Boolean = true;



  ShowHideButton2(form: NgForm, data) {
    data['sections'] = form.value.sections;
    // console.log(data)
    this.userService.resumeSave(data).subscribe((res) => {
      this.activeModal.close();
      this.router.navigate(['/dashboard']);
    });

  }
  constructor(public activeModal: NgbActiveModal, private userService: UserService, private router: Router) { }

}



