import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
// import { HttpClientModule,HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AppRoutingModule } from './app-routing.module';
import { SharedModule } from "./shared/shared.module";
import { ContentPagesModule } from "./pages/content-pages/content-pages.module";
import { UIComponentsModule } from "./components/ui-components.module";
import { ImageCropperModule } from 'ngx-img-cropper';
import { AgmCoreModule } from '@agm/core';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS, HttpClientJsonpModule } from '@angular/common/http';
import { JsonpModule } from '@angular/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { StoreModule } from '@ngrx/store';
import { DragulaModule } from 'ng2-dragula';
import { AppComponent } from './app.component';
import { ContentLayoutComponent } from "./layouts/content/content-layout.component";
import { FullLayoutComponent } from "./layouts/full/full-layout.component";
import { AuthService } from './shared/auth/auth.service';
// import { AuthGuard } from './shared/auth/auth-guard.service';
import { AuthGuard } from '../app/auth/auth.guard';
import { AuthInterceptor } from '../app/auth/auth.interceptor';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as $ from 'jquery';
import { UserComponent } from './user/user.component';
import { RecaptchaModule } from "ng-recaptcha";
import { SignUpComponent } from './user/sign-up/sign-up.component';
import { LoginPageComponent } from './user/login/login-page.component';

import { appRoutes } from './routes';
// import { UserProfileComponent } from './user-profile/user-profile.component';
import { SocialLoginModule, AuthServiceConfig, GoogleLoginProvider, FacebookLoginProvider, LinkedinLoginProvider } from 'ng-social-login-module';
import { ForgotPasswordComponent, ForgotPasswordModalContent } from './user/forgot-password/forgot-password.component';
import { ResetPasswordComponent, ResetPasswordModalContent } from './user/reset-password/reset-password.component';
import { MasterLayoutComponent } from './layouts/master-layout/master-layout.component';
import { UserDashboardComponent, NgbdModalContent, NgbdModalResumeContent, NgbdModalResumeRenameContent } from './user-dashboard/user-dashboard.component';
import { NgbdModalResumeUrl, NgbdModalResumeMissing } from './sections/edit-resume/edit-resume.component';
import { PersonalInfoComponent } from './sections/personal-info/personal-info.component';
// import { NgbdModalArrangeSections } from '../app/shared/customizer/customizer.component'
import { NgbdModalTemplateLists, NgbdModalWebsiteTemplateLists } from '../app/shared/customizer/customizer.component'
import { WorkExperienceComponent } from './sections/work-experience/work-experience.component';
import { CoursesComponent } from './sections/courses/courses.component';
import { LanguagesComponent } from './sections/languages/languages.component';
import { SkillsCompetencesComponent } from './sections/skills-competences/skills-competences.component';
import { EmptySpaceComponent } from './sections/empty-space/empty-space.component';
import { HobbiesComponent } from './sections/hobbies/hobbies.component';
import { StrengthsComponent } from './sections/strengths/strengths.component';
import { AwardsComponent } from './sections/awards/awards.component';
import { CertificationsComponent } from './sections/certifications/certifications.component';
import { InterestsComponent } from './sections/interests/interests.component';
import { ClientsLogosComponent } from './sections/clients-logos/clients-logos.component';
import { FreeTextComponent } from './sections/free-text/free-text.component';
import { DonutChartComponent } from './sections/donut-chart/donut-chart.component';
import { ReferencesComponent } from './sections/references/references.component';
import { WorldMapComponent } from './sections/world-map/world-map.component';
import { PortfolioComponent } from './sections/portfolio/portfolio.component';
import { PublicationsComponent } from './sections/publications/publications.component';
import { FindAJobComponent } from './find-a-job/find-a-job.component';
// import { SectionsComponent } from './sections/sections.component';
import { HeaderComponent } from './sections/header/header.component';
import { EditResumeModule } from './sections/edit-resume/edit-resume.module';
import { AreasOfExpertiseModule } from './sections/areas-of-expertise/areas-of-expertise.module';
import { AwardsModule } from './sections/awards/awards.module';
import { PricingComponent } from './pricing/pricing.component';
import { UserProfileComponent, NgbdModalUserDeleteContent, NgbdModalCancelSubscriptionContent, } from './user-profile/user-profile.component';
import { AreasOfExpertiseComponent } from './sections/areas-of-expertise/areas-of-expertise.component';
import { RenameModalComponent } from './common_modals/rename_modal/rename-modal.component';
import { IntroductionComponent } from './sections/introduction/introduction.component';
import { SettingComponent } from './setting/setting.component';
import { RenameSectionComponent } from './common_modals/rename-section/rename-section.component';
import { RegistrationWizardComponent } from './user/registration-wizard/registration-wizard.component';
import { ThankYouComponent } from './thank-you/thank-you.component';
import { OwlModule } from 'ngx-owl-carousel';
import { UserService } from './shared/user.service';
import { environment } from '../environments/environment';
import { AdminComponent } from './user/admin/admin.component';


const config = new AuthServiceConfig([
  {
    id: GoogleLoginProvider.PROVIDER_ID,
    provider: new GoogleLoginProvider('163991732613-gsg8ml64md3k7f3vg4nunidamnipfg9v.apps.googleusercontent.com')
  },
  {
    id: FacebookLoginProvider.PROVIDER_ID,
    provider: new FacebookLoginProvider('399550303725455')
  },
  {
    id: LinkedinLoginProvider.PROVIDER_ID,
    provider: new LinkedinLoginProvider('812f6ygyv8erzm')
  }

], false);

export function provideConfig() {
  return config;
}

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}


@NgModule({
  declarations: [
    AppComponent,
    FullLayoutComponent,
    ContentLayoutComponent,
    UserComponent,
    SignUpComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    MasterLayoutComponent,
    UserDashboardComponent,
    NgbdModalContent,
    RenameModalComponent,
    NgbdModalResumeContent,
    // NgbdModalResumeDeleteContent,
    NgbdModalResumeRenameContent,
    NgbdModalUserDeleteContent,
    NgbdModalCancelSubscriptionContent,
    ForgotPasswordModalContent,
    ResetPasswordModalContent,
    NgbdModalResumeUrl,
    NgbdModalResumeMissing,
    LoginPageComponent,
    HeaderComponent,
    FindAJobComponent,
    // SectionsComponent,
    // PersonalInfoComponent,
    // EditResumeComponent,
    PricingComponent,
    UserProfileComponent,
    // NgbdModalArrangeSections,

    NgbdModalTemplateLists,
    NgbdModalWebsiteTemplateLists,
    // IntroductionComponent,
    // AreasOfExpertiseComponent,
    // ComputerSkillsComponent,

    // AreaOfExpertiseComponent
    // ModalComponent
    SettingComponent,
    RenameSectionComponent,
    RegistrationWizardComponent,
    ThankYouComponent,
    AdminComponent,

    // EditReferencesModalComponent,

    // EditSoftwareModalComponent,

    // EditCertificationsModalComponent,

    // EditInterestsModalComponent

  ],
  imports: [
    FormsModule,
    SocialLoginModule,
    BrowserAnimationsModule,
    StoreModule.forRoot({}),
    AppRoutingModule,
    SharedModule,
    ContentPagesModule,
    UIComponentsModule,
    RecaptchaModule,
    DragulaModule.forRoot(),
    HttpClientModule,
    HttpClientJsonpModule,
    JsonpModule,
    OwlModule,
    NgbModule.forRoot(),
    EditResumeModule,
    AreasOfExpertiseModule,
    AwardsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    }),
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyBr5_picK8YJK7fFR2CPzTVMj6GG1TtRGo'
    }),
    RouterModule.forRoot(appRoutes),
  ],

  providers: [
    {
      provide: AuthServiceConfig,
      useFactory: provideConfig
    },
    {
      // provide: HTTP_INTERCEPTORS,
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    AuthService,
    AuthGuard,
    NgbActiveModal,
    UserService
  ],
  bootstrap: [AppComponent],
  entryComponents: [NgbdModalContent, NgbdModalResumeContent, NgbdModalResumeRenameContent, NgbdModalUserDeleteContent, ForgotPasswordModalContent, ResetPasswordModalContent, NgbdModalTemplateLists, NgbdModalWebsiteTemplateLists, RenameSectionComponent, NgbdModalCancelSubscriptionContent, NgbdModalResumeUrl, NgbdModalResumeMissing]
})
export class AppModule { }
