import { Component, OnInit, ElementRef, EventEmitter, Renderer2 } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { UserService } from '../../shared/user.service';
import { GlobalService } from '../../shared/global.service';
import { Router, NavigationExtras, ActivatedRoute } from "@angular/router";
import { ROUTES } from '../../shared/sidebar/sidebar-routes.config';

var fireRefreshEventOnWindow = function () {
    var evt = document.createEvent("HTMLEvents");
    evt.initEvent('resize', true, false);
    window.dispatchEvent(evt);
};


@Component({
    selector: 'app-full-layout',
    templateUrl: './full-layout.component.html',
    styleUrls: ['./full-layout.component.scss']
})

export class FullLayoutComponent implements OnInit {


    options = {
        direction: 'ltr'
    };

    textDir; // direction according to language
    resumeLanguage;
    resumeMeta;
    check_next;
    check_prev;
    next_section_name;
    prev_section_name;
    sections;
    userDetail;
    userId;
    numberOfSections = 0;
    sectionOrder = 0;
    progressValue = 0;
    private menuSectionOrder = [
        "section-text",
        "section-work",
        "section-education",
        "section-skillset",
        "section-languages",
        "section-skillset",
        "skills-software",
        "section-awards",
        "section-certifications",
        "section-courses",
        "section-strengths",
        "section-interests",
        "section-portfolio",
        "section-logos",
        "section-text-inside",
        "section-references",
        "section-world-map",
        "section-publications",
        "section-empty",
        "section-donut-chart",
        "section-Hobbies",
        "section-empty-space"
    ];

    constructor(private elementRef: ElementRef,
        public userService: UserService,
        public globalService: GlobalService,
        private router: Router,
        private route: ActivatedRoute,
        public translate: TranslateService,
        private renderer: Renderer2) {
        this.userService.sectionChangeWatch.subscribe(value => {
            if (parseInt(this.sections.length) - (parseInt(localStorage.getItem('currentTab')) + 1) == 0) {
                this.check_next = false;
            } else {
                this.check_next = true;
            }
        }),

            this.globalService.visibleNextWatch.subscribe(value => {
            })
        this.globalService.sideMenuDeleteWatch.subscribe(value => {
            this.check_next = true;
        })
        this.globalService.resumeDetailWatch.subscribe(res => {

            this.resumeMeta = res['data'][0].meta;
            this.sections = res['data'][0].sections;
            this.numberOfSections = this.sections.length + 1;
            this.renderer.setAttribute(document.body, 'data-tempate-isPro', this.resumeMeta.defaultTemplate.isPro);
            this.renderer.setAttribute(document.body, 'data-resume-type', this.resumeMeta.type);
            this.renderer.setAttribute(document.body, 'data-resume-language', this.resumeMeta.ResumeLanguage);

            this.userId = res['data'][0]['userId'];
            this.resumeLanguage = res['data'][0].meta.ResumeLanguage;
            localStorage.setItem("userId", this.userId)

            var sectionid = localStorage.getItem("sectionid");

            this.sections = this.sections.sort((menu1, menu2) => {
                return this.menuSectionOrder.indexOf(menu1.type) > this.menuSectionOrder.indexOf(menu2.type) ? 1 : -1;
            })
            if (localStorage.getItem('sectionid') == '') {
                this.sectionOrder = 1;
            } else {
                this.sections.forEach((section, index) => {
                    if (section.ids == sectionid) this.sectionOrder = index + 2;
                })
            }
            this.progressValue = this.sectionOrder / this.numberOfSections * 100;
            if (this.sectionOrder == this.numberOfSections) {
                this.check_next = false;
                this.next_section_name = "";
            } else {
                this.check_next = true;
                this.next_section_name = this.sections[this.sectionOrder - 1]['name'];
            }
            if (this.sectionOrder == 1) {
                this.check_prev = false;
                this.prev_section_name = "";
            } else {
                this.check_prev = true;
                if (this.sectionOrder == 2) this.prev_section_name = "Personal Info";
                else this.prev_section_name = this.sections[this.sectionOrder - 3]['name'];
            }
        })
    }
    ngOnInit() {
        this.check_next = true;
        this.id = localStorage.getItem('resumeId');
        this.userId = localStorage.getItem('userId');
        this.resumeMeta = {};
        if (!this.userService.isLoggedIn()) {
            this.router.navigateByUrl('/login');
            return this.userService.deleteToken();
        }
        this.userService.getUserProfile().subscribe(
            res => {
                this.userDetail = res['user'];
                this.globalService.emitProfile(res['user']);
                this.renderer.setAttribute(document.body, 'data-user-type', this.userDetail.meta.role); // here free value should read user.meta.role
            },
            err => {
                console.log(err);
            }
        );
        if (localStorage.getItem('currentTab') === null) {
            this.currentTab = -1;
        } else {
            this.currentTab = localStorage.getItem('currentTab');
        }
        let language = localStorage.getItem('locale');
        this.setDirection(language);
        this.translate.use(language);
        if (language == "ar") {
            this.renderer.addClass(document.body, "langAR");
        }
    }

    setDirection(language: any) {
        if (language == 'ar') {
            this.textDir = 'rtl';
        } else {
            this.textDir = 'ltr';
        }
    }
    onClick(event) {

        setTimeout(() => { fireRefreshEventOnWindow() }, 300);
    }

    getOptions($event): void {
        this.options = $event;
    }

    id;
    resumeIdArray;
    sectionUniqueId;
    sectionname;
    sectionArray;
    currentTab: any = 0;
    isTabPresent: Boolean = false;
    nextSection() {
        this.currentTab = this.sectionOrder - 1;
        var nextSection = this.sections[this.currentTab];
        var index = ROUTES.findIndex(x => x.type.toString().toLowerCase() === nextSection.type.toString().toLowerCase());
        var path = "/cv/" + this.id + ROUTES[index].path + "/" + nextSection.ids;
        this.router.navigate([path]);
        return;
    }

    prevSection() {
        if (this.sectionOrder == 2) {
            var path = "/cv/" + this.id + "/personal-info";
            this.router.navigate([path]);
        } else {
            this.currentTab = this.sectionOrder - 3;
            var prevSection = this.sections[this.currentTab];
            var index = ROUTES.findIndex(x => x.type.toString().toLowerCase() === prevSection.type.toString().toLowerCase());
            var path = "/cv/" + this.id + ROUTES[index].path + "/" + prevSection.ids;
            this.router.navigate([path]);
            return;
        }

    }
    languageChangedHandler(language: any) {
        this.renderer.removeClass(document.body, 'langAR');

        if (language == 'ar') {
            this.textDir = 'rtl';
            this.renderer.addClass(document.body, 'langAR');

        } else {
            this.textDir = 'ltr';
        }

    }

}
